import { Button, Col, Flex, Form, Row } from 'antd';
import FormInput from 'common/FormInput';
import { CENTRAL_LOGS } from 'data/CentralLogs';
import { useNavigate } from 'react-router-dom';
import { getCentralLogs } from '../redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CentralLogs from '../components/CentralLogs';
import { useEffect, useState } from 'react';

const PER_PAGE_ITEM = 15;

export default function AuditTrail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch: AppDispatch = useDispatch();
  const { centralLogsLoading, pagination } = useSelector(
    (state: RootState) => state.reports
  );

  const transformedCentralLogs = CENTRAL_LOGS.map((item) => {
    return { label: item.label, value: item.tableName };
  });

  const handleSearchLogs = (values: any) => {
    const payload = {
      ...values,
      page: currentPage,
      perPage: PER_PAGE_ITEM,
    };
    dispatch(getCentralLogs(payload));
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    if (Object.values(values).every((e) => e)) {
      handleSearchLogs(values);
    }
  }, [currentPage]);

  return (
    <Flex
      vertical
      className="h-full bg-white shadow-equal-sides rounded-xl"
    >
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Audit Trail
        </h2>
      </Flex>

      {/* list of central logs */}
      <Form
        id="date_selector_modal_form"
        layout="vertical"
        onFinish={handleSearchLogs}
        form={form}
        className='overflow-auto'
      >
        <div className="!p-4">
          <Col span={24}>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 12 }}>
                <FormInput
                  marginBottom="medium"
                  name="table"
                  label={
                    <span className="text-tertiary-dark">Report Name</span>
                  }
                  defaultStyle
                  type="select"
                  placeholder="Selct report name"
                  required
                  options={transformedCentralLogs}
                  validateMsg="Report name is required"
                />
              </Col>
              <Col span={24} sm={{ span: 6 }}>
                <FormInput
                  marginBottom="medium"
                  name="from_date"
                  label={<span className="text-tertiary-dark">From</span>}
                  defaultStyle
                  type="date"
                  placeholder="Selct from date"
                  required
                  validateMsg="From date is required"
                  inputClasses="h-10 font-open-sans !shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
                />
              </Col>
              <Col span={24} sm={{ span: 6 }}>
                <FormInput
                  marginBottom="medium"
                  name="to_date"
                  type="date"
                  label={<span className="text-tertiary-dark">To</span>}
                  defaultStyle
                  placeholder="Select to date"
                  required
                  validateMsg="To date is required"
                  inputClasses="h-10 font-open-sans !shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
                />
              </Col>
            </Row>
          </Col>
        </div>

        <div className="flex justify-center gap-2 mt-2">
          <Button
            className="text-sm font-semibold text-white bg-primary hover:bg-transparent hover:!border-primary hover:!text-primary font-open-sans"
            onClick={() => {
              form.submit();
              setCurrentPage(1);
            }}
            loading={centralLogsLoading}
          >
            View
          </Button>
          <Button
            className="text-sm font-semibold border font-open-sans text-primary border-primary hover:!text-white hover:!bg-primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        {/* central logs table */}
        <CentralLogs
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
        />
      </Form>
    </Flex>
  );
}
