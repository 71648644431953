import auditTrailImg from 'assets/images/reports/audit.png';

export const REPORTS = [
  {
    id: 1,
    label: 'Audit Trail',
    img: auditTrailImg,
    path: 'audit_trail',
    subLabel:
      "An audit trail provides a transparent record of actions on users, uom's, clients, type of tests, experiment limits",
  },
];
