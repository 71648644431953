import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}
interface ResponseWithPagination {
  success: boolean;
  message: string;
  data: any;
  pagination: any;
}

export const centralLogs = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.centralLogs, payload, true);
};
