import { useNavigate } from 'react-router-dom';
import createIcon from 'assets/icons/create.svg';

export default function CreateButton({
  borderLess,
  label,
  onClick,
  subLabel,
  iconClassess,
}: {
  borderLess?: boolean;
  label: string | JSX.Element;
  onClick: () => void;
  subLabel?: string | JSX.Element;
  iconClassess?: string;
}) {
  return (
    <div
      className={`flex flex-col items-center justify-center w-full p-[26px] ${borderLess ? 'border-none' : 'border'} rounded-lg cursor-pointer border-tertiary-highlight-200 hover:border-primary-dark hover:bg-tertiary-highlight-100 hover:scale-[1.01]`}
      onClick={onClick}
    >
      <img src={createIcon} alt="create icon" className={iconClassess} />
      <label className="text-xs font-semibold text-center font-open-sans text-primary">
        {label}
      </label>
      {subLabel && (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          {subLabel}
        </label>
      )}
    </div>
  );
}
