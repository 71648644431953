import Folder from 'common/FolderIcon';

type TProps = {
  folderName: string;
  counts: number
};

export default function FolderCard({ folderName, counts }: TProps) {
  return <Folder counts={counts} folderName={folderName} />;
}
