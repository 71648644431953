import { Table as AntTable, Button, Flex, TablePaginationConfig } from 'antd';
import { useState } from 'react';
import arrow_left from 'assets/icons/arrow_left.svg';
import arrow_left_active from 'assets/icons/arrow_left_active.svg';
import arrow_right from 'assets/icons/arrow_right.svg';
import arrow_right_active from 'assets/icons/arrow_right_active.svg';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';

type TColumn = {
  title: JSX.Element | string;
  dataIndex: string;
  key: string;
  width?: string;
  ellipsis?: boolean;
  render: (text: string, record: any, index: number) => JSX.Element | string;
  sorter?: any;
  sorterIcon?: (order?: string) => JSX.Element | string;
  filterIcon?: (filtered?: boolean) => JSX.Element | string;
  sortDirections?: any;
  filterDropdown?: any;
  onCell?:any
};

type TTableProps = {
  columns: TColumn[];
  dataSource: any;
  bordered?: boolean;
  emptySpaceEl?: string | JSX.Element;
  components?: any;
  spanWidth?: number;
  loading?: boolean;
  hideScroll?: boolean;
  handleTableChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
  rowClassName?: (record: any, index: number) => any;
  onRow?: (record: any) => any;
};

type TTablePropsWithCustomPagination = TTableProps & {
  pagination: true;
  totalPages: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

type TTablePropsWithoutPagination = TTableProps &
  Partial<Omit<TTablePropsWithCustomPagination, 'columns'>>;

type TProps = TTablePropsWithCustomPagination | TTablePropsWithoutPagination;

export default function Table(props: TProps) {
  const {
    pagination,
    pageSize = 10,
    currentPage = 1,
    setCurrentPage,
    columns,
    dataSource,
    totalPages = 1,
    bordered = false,
    emptySpaceEl,
    components,
    spanWidth,
    loading,
    hideScroll,
    handleTableChange,
    rowClassName,
    onRow
  } = props;

  // const totalPages = Math.ceil(dataSource.length / pageSize);

  const handlePageChange = (page: number) => {
    if (setCurrentPage) {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = (): number[] => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <AntTable
      loading={loading}
      locale={emptySpaceEl ? { emptyText: emptySpaceEl } : undefined}
      rowClassName={
        rowClassName
          ? rowClassName
          : (record, index) =>
              `${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-secondary-dark`
      }
      components={
        components
          ? components
          : {
              body: {
                // row: (props: any) => {
                //   return (
                //     <tr className="bg-tertiary-highlight-100 hover:bg-secondary-dark">
                //       {props.children}
                //     </tr>
                //   );
                // },
                cell: (props: any) => {
                  return (
                    <td
                      {...props}
                      style={{ border: bordered ? '' : 'none' }}
                      className=" !p-3"
                    />
                  );
                },
              },
              header: {
                cell: (props: any) => {
                  return (
                    <th
                      {...props}
                      style={{
                        backgroundColor: '#F9FAFF',
                        border: bordered ? '' : 'none',
                      }}
                      className=" !px-3"
                    >
                      {props.children}
                    </th>
                  );
                },
              },
            }
      }
      scroll={hideScroll ? {} : { x: spanWidth || 1050 }}
      className="w-full"
      sticky
      onRow={onRow}
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
      showSorterTooltip={false}
      onChange={handleTableChange}
      footer={() =>
        pagination && (
          <Flex justify="center" align="center">
            <Button
              iconPosition="start"
              type="link"
              icon={
                <img
                  src={currentPage === 1 ? arrow_left : arrow_left_active}
                  className="mr-1"
                />
              }
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className={`text-xs font-semibold font-open-sans ${currentPage === 1 ? 'text-tertiary' : 'text-tertiary-filter'}`}
            >
              Previous
            </Button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`mx-1 px-3 py-1 ${currentPage === page ? 'bg-primary-dark text-white !rounded-full' : 'bg-transparent text-primary-dark'}`}
              >
                {page}
              </button>
            ))}
            <Button
              iconPosition="end"
              type="link"
              icon={
                <img
                  src={
                    currentPage === totalPages
                      ? arrow_right
                      : arrow_right_active
                  }
                  className="ml-1"
                />
              }
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`text-xs font-semibold font-open-sans ${currentPage === totalPages ? 'text-tertiary' : 'text-tertiary-filter'}`}
            >
              Next
            </Button>
          </Flex>
        )
      }
    />
  );
}
