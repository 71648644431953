import React, { useState, useEffect, useRef } from 'react';
import 'miew/dist/miew.min.css';
import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';
import { Ketcher } from 'ketcher-core';
import 'ketcher-react/dist/index.css';
import Miew from 'miew';
import styled from '@emotion/styled';
import './k_styles.css';

// Ensure Miew is available globally
(window as any).Miew = Miew;

const CenteredContainer = styled.div`
  margin-top: 7px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  max-height: 100vh;
  max-width: 100vw;
`;

const GridWrapper = styled.div`
  height: 55vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  grid-template-columns: 1fr 270px 320px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'Ketcher Panel Output';

  & > div {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
`;

const structServiceProvider = new StandaloneStructServiceProvider();

type TProps = {
  experimentDetailsData: any;
};
const ViewKetcher = ({ experimentDetailsData }: TProps) => {
  const ketcherRef = useRef<Ketcher | null>(null);
  const [lastSmiles, setLastSmiles] = useState<string>('');

  const handleOnInit = (ketcher: Ketcher) => {
    ketcherRef.current = ketcher;
    (window as any).ketcher = ketcher;
  };

  useEffect(() => {
    const fetchSmiles = async () => {
      if (ketcherRef.current) {
        const smiles = await ketcherRef.current.getSmiles();
        if (smiles !== lastSmiles) {
          setLastSmiles(smiles);
        }
      }
    };

    const interval = setInterval(fetchSmiles, 1000); // Poll every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [lastSmiles]); //, onSmilesChange

  // useEffect(() => {
  //   if (experimentDetailsData && ketcherRef.current) {
  //     ketcherRef.current.setMolecule(experimentDetailsData?.reaction_scheme);
  //     // ketcherRef.current.setMolecule(
  //     //   experimentDetailsData?.folder_route_scheme_id
  //     // ); // Load the molecule
  //   }
  // }, [experimentDetailsData]); //tabClicked

  useEffect(() => {
    if (experimentDetailsData && ketcherRef.current) {
      console.log(
        'Loading new reaction_scheme:',
        experimentDetailsData?.reaction_scheme
      );

      // Clear existing molecule first
      ketcherRef.current.setMolecule('');

      // Delay setting the molecule to ensure Ketcher is fully initialized
      setTimeout(() => {
        console.log(
          'Setting new molecule after clear:',
          experimentDetailsData?.reaction_scheme
        );

        if (experimentDetailsData?.reaction_scheme) {
          ketcherRef.current?.setMolecule(
            experimentDetailsData.reaction_scheme
          );
        } else {
          console.warn('No valid reaction_scheme to load.');
        }
      }, 500); // 500ms delay to allow Ketcher to fully initialize
    }
  }, [experimentDetailsData]); // Trigger the effect only when experimentDetailsData changes

  return (
    <CenteredContainer>
      <GridWrapper className="disable_Style">
        <Editor
          errorHandler={(message: string) => null}
          staticResourcesUrl={''}
          structServiceProvider={structServiceProvider}
          onInit={handleOnInit}
        />
      </GridWrapper>
    </CenteredContainer>
  );
};

export default ViewKetcher;
