import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Row } from 'antd';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateExperimentLimit } from 'features/Admin/redux/slice';
import { AppDispatch, RootState } from 'app/store';
import RemarksModal from 'common/RemarksModal';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  userDataById?: any;
  experimentLimit?: any;
  handleGetExperimentLimit?: () => void;
};

export default function FolderLimitModal({
  isOpen,
  onClose,
  experimentLimit,
  handleGetExperimentLimit,
}: TProps) {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { updateExperimentLimitLoading } = useSelector(
    (state: RootState) => state.admin
  );

  const handleOnFinish = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isRemarksModalOpen) {
          setIsRemarksModalOpen(false);
          const payload = {
            ...values,
            id: experimentLimit[0]?.id,
            is_active: false,
            change_remarks: remark,
          };
          dispatch(updateExperimentLimit(payload)).then((res: any) => {
            if (res.payload.success) {
              onClose();
              form.resetFields();
              handleGetExperimentLimit?.();
            }
          });
        } else {
          setIsRemarksModalOpen(true);
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      headerTitle={'Experiment Limit Per Folder'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => handleOnFinish('')}
      onCancel={() => form.resetFields()}
      btnLoading={updateExperimentLimitLoading}
      agreeText={'Save'}
    >
      <Form
        id="add_user_modal_form"
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        <Row className="mt-2 mb-2 ml-3 font-semibold text-tertiary-dark">
          <Col span={24}>
            <span>Current experiment limit: </span>
            <span className="ml-1">
              {experimentLimit?.length > 0
                ? experimentLimit[0]?.experiment_limit
                : 'NA'}
            </span>
          </Col>
        </Row>
        <div className="!p-4">
          <Row>
            <Col span={24}>
              <FormInput
                marginBottom="medium"
                name="limit"
                label={
                  <span className="text-tertiary-dark">Experiment Limit</span>
                }
                defaultStyle={false}
                placeholder="Enter experiment limit"
                required
                validateMsg="experiment limit is required"
                type="number"
              />
            </Col>
          </Row>

          {/* change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleOnFinish(value)}
            loading={updateExperimentLimitLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      </Form>
    </Modal>
  );
}
