import {
  Flex,
  Spin,
  Tooltip,
  Badge,
  Switch,
  Space,
  Popover,
  Button,
  Input,
  Form,
} from 'antd';
import Table from 'common/Table';
import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import UserModal from './UserModal';
import { getRoleName } from 'utilities/helpers';
import { ADMIN } from 'utilities/roleRights';
import TextArea from 'antd/es/input/TextArea';
import FormInput from 'common/FormInput';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { createUser } from 'features/Admin/redux/slice';

type listProps = {
  searchUser: string;
  usersList: any;
  usersListLoading: boolean;
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
  handelGetUserList: any;
  createUserLoading: boolean;
};

export default function ListView({
  searchUser,
  usersList,
  usersListLoading,
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM,
  handelGetUserList,
  createUserLoading,
}: listProps) {
  const [idToUpdate, setIdToUpdate] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDataById, setUserDataById] = useState({});
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();

  //handle enabling/disabling user
  const handleUpdateUserStatus = (status: boolean) => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        const payload = {
          id: idToUpdate,
          is_active: status,
          blocked_reason: reason,
        };
        dispatch(createUser(payload)).then((res: any) => {
          if (res?.payload?.success) {
            setIdToUpdate(null);
            form.resetFields();
            handelGetUserList();
          }
        });
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    // const reason = form.getFieldValue(['reason']);
    // const payload = {
    //   id: idToUpdate,
    //   is_active: status,
    //   blocked_reason: reason,
    // };
    // dispatch(createUser(payload)).then((res: any) => {
    //   if (res?.payload?.success) {
    //     setIdToUpdate(null);
    //     form.resetFields();
    //     handelGetUserList();
    //   }
    // });
  };

  //content for desabling the user
  const contentForDisabling = (
    <Form form={form}>
      <div className="flex flex-col gap-2">
        <span className="text-xs font-semibold font-open-sans">
          Do you want to disable this user?
        </span>
        <FormInput
          name="reason"
          type="textarea"
          placeholder="Enter a reason"
          required
          rules={[
            {
              required: true,
              message: 'Reason is required',
            },
          ]}
        />
      </div>
      <div className="flex justify-around ">
        <Button
          size="small"
          className="font-medium text-xs font-open-sans hover:!text-primary border hover:!border-primary"
          onClick={() => setIdToUpdate(null)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          className="font-medium text-xs text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:!border-primary"
          onClick={() => handleUpdateUserStatus(false)}
          loading={createUserLoading}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );

  //content for enabling the user
  const contentForEnabling = (
    <div className="flex flex-col gap-4">
      <span className="text-xs font-semibold font-open-sans">
        Do you want to enable this user?
      </span>
      <div className="flex justify-around ">
        <Button
          size="small"
          className="font-medium text-xs font-open-sans hover:!text-primary border hover:!border-primary"
          onClick={() => setIdToUpdate(null)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          className="font-medium text-xs text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:!border-primary"
          onClick={() => handleUpdateUserStatus(true)}
          loading={createUserLoading}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const handleModalOpen = (record: any) => {
    setUserDataById(record);
    setIsModalOpen(true);
  };
  const handleModalClose = () => setIsModalOpen(false);

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Employee ID
        </label>
      ),
      dataIndex: 'employee_id',
      key: 'employee_id',
      width: '10%',
      render: (text: string) => (
        <>
          <p className="textStyle">{text ? text : 'NA'}</p>
        </>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Name
        </label>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {record.is_new && (
                <Badge.Ribbon
                  placement="start"
                  text="New"
                  className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center ${index === 0 ? '-top-4' : '-top-5'} !-left-4 text-2xs bg-primary`}
                ></Badge.Ribbon>
              )}
              {text}
            </p>
          </>
        );
      },
      //renderColumn,
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Email
        </label>
      ),
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      render: (text: string) => (
        <>
          <p className="textStyle">{text || "NA"}</p>{' '}
        </>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Role
        </label>
      ),
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      render: (text: string) => (
        <>
          <p className="textStyle">{getRoleName(text)}</p>
        </>
      ),
    },

    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_: any, record: any) => {
        return (
          <Space>
            {record.isActive && (
              <Tooltip
                title={'Edit'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              >
                <EditOutlined
                  onClick={() => handleModalOpen(record)}
                  className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
                />
              </Tooltip>
            )}
            <Popover
              title=""
              open={idToUpdate === record.key}
              content={
                record.isActive ? contentForDisabling : contentForEnabling
              }
              trigger={['click']}
              placement="topLeft"
              overlayClassName=" w-1/4"
            >
              <Tooltip
                title={record.isActive ? 'Disable' : 'Enable'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              >
                <Switch
                  size="small"
                  value={record.isActive}
                  onChange={() => setIdToUpdate(record.key)}
                />
              </Tooltip>
            </Popover>
          </Space>
        );
      },
    },
  ];

  const userList = usersList?.map((item: any) => {
    return {
      key: item?.id,
      name: `${item?.first_name} ${item?.last_name}`, //item?.display_name,
      email: item?.email,
      role: item.role?.role,
      assignedDate: 'not given',
      status: item?.is_active,
      action: 'View',
      first_name: item?.first_name,
      last_name: item?.last_name,
      employee_id: item?.employee_id,
      roleDetails: item?.role,
      isActive: item?.is_active,
      mobile_phone: item?.mobile_phone,
    };
  });

  return (
    <Spin spinning={usersListLoading}>
      <Flex className="relative h-full m-2 overflow-auto sticky-footer">
        <Table
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={userList}
        />
        <UserModal
          isEdit={true}
          addUserLoading={false}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          userDataById={userDataById}
          handelGetUserList={handelGetUserList}
        />
      </Flex>
    </Spin>
  );
}
