import React, { useEffect } from 'react';
import { Flex, Button, Spin } from 'antd';
import Card from 'common/Card';
import Label from 'common/Label';
import CompoundList from './components/CompoundList';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getprojectDetail } from '../../../proposalLeadFlow/dashboard/redux/slice';
import { useParams } from 'react-router-dom';
import download_icon from 'assets/icons/download_icon.svg';
import FolderList from 'features/sectionHeadFlow/folders/components/FolderList';
import { TabTitle } from 'utilities/helpers';

// type TCompoundCode = {
//   compoundCode: string;
//   aliasCode: string;
//   quantityRequested: string;
//   purityRequested: string;
// };

type TFolder = {
  id: number;
  folderName: string;
};

const FOLDERS: TFolder[] = [
  { id: 1, folderName: 'GA005/A01' },
  { id: 2, folderName: 'GA005/A02' },
];

interface payload {
  page: number;
  per_page: number;
  id: number;
}

export default function ProjectDetails() {
  TabTitle('Project Details | NewAtom ELN');
  const dispatch: AppDispatch = useDispatch();

  const param = useParams();

  const { projectDetailData, getprojectDetailLoading, pagination } =
    useSelector((state: RootState) => state.project);

  useEffect(() => {
    const payload: payload = {
      page: 1,
      per_page: 10,
      id: Number(param?.id),
    };
    dispatch(getprojectDetail(payload));
  }, []);

  const compoundCodes = projectDetailData?.project_compounds?.map(
    (item: any) => {
      return {
        id: item.id,
        compoundCode: item.compound_code,
        aliasCode: item.alias_code,
        quantityRequested: item.requested_quantity,
        purityRequested: item.requested_purity,
      };
    }
  );

  const handleFetchDocument = (fileName: string) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };

  return (
    <Spin spinning={getprojectDetailLoading}>
      <Flex vertical gap={20}>
        <Card header={`Project #${projectDetailData?.project_code}`}>
          <div className="flex flex-col gap-3 p-6">
            <Label
              description={projectDetailData?.proposal_code}
              label={'Proposal Code'}
            />
            <Label
              description={
                <span className="capitalize text-transform:">
                  {projectDetailData?.industry_type?.industry_type}
                </span>
              }
              label={'Industry type'}
            />
            <Label
              description={
                <span className="text-transform: none">
                  {projectDetailData?.project_description}
                </span>
              }
              label={'Project Description'}
            />
            <Label
              description={
                <span className="capitalize text-transform:">
                  {projectDetailData?.section_heads?.length > 0
                    ? projectDetailData?.section_heads[0]?.section_head_user
                        ?.display_name
                    : ''}
                </span>
              }
              label={'Section Head'}
            />
            <Label
              description={
                projectDetailData?.documents?.length > 0 ? (
                  <span className="flex items-center gap-2">
                    {projectDetailData?.documents?.map(
                      (item: any, index: number) => (
                        <Button
                          type="link"
                          // onClick={() => {
                          //   handleFetchDocument(item?.fileName);
                          // }}
                          icon={<img src={download_icon} alt="download_icon" />}
                          iconPosition="end"
                          key={item.id}
                          className="border rounded-lg text-primary-dark border-tertiary-highlight-200 hover:scale-105"
                        >
                          <a
                            href={item?.presignedUrl}
                            download={item?.fileName}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.originalName}
                          </a>
                        </Button>
                      )
                    )}
                  </span>
                ) : (
                  'NA'
                )
              }
              label={'File'}
            />
          </div>
        </Card>
        <Card header={'Compounds'}>
          <div className="p-6">
            <CompoundList
              compoundCodes={compoundCodes}
              id={projectDetailData.id}
            />
          </div>
        </Card>
        <Card header={'Folders'}>
          <div className="p-6">
            {/*  <FolderList folders={FOLDERS} id={projectDetailData.id} /> */}
          </div>
        </Card>
      </Flex>
    </Spin>
  );
}
