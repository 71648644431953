import React from 'react';
import { Input, Row, Col } from 'antd';

const { TextArea } = Input;

interface Step {
  description: string;
}

interface TabContentProps {
  step: Step;
  onInputChange: (field: keyof Step, value: string) => void;
}

const Description: React.FC<TabContentProps> = ({ step, onInputChange }) => {
  return (
    <div>
      <div className="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans !mb-2">
        Description
      </div>
      <Row className="mb-4">
        <Col span={24}>
          <TextArea
            value={step?.description}
            // name="description"
            placeholder="Enter description"
            rows={5}
            onChange={(e) => onInputChange('description', e.target.value)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Description;
