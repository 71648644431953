import CreateButton from 'common/CreateButton';
import CompoundCard from './CompoundCard';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

type TCompoundCode = {
  id: number;
  compoundCode: string;
  aliasCode: string;
  quantityRequested: string;
  purityRequested: string;
};
type TProps = {
  compoundCodes: TCompoundCode[];
  id: string | number;
};

export default function CompoundList({ compoundCodes, id }: TProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/projects/create/compound/${id}`);
  };

  if (!compoundCodes?.length) {
    return (
      <div className="flex items-center justify-center cursor-pointer">
        <CreateButton
          subLabel={
            <p className="mt-2 text-sm font-semibold text-center font-open-sans">
              Please create a Compound Code for the project
            </p>
          }
          label={
            <p className="mt-2 text-base font-bold font-open-sans text-primary-dark">
              No Compound Code
            </p>
          }
          iconClassess="w-12"
          borderLess
          onClick={handleClick}
        />
      </div>
    );
  }

  const renderedCompoundList = compoundCodes?.map((code) => {
    return (
      <Col key={code.aliasCode} span={12} md={{ span: 8 }} lg={{ span: 6 }}>
        <CompoundCard
          id={code.id}
          compoundCode={code.compoundCode}
          purityRequested={code.purityRequested}
          quantityRequested={code.quantityRequested}
          aliasCode={code.aliasCode}
        />
      </Col>
    );
  });

  return (
    <Row gutter={10} className="flex items-center">
      {[
        ...renderedCompoundList,
        /*  <Col key={0} span={24} md={{ span: 8 }} lg={{ span: 6 }}>
          <CreateButton label="Create Compound code" onClick={handleClick} />
        </Col>, */
      ]}
    </Row>
  );
}
