import { Button, Flex, Tag } from 'antd';
import Card from 'common/Card';
import Label from 'common/Label';
import ViewKetcher from 'common/ViewKetcher';
import { useEffect, useState } from 'react';
import { getLoggedInUser } from 'utilities/helpers';
import { CHEMIST, TECHNICAL_LEAD } from 'utilities/roleRights';

type TStep = {
  reaction_scheme: string;
  smiles_string: string;
  description: string;
  index?: number;
};

type TProps = {
  data: any;
  steps: TStep[];
  setActiveStep: React.Dispatch<React.SetStateAction<TStep>>;
  activeStep: TStep;
};

const labelClasses =
  'flex-shrink-0 w-full sm:w-[15vw] md:w-[12vw] lg:w-[9vw] xl:w-[7vw] text-xs font-semibold font-open-sans';

export default function FolderView({
  data,
  steps,
  activeStep,
  setActiveStep,
}: TProps) {
  const { role } = getLoggedInUser();

  useEffect(() => {
    setActiveStep({ ...steps[0], index: 0 });
  }, [steps, data]);

  //rendering project chemists and TL
  const getProjectChemists = (is_tl: boolean) => {
    const chemists = data?.project_chemists
      ?.filter((ch: any) => ch.is_tl === is_tl)
      .map((chemist: any) => {
        return chemist.project_chemist?.display_name;
      })
      .join(', ');
    return chemists || 'NA';
  };
  console.log(data);
  return (
    <Flex vertical gap={'1.25rem'}>
      <Card>
        <div className="relative flex flex-col w-full gap-2">
          <div className="flex items-center w-full gap-3">
            <span className="flex-shrink-0 text-xs font-semibold font-open-sans">
              Route:{' '}
            </span>
            <span className="flex-grow text-xs font-open-sans">
              {data?.folder_route?.route || 'NA'}
            </span>
          </div>

          <div>
            <span className="flex-shrink-0 text-xs font-semibold font-open-sans">
              Reaction Scheme:{' '}
            </span>
            <div>
              {steps?.map((step, index) => (
                <Tag
                  key={index}
                  onClick={() => {
                    setActiveStep({ ...step, index });
                  }}
                  style={{
                    backgroundColor:
                      activeStep.index === index ? '#0044D3' : '#f1f1f1',
                    color: activeStep.index === index ? 'white' : 'black',
                    marginRight: '10px',
                  }}
                  className="text-['9px'] cursor-pointer font-semibold lg:text-['9px']  font-open-sans "
                >
                  Step {index + 1}
                </Tag>
              ))}
              <ViewKetcher step={activeStep} />
            </div>
          </div>
        </div>
      </Card>
      <Card>
        <div className="relative flex flex-col w-full gap-2">
          <Label
            label={'Description:'}
            description={activeStep.description || 'NA'}
            labelClasses={labelClasses}
            containerClasses="flex flex-col items-start w-full gap-1 sm:flex-row sm:items-start sm:gap-6 justify-start"
          />
          {data?.folder_creator?.role?.role === CHEMIST &&
          role === TECHNICAL_LEAD ? null : (
            <Label
              label={'Technical Lead:'}
              description={getProjectChemists(true)}
              labelClasses={labelClasses}
            />
          )}
          <Label
            label={'Project Team:'}
            description={getProjectChemists(false)}
            labelClasses={labelClasses}
          />
        </div>
      </Card>
    </Flex>
  );
}
