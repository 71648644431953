// export const TOPICS = [
//     'Improve reaction yield',
//     'Reduce synthesis steps',
//     'Increase product purity',
//     'Optimize catalyst loading',
//     'Propose greener solvents',
//     'Enhance reaction selectivity',
//     'Scale-up production process',
//     'Minimize side reactions',
//     'Suggest alternative reagents',
//     'Design continuous flow',
//   ];
export const TOPICS = [
  'Improve reaction yield',
  'Increase product purity',
  'Optimize catalyst loading',
  'Optimize solvent usage',
  'Propose greener solvents',
  'Suggest process improvements for scale-up',
  'Suggest alternative reagents',
  'Suggest safer approaches',
  'Improve filtration process',
  'Improve drying process',
];
