import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, Flex, Space } from 'antd';
import logo_full from 'assets/icons/logo_full_new.svg';
import logo from 'assets/icons/logo_new.svg';
import { NAVLINKS, type TNavLinks } from 'common/Layout/components/navlinks';
import { NavLink } from 'react-router-dom';
import { getIntitals, getLoggedInUser } from 'utilities/helpers';
import ProfileDropdown from './ProfileDropdown';

type TProps = {
  onClose: () => void;
  isOpen: boolean;
};

export default function SidebarMobile({ onClose, isOpen }: TProps) {
  const user = getLoggedInUser();

  const NavItem = ({
    link,
    isActive,
  }: {
    link: TNavLinks;
    isActive: boolean;
  }) => {
    return (
      <Flex
        vertical
        gap={4}
        className={`mb-1 cursor-pointer relative py-3 bg-tertiary-highlight-100 rounded-lg ${isActive && 'border-e-4'} border-primary-dark`}
      >
        <Flex align="center" gap={'2rem'}>
          <Button
            icon={
              <img
                key={link.id}
                src={isActive ? link.activeIcon : link.icon}
                alt={link.label}
                className="w-10"
              />
            }
            className="!border-none"
          />
          <h6
            className={`font-semibold text-sm font-open-sans text-center ${
              isActive ? 'text-primary' : 'text-tertiary'
            }`}
          >
            {link.label}
          </h6>
        </Flex>
      </Flex>
    );
  };

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    isDisabled: boolean | undefined
  ) => {
    if (isDisabled) {
      e.preventDefault();
    }
    onClose();
  };

  const renderedLinks = NAVLINKS?.filter((link) =>
    link.access?.includes(user.role)
  ).map((link: TNavLinks): JSX.Element => {
    return (
      <NavLink
        onClick={(e) => handleClick(e, link.isDisabled)}
        to={link.path}
        key={link.id}
      >
        {({ isActive }) => (
          <NavItem
            link={link}
            isActive={
              isActive ||
              (window.location.pathname === '/' && link.path === 'dashboard')
            }
          />
        )}
      </NavLink>
    );
  });

  return (
    <Drawer
      title={
        <div className="flex items-center gap-2">
          <img src={logo} alt="logo" className="w-[12%]" />
          <img src={logo_full} alt="logo" className="w-2/5" />
        </div>
      }
      placement="left"
      closable={false}
      onClose={onClose}
      open={isOpen}
      extra={<Button onClick={onClose} icon={<CloseOutlined />} />}
    >
      <Flex
        justify="space-between"
        align="start"
        vertical
        className="h-full gap-6"
      >
        <Flex justify="start" vertical gap={'0.5rem'} className="w-full">
          {renderedLinks}
        </Flex>
        <ProfileDropdown>
          <div className="flex justify-between w-full p-2 bg-tertiary-highlight-200 rounded-xl">
            <div className="flex items-center gap-3">
              <Avatar size="large" className="text-white bg-primary">
                {getIntitals(user?.user_details?.display_name)}
              </Avatar>
              <div className="flex flex-col">
                <span className="text-base font-semibold text-primary font-open-sans">
                  {user?.user_details?.display_name}
                </span>
                <span className="text-sm font-semibold text-tertiary-filter font-open-sans">
                  {user?.user_details?.email}
                </span>
              </div>
            </div>
            <RightOutlined className="text-xl" />
          </div>
        </ProfileDropdown>
      </Flex>
    </Drawer>
  );
}
