function IsJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const hours = 10; // to clear the localStorage after 10 hour
const now = new Date().getTime();
const setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
  localStorage.setItem('setupTime', now.toString());
} else {
  if (now - parseInt(setupTime) > hours * 60 * 60 * 1000) {
    localStorage.clear();
    localStorage.setItem('setupTime', now.toString());
  }
}

const LocalStorage = {
  getItem(key: string): any {
    if (typeof localStorage !== 'undefined') {
      const value = localStorage.getItem(key);
      if (value !== '' && value !== undefined && value !== null) {
        if (IsJsonString(value)) {
          return JSON.parse(value);
        }
        return value;
      }
    }
    return null;
  },
  has(key: string): boolean {
    return !!LocalStorage.getItem(key);
  },
  setItem(key: string, value: any): void {
    if (typeof localStorage !== 'undefined') {
      const item = typeof value === 'string' ? value : JSON.stringify(value);
      localStorage.setItem(key, item);
    }
  },
  updateObject(key: string, change: (data: any) => any): void {
    if (typeof localStorage !== 'undefined' && typeof change !== 'undefined') {
      const data = LocalStorage.getItem(key);
      const update = change(Object.assign({}, data));
      if (typeof update !== 'undefined' && update) {
        LocalStorage.setItem(key, update);
      }
    }
  },
};

export default LocalStorage;
