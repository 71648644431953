import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Dropdown,
  Flex,
  Form,
  Input,
  MenuProps,
  Popover,
} from 'antd';
import FormInput from './FormInput';
import { debounce } from 'lodash';

type TProps = {
  type: 'date' | 'number' | 'switch';
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  setFromDate?: React.Dispatch<React.SetStateAction<any>>;
  setToDate?: React.Dispatch<React.SetStateAction<any>>;
};

export default function ColumnDropdownFilter({
  type,
  setValue,
  setFromDate,
  setToDate,
}: TProps) {
  const [form] = Form.useForm();

  const renderComp = () => {
    switch (type) {
      case 'date':
        return (
          <Flex
            justify="center"
            vertical
            align="center"
            gap={'0.2rem'}
            className="p-2"
          >
            <div className="flex flex-col gap-1">
              <span className="text-xs font-semibold text-primary">From</span>
              <FormInput
                name="from_date"
                noStyle
                placeholder="Enter start date"
                marginBottom="none"
                type="date"
                showTime={false}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs font-semibold text-primary">To</span>
              <FormInput
                name="to_date"
                noStyle
                placeholder="Enter end date"
                marginBottom="none"
                type="date"
                showTime={false}
              />
            </div>
          </Flex>
        );
      case 'number':
        return (
          <div className="flex items-center gap-1">
            <span className="text-xs font-semibold font-open-sans shrink-0 text-pretty text-primary">
              Experiments per page :
            </span>
            <FormInput
              name="per_page_items"
              noStyle
              placeholder="Enter per page"
              defaultStyle
              type="number"
              min={5}
              defaultValue={15}
              max={100}
              inputClasses=" !border-none font-open-sans !outline-none focus:!border-none focus:!outline-none text-xs"
            />
          </div>
        );
      default:
        return <div>Nothing</div>;
    }
  };

  //debouncing per page counts
  const debouncedSetPerPageItems = debounce((value: any) => {
    if (setValue) {
      setValue(value);
    }
  }, 300);

  const handleFormValueChange = (changedVal: any, values: any) => {
    if (changedVal.per_page_items) {
      debouncedSetPerPageItems(values.per_page_items);
    }

    if (changedVal.from_date && values.to_date && setFromDate && setToDate) {
      setFromDate(values.from_date);
      setToDate(values.to_date);
    }
    if (changedVal.to_date && values.from_date && setToDate && setFromDate) {
      setFromDate(values.from_date);
      setToDate(values.to_date);
    }
  };

  return (
    <Form
      form={form}
      onValuesChange={handleFormValueChange}
      id="sorter-form"
      layout="vertical"
    >
      {renderComp()}
    </Form>
  );
}
