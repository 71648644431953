import { Alert, Col, Form, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { changePassword, logoutUser } from 'features/login/redux/slice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ChangePasswordModal({ isOpen, onClose }: TProps) {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state: RootState) => state.login);

  const handleOnFinish = (values: any) => {
    if (!values.password || !values.confirm_password) return;

    const payload = {
      new_password: values.password,
    };

    dispatch(changePassword(payload)).then((res: any) => {
      if (res?.payload?.success) {
        dispatch(logoutUser());
        navigate('/login', { replace: true });
      }
    });
  };

  return (
    <Modal
      btnLoading={loading}
      headerTitle="Change Password"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      cancelText={'Cancel'}
      agreeText={'Change'}
      spanWidth="!w-[25rem]"
    >
      <Form
        className="px-3"
        id="change_password_modal_form"
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        <>
          <Alert
            message={
              <span className="inline-block text-xs font-semibold font-open-sans">
                Changing your password will log you out of this app. You will
                need to log in again with the new password.
              </span>
            }
            type="warning"
            showIcon
            className="mb-2 !bg-[#fffdf0] flex !items-start custom-alert-icon"
          />
          <Row>
            <Col span={24}>
              <FormInput
                name="password"
                label={'New password'}
                placeholder="Enter new password"
                marginBottom="small"
                required
                type='password'
                validateMsg="New password is required"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInput
                name="confirm_password"
                label={'Confirm password'}
                placeholder="Re-enter new password"
                marginBottom="small"
                type='password'
                rules={[
                  {
                    required: true,
                    message: 'New Password is required',
                  },
                  {
                    validator(
                      rule: any,
                      value: string | undefined,
                      callback: (msg?: string) => void
                    ) {
                      const password = form.getFieldValue('password');
                      if (value && value !== password) {
                        callback('Password and confirm password should match');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
}
