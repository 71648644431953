import {
  Button,
  Col,
  Empty,
  Form,
  FormInstance,
  Row,
  Tag,
  Tooltip,
} from 'antd';
import { AppDispatch, RootState } from 'app/store';
import Card from 'common/Card';
import { Upload } from 'common/fileUploader';
import FormInput from 'common/FormInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnalysis,
  getAnalysisList,
  getTypeOfTestList,
  setExperimentId,
} from '../redux/slice';
import deleteIcon from 'assets/icons/delete.svg';
import { getLoggedInUser } from 'utilities/helpers';
import SectionHeader from 'common/SectionHeader';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';

type TItemProps = {
  id: string;
  serial_number: number | string;
  PLC: string;
  docs: any[];
  description: string;
  isCreate: boolean;
  isView: boolean;
  dispatch: AppDispatch;
  projectId: string | number;
  experimentId: string | null;
  isEditing: boolean;
};

function UPLCItem({
  id,
  PLC,
  docs,
  description,
  isCreate,
  isView,
  serial_number,
  dispatch,
  projectId,
  experimentId,
  isEditing,
}: TItemProps) {
  const [idToRemove, setIdToRemove] = useState<string | null>(null);
  const { addAnalysisLoading, isExternalChemist } = useSelector(
    (state: RootState) => state.experiments
  );

  const handleDeleteAnalysis = (remark: string) => {
    const formdata = new FormData();

    formdata.append('id', id);
    formdata.append('is_active', 'false');
    formdata.append('change_remarks', remark);
    formdata.append('section', 'analysis');
    formdata.append(
      'is_edit',
      isView && isExternalChemist ? 'false' : isView ? 'true' : 'false'
    );

    dispatch(addAnalysis(formdata)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToRemove(null);
        dispatch(
          getAnalysisList({
            project_id: projectId,
            experiment_id: experimentId,
          })
        );
      } else {
        setIdToRemove(null);
      }
    });
  };

  return (
    <Row className="flex w-full overflow-hidden rounded-xl md:bg-transparent bg-tertiary-highlight-200">
      <Col span={isCreate || (isView && isEditing) ? 22 : 24}>
        <Row>
          <Col
            span={8}
            md={{ span: 1 }}
            className="px-1 py-1 text-xs font-bold text-center lg:py-3 md:text-sm text-primary font-open-sans"
            style={{ backgroundColor: '#EFECFF' }}
            title={serial_number as string}
          >
            {serial_number}
          </Col>
          <Col
            span={8}
            md={{ span: 3 }}
            className="px-1 py-2 text-xs font-bold text-center lg:py-3 md:text-sm text-primary font-open-sans border-s border-e"
            style={{ backgroundColor: '#F4F1FF' }}
            title={PLC}
          >
            {PLC}
          </Col>
          <Col
            span={8}
            md={{ span: 4 }}
            className="w-full px-1 py-2 text-xs font-semibold lg:py-3 md:text-sm text-tertiary-dark font-open-sans border-s border-e"
            style={{ backgroundColor: '#F6F6F6' }}
          >
            {docs.map((doc) => {
              return (
                <Tooltip
                  title={doc?.originalName}
                  overlayClassName="custom-tooltip"
                  key={doc?.originalName}
                >
                  <Tag className="text-ellipsis w-[98%] overflow-hidden">
                    <a
                      type="link"
                      href={doc?.presignedUrl}
                      download={doc?.originalName}
                      target="_blank"
                      rel="noreferrer"
                      className="font-open-sans hover:!text-primary"
                    >
                      {doc?.originalName}
                    </a>
                  </Tag>
                </Tooltip>
              );
            })}
          </Col>
          <Col
            span={24}
            md={{ span: 16 }}
            className="px-3 py-2 text-xs lg:py-3 md:text-xs lg:text-sm text-start text-tertiary-dark font-open-sans border-s"
            style={{ backgroundColor: '#F6F6F6' }}
            title={description}
          >
            {convertTextWithChemicalFormulas(description)}
          </Col>
        </Row>
      </Col>

      {(isCreate || (isView && isEditing)) && (
        <Col span={2} className="my-auto">
          <div className="w-full mx-auto text-center">
            <Button
              loading={idToRemove && idToRemove === id ? true : false}
              className="!bg-transparent"
              icon={<img src={deleteIcon} />}
              onClick={() => {
                if (isCreate) {
                  handleDeleteAnalysis('');
                }
                setIdToRemove(id);
              }}
            />
          </div>
        </Col>
      )}

      {/*change remarks modal */}
      <RemarksModal
        isOpen={Boolean(idToRemove) && isView}
        onApprove={(value) => handleDeleteAnalysis(value)}
        loading={addAnalysisLoading}
        onClose={() => setIdToRemove(null)}
      />
    </Row>
  );
}

type TProps = {
  totalSteps: number;
  projectId: string | number;
  analysisList: any[];
  isCreate: boolean;
  isView: boolean;
  parentForm: FormInstance<any>;
  experimentId: string | null;
  folderId: string | undefined;
  experimentStatus: string;
};

export default function Analysis({
  totalSteps,
  projectId,
  analysisList,
  isCreate,
  isView,
  parentForm,
  experimentId,
  folderId,
  experimentStatus,
}: TProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [formattedInput, setFormattedInput] = useState<string>(''); // Formatted input state
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { role } = getLoggedInUser();

  const [form] = Form.useForm();
  const {
    addAnalysisLoading,
    analysisListLoading,
    typeOfTestList,
    typeOfTestLoading,
    isExternalChemist
  } = useSelector((state: RootState) => state.experiments);
  const [files, setFiles] = useState<File[]>([]);
  const serialNumbers = Array.from({ length: totalSteps }, (_, i) => {
    return { label: i + 1, value: i + 1 };
  });

  const renderedTypeOfTest = typeOfTestList?.map((test) => {
    return { label: test.type_of_test, value: test.id };
  });

  const handleSave = () => {
    if (isEditing) {
      console.log('>>saving data');
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleRemoveFile = (index: number | string) => {
    const allFiles = [...files];
    const filteredFiles = allFiles.filter((file, i) => file.name !== index);
    setFiles(filteredFiles);
  };

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (changedVal.observation) {
      setFormattedInput(changedVal.observation);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        observation: convertTextWithChemicalFormulas(formattedInput),
      });
    }
  };
  const handleAddAnalysis = (values: any) => {
    const { serial_number, type_of_test_id, observation } = values;

    let formdata = new FormData();
    const experiment_id = experimentId || null;

    files.forEach((file) => {
      formdata.append('attachments', file);
    });
    formdata.append('project_id', String(projectId));
    formdata.append('serial_number', serial_number);
    formdata.append('type_of_test_id', type_of_test_id);
    formdata.append(
      'observation',
      convertChemicalFormulasToSimpleText(observation)
    );
    formdata.append('experiment_id', experiment_id as string);
    formdata.append('folder_id', folderId as string);
    formdata.append('change_remarks', values.remark as string);
    formdata.append('section', 'analysis');
    formdata.append(
      'is_edit',
      isView && isExternalChemist ? 'false' : isView ? 'true' : 'false'
    );
    dispatch(addAnalysis(formdata)).then((res: any) => {
      if (res?.payload?.success) {
        setIsRemarksModalOpen(false);
        const experiment_id = res?.payload?.experiment_id || experimentId;
        form.resetFields();
        setFiles([]);
        dispatch(setExperimentId(experiment_id));
        dispatch(getAnalysisList({ project_id: projectId, experiment_id }));
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isCreate || isView) {
          handleAddAnalysis({ remark, ...values });
        } else {
          if (isRemarksModalOpen) {
            handleAddAnalysis({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    dispatch(getTypeOfTestList({}));
  }, [dispatch, getTypeOfTestList]);

  //adding placeholder value to show attachments error as its not form element
  useEffect(() => {
    if (files.length) {
      form.setFieldsValue({ attachments: 'yes' });
    }
  }, [files]);

  return (
    <Card
      header={
        <SectionHeader
          isEditing={isEditing}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Analysis"
          experimentId={experimentId}
        />
      }
    >
      <Form
        id="analysis_form"
        onFinish={handleAddAnalysis}
        onValuesChange={handleFormValuesChange}
        onKeyDown={handleKeyDown}
        form={form}
        layout="vertical"
      >
        <div className="flex flex-col gap-3 p-6">
          {(isCreate || (isView && isEditing)) && (
            <Row gutter={10}>
              <Col span={24} md={{ span: 4 }}>
                <FormInput
                  name="serial_number"
                  type="select"
                  placeholder="Select step #"
                  label="Serial Number"
                  defaultStyle
                  required={serialNumbers.length ? true : false}
                  options={serialNumbers}
                  validateMsg="Serial number is required"
                />
              </Col>
              <Col span={24} md={{ span: 4 }}>
                <FormInput
                  name="type_of_test_id"
                  type="select"
                  placeholder="Select type"
                  label="Type of test"
                  defaultStyle
                  loading={typeOfTestLoading}
                  options={renderedTypeOfTest}
                  required={serialNumbers.length ? true : false}
                  validateMsg="Type of test is required"
                />
              </Col>
              <Col span={24} md={{ span: 6 }} className="cursor-pointer">
                {/* <Label label={"Attachment"}/> */}
                <FormInput
                  label={'Attachment'}
                  name="attachments"
                  type="placeholder"
                  defaultStyle
                  rules={[
                    {
                      validator(
                        rule: any,
                        value: string | undefined,
                        callback: (msg?: string) => void
                      ) {
                        const observation = form.getFieldValue('observation');
                        if (!observation && !value) {
                          callback('Attachments are required');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                  placeholderComponent={
                    <Upload
                      type="horizontal"
                      files={files}
                      setFiles={setFiles}
                      limit={10}
                      allowedFileTypes={['image/*']}
                      hideFilename
                    />
                  }
                />
              </Col>
              <Col span={24} md={{ span: 7 }}>
                <FormInput
                  name="observation"
                  placeholder="Enter result/observations"
                  label="Result/Observations"
                  defaultStyle
                  rules={[
                    {
                      validator(
                        rule: any,
                        value: string | undefined,
                        callback: (msg?: string) => void
                      ) {
                        const attachments = form.getFieldValue('attachments');
                        if (!attachments && !value) {
                          callback('Observation is required');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                />
              </Col>
              <Col
                span={24}
                md={{ span: 2 }}
                lg={{ span: 3 }}
                className="flex items-center justify-center mt-2 lg:mt-3"
              >
                <Button
                  type="primary"
                  className={`bg-transparent border text-primary border-primary ${serialNumbers.length ? 'hover:!text-white hover:!bg-primary' : ''}`}
                  loading={addAnalysisLoading}
                  disabled={!serialNumbers.length}
                  onClick={() => handleSubmit('')}
                >
                  Add
                </Button>
              </Col>
            </Row>
          )}

          <Row gutter={10} className="flex flex-1 gap-2 px-2">
            {files.map((file) => {
              return (
                <Tag
                  key={file.name}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleRemoveFile(file.name);
                  }}
                >
                  {file?.name}
                </Tag>
              );
            })}
          </Row>

          {isView && !analysisList.length && <Empty />}
          {analysisList.length
            ? analysisList.map((item) => {
                return (
                  <UPLCItem
                    isEditing={isEditing}
                    experimentId={experimentId}
                    projectId={projectId}
                    dispatch={dispatch}
                    key={item.id}
                    isCreate={isCreate}
                    isView={isView}
                    serial_number={item.serial_number}
                    id={item.id}
                    PLC={
                      renderedTypeOfTest.find(
                        (type: any) => type.value === item.type_of_test_id
                      )?.label || ''
                    }
                    docs={item.analysis_attachments}
                    description={item.observation}
                  />
                );
              })
            : null}

          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={addAnalysisLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      </Form>
    </Card>
  );
}
