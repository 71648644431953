import apiClient from 'utilities/apiClient';

interface ProjectListPayload {
  [key: string]: any;
}

interface ProjectListResponse {
  success: boolean;
  data?: any[];
  pagination?: any;
}

interface ProjectDetailPayload {
  id: number;
}

interface ProjectDetailResponse {
  success: boolean;
  data?: any;
  pagination?: any;
}

export const projectList = (
  payload: ProjectListPayload
): Promise<ProjectListResponse> => {
  return apiClient.get(apiClient.Urls.projectList, payload, true);
};

export const projectDetail = (
  payload: ProjectDetailPayload
): Promise<ProjectDetailResponse> => {
  return apiClient.get(apiClient.Urls.projectDetail, payload, true);
};
