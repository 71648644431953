import { type TCards } from '..';

export default function CountsCard({
  description,
  title,
  count,
  countColor,
  icon,
}: TCards) {
  return (
    <div className="relative flex flex-col gap-2 px-6 py-4 bg-white font-open-sans">
      <div className="flex flex-col gap-3 font-open-sans">
        <h6 className="font-semibold text-md text-primary font-open-sans">
          {title}
        </h6>
        <span style={{ color: countColor }} className="text-3xl font-bold">
          {count}
        </span>
      </div>
      <span className="text-sm text-tertiary-filter font-open-sans">
        {description}
      </span>
      <img
        src={icon}
        className="absolute top-[15%] md:top-[5%] right-4 h-3/5 md:h-2/5 lg:h-3/5"
      />
    </div>
  );
}
