import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}
interface ResponseWithPagination {
  success: boolean;
  message: string;
  data: any;
  pagination: any;
}

export const experimentsList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.experimentsList, payload, true);
};

export const experimentDetails = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.exprimentDetails, payload, true);
};

export const createProcedure = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createProcedure, payload, true);
};

export const procedureList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.procedureList, payload, true);
};

export const createAnalysis = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createAnalysis, payload, true, 'file');
};

export const analysisList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.analysisList, payload, true);
};

export const createMaterials = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createMaterials, payload, true);
};

export const experimentCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createExperiment, payload, true, 'file');
};

export const additionalComment = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.additionalComment, payload, true);
};

export const materialsList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.materialsList, payload, true);
};

export const productCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createProduct, payload, true);
};

export const productList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.productList, payload, true);
};

export const typeOfTest = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.typeOfTest, payload, true);
};

export const approveRejectExp = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.patch(apiClient.Urls.approveRejectExperiment, payload, true);
};

export const paraphrase = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.post(apiClient.Urls.paraphrase, payload, true);
};

export const removeRefFile = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.patch(apiClient.Urls.removeRefFile, payload, true);
};

export const experimentTimeline = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.experimentTimeline, payload, true);
};

export const experimentTabCounts = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.experimentTabCounts, payload, true);
};

export const experimentLogs = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.experimentLogs, payload, true);
};
