import { useCallback, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { useState } from 'react';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ListView from './components/ListView';
import UOMModal from './components/UOMModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getUomList } from 'features/Admin/redux/slice';

interface payload {
  page: number;
  perPage: number;
  search: string;
}

const PER_PAGE_ITEM = 15;

export default function UOM() {
  const navigate = useNavigate();
  const [searchUom, setSearchUom] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const dispatch: AppDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { pagination, uomList, uomListLoading, createUomLoading } = useSelector(
    (state: RootState) => state.admin
  );

  useEffect(() => {
    // const payload: payload = {
    //   page: currentPage,
    //   perPage: PER_PAGE_ITEM,
    //   search: searchUom,
    // };
    // dispatch(getUomList(payload));
    handelGetUomList();
  }, [searchUom, currentPage]);

  const handelGetUomList = () => {
    const payload: payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchUom,
    };
    dispatch(getUomList(payload));
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchUom(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          UOM
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by UOM code or name',
            }}
            filters={false}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
          <Button
            size="middle"
            icon={<PlusOutlined />}
            className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
            onClick={handleModalOpen}
          >
            Add UOM
          </Button>
        </div>
      </Flex>
      <ListView
        searchUom={searchUom}
        uomList={uomList}
        handelGetUomList={handelGetUomList}
        uomListLoading={uomListLoading}
        pagination={pagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        PER_PAGE_ITEM={PER_PAGE_ITEM}
        addUserLoading={createUomLoading}
      />
      <UOMModal
        addUserLoading={createUomLoading}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        handelGetUomList={handelGetUomList}
      />
    </Flex>
  );
}
