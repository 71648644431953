import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { projectList, projectDetail } from './api';

interface ProjectState {
  getprojectListLoading: boolean;
  projectListData: any[];
  projectListDropdownData: any[];
  getprojectListDropdownDataLoading: boolean;
  pagination?: any;
  getprojectDetailLoading: boolean;
  projectDetailData: any;
}

const initialState: ProjectState = {
  getprojectListLoading: false,
  projectListData: [],
  getprojectDetailLoading: false,
  projectDetailData: {},
  projectListDropdownData: [],
  getprojectListDropdownDataLoading: false,
};

const actions = {
  GET_PROJECT_LIST: 'getprojectList/GET_PROJECT_LIST',
  GET_PROJECT_LIST_DROPDOWN: 'getprojectList/GET_PROJECT_LIST_DROPDOWN',
  GET_PROJECT_DETAIL: 'getprojectDetail/GET_PROJECT_DETAIL',
};

export const getprojectList = createAsyncThunk(
  actions.GET_PROJECT_LIST,
  async (payload: Record<string, any>) => {
    const response = await projectList(payload);
    return response;
  }
);

export const getprojectList_dropdown = createAsyncThunk(
  actions.GET_PROJECT_LIST_DROPDOWN,
  async (payload: Record<string, any>) => {
    const response = await projectList(payload);
    return response;
  }
);
export const getprojectDetail = createAsyncThunk(
  actions.GET_PROJECT_DETAIL,
  async (payload: Record<string, any>) => {
    const response = await projectDetail({ id: Number(payload?.id) });
    return response;
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get project list
    builder
      .addCase(getprojectList.pending, (state) => {
        state.getprojectListLoading = true;
      })
      .addCase(getprojectList.fulfilled, (state, action) => {
        state.getprojectListLoading = false;
        const { success, data, pagination } = action.payload;

        if (success) {
          state.projectListData = data ? data : [];
          state.pagination = pagination || null;
        } else {
          state.projectListData = [];
          // message.error(action.payload.message);
        }
      })
      .addCase(getprojectList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getprojectListLoading = false;

        message.error(msg);
      });

    //get project list in dropdown
    builder
      .addCase(getprojectList_dropdown.pending, (state) => {
        state.getprojectListDropdownDataLoading = true;
      })
      .addCase(getprojectList_dropdown.fulfilled, (state, action) => {
        state.getprojectListDropdownDataLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.projectListDropdownData = data ? data : [];
        } else {
          state.projectListDropdownData = [];
        }
      })
      .addCase(getprojectList_dropdown.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getprojectListDropdownDataLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getprojectDetail.pending, (state) => {
        state.getprojectDetailLoading = true;
      })
      .addCase(getprojectDetail.fulfilled, (state, action) => {
        state.getprojectDetailLoading = false;
        const { success, data, pagination } = action.payload;

        if (success) {
          state.projectDetailData = data ? data : [];
          state.pagination = pagination || null;
        } else {
          state.projectDetailData = [];
          // message.error(action.payload.message);
        }
      })
      .addCase(getprojectDetail.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getprojectDetailLoading = false;

        message.error(msg);
      });
  },
});

export default projectSlice.reducer;
