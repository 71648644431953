import Label from 'common/Label';
// import deleteIcon from 'assets/icons/delete.svg';
// import { useDispatch, useSelector } from 'react-redux';
//import { createProjectCompound } from '../../../redux/slice';
// import { AppDispatch, RootState } from 'app/store';
import { Spin } from 'antd';
// import { useNavigate, useParams } from 'react-router-dom';
// import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import { useState } from 'react';

type TProps = {
  id: number;
  compoundCode: string;
  aliasCode: string;
  quantityRequested: string;
  purityRequested: string;
};

const containerClasses = 'sm:gap-2';
const descriptionClasses =
  'flex-grow text-[10px] sm:text-xs text-tertiary-dark';
const labelClasses = ' text-tertiary-filter text-[10px] sm:text-xs font-bold';

export default function CompoundCard({
  id,
  compoundCode,
  aliasCode,
  quantityRequested,
  purityRequested,
}: TProps) {
  const [deleteInProgress, setDeleteInProgress] = useState<null | number>(null);
  // const param = useParams();
  // const navigate = useNavigate();
  // const dispatch: AppDispatch = useDispatch();

  // const handleDeleteCompound = (id: number) => {
  //   setDeleteInProgress(id);
  //   // dispatch(createProjectCompound({ id: id, is_active: false }))
  //   //   .then((res: any) => {
  //   //     if (res.payload.success) {
  //   //       setDeleteInProgress(null);
  //   //       message.success('Succesfully deleted compound');
  //   //       if (!param.id) {
  //   //         navigate('/dashboard');
  //   //       } else {
  //   //         dispatch(
  //   //           getprojectDetail({
  //   //             id: Number(param.id),
  //   //           })
  //   //         );
  //   //       }
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     setDeleteInProgress(null);
  //   //   });
  // };

  return (
    <Spin spinning={deleteInProgress === id}>
      <div className="relative flex flex-col w-full gap-2 p-2 border rounded-lg sm:p-4 border-tertiary-highlight-200 hover:bg-tertiary-highlight-100 hover:scale-[1.01]">
        <Label
          label={'Compound Code:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={compoundCode}
        />
        <Label
          label={'Alias Code:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={aliasCode}
        />
        <Label
          label={'Quantity Requested:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={quantityRequested}
        />
        <Label
          label={'Purity Requested (%):'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={purityRequested}
        />
        {/* <img
          src={deleteIcon}
          alt="delete icon"
          onClick={() => handleDeleteCompound(id)}
          className="absolute cursor-pointer top-1 right-1 hover:opacity-75"
        /> */}
      </div>
    </Spin>
  );
}
