import { Button, Flex, Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useResponsiveness } from 'hooks/useResponsiveness';
import SideNavBar from 'common/Layout';
import { MenuOutlined } from '@ant-design/icons';
import SidebarMobile from 'common/Layout/components/SidebarMobile';
import { useState } from 'react';

const Home: React.FC = () => {
  const { xs, sm, md, lg, xl, xxl } = useResponsiveness();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleMobileSidebarOpen = () => setIsSidebarOpen(true);
  const handleMobileSidebarclose = () => setIsSidebarOpen(false);

  return (
    <Flex className="h-screen">
      {/* <div className="absolute w-full border-2 border-red-500 top-3 sm:border-pink-500 md:border-green-500 lg:border-blue-500 xl:border-purple-500"></div>
      <div
        className={`border-2 ${xs && 'border-red-500'} w-full absolute ${sm && 'border-pink-500'} ${md && 'border-green-500'} ${lg && 'border-blue-500'} ${xl && 'border-purple-500'}`}
      ></div> */}
      <Layout>
        <SideNavBar />
        <SidebarMobile
          onClose={handleMobileSidebarclose}
          isOpen={isSidebarOpen}
        />
        <Layout>
          <Layout.Content
            className={`${xs ? 'p-2' : sm ? 'p-3' : md ? 'p-4' : lg ? 'p-4' : xl ? 'p-8' : xxl ? 'p-8' : 'p-10'} overflow-auto font-open-sans`}
          >
            <div className="block mb-2 ml-1 sm:hidden">
              <Button
                onClick={handleMobileSidebarOpen}
                type="primary"
                shape="circle"
                className="bg-primary"
                icon={<MenuOutlined />}
              />
            </div>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Flex>
  );
};

export default Home;
