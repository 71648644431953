import { Button, Col, Flex, Form } from 'antd';
import logo from 'assets/newIcon/favicon1.png';
import logo_full from 'assets/icons/logo_full_new.svg';
import { useNavigate } from 'react-router-dom';
import FormInput from 'common/FormInput';
import { useEffect } from 'react';
import { login } from '../redux/slice';
import { getLoggedInUser, TabTitle } from 'utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
// Define the User type
interface Payload {
  employee_id: string;
  password: string;
}
const LoginPage: React.FC = () => {
  TabTitle('Welcome to NewAtom ELN');
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, accessToken, user } = useSelector(
    (state: RootState) => state.login
  );

  const handleLogin = (values: Payload) => {
    dispatch(login(values));
  };

  useEffect(() => {
    if (!loading && accessToken) {
      if (user?.is_first_login) {
        navigate('/change-password');
      } else {
        navigate('/dashboard');
      }
    }
  }, [accessToken, navigate, loading]);

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="w-full h-full bg-center bg-cover login-bg-filter md:w-4/5 lg:w-5/6"></div>
      <Flex
        className="absolute -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 right-1/2 md:right-0 left-1/2 md:left-auto md:translate-x-0 md:-translate-y-1/2 rounded-3xl md:rounded-s-[6rem] py-12 md:py-12 xl:py-16 px-8 md:px-12 lg:px-16 xl:px-20 w-[90vw] sm:w-[60vw] md:w-[40vw]"
        vertical
        gap={'2rem'}
      >
        {/* <Flex justify="start" align="center">
          <img
            alt="logo"
            src={logo}
            className="mx-auto mr-0 w-9 md:mx-0 md:w-18 gl:w-9 xl:w-10 md:mr-0 xl:mr-0"
          />
          <h1 className="mx-auto ml-3 text-2xl font-bold text-center xs:text-xs sm:text-xl lg:text-xl md:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-primary font-open-sans md:ml-3 xl:ml-3">
            New Atom ELN
          </h1>
        </Flex> */}
        <div className="flex items-center justify-center gap-2 px-2 md:justify-start">
          <img alt="logo" src={logo} className="w-[10%] sm:w-[12%]" />
          <img alt="logo full" src={logo_full} className="w-[25%] md:w-[40%]" />
        </div>
        <Form layout="vertical" form={form} onFinish={handleLogin}>
          <Flex vertical>
            <Col span={24}>
              <FormInput
                hideColon
                classes="!m-1 !border-none"
                label="Employee Code"
                name="employee_id"
                placeholder="Enter Employee Code"
                validateMsg="User Id is required"
                required
              />
            </Col>
            <Col span={24}>
              <FormInput
                hideColon
                classes="!m-1 !border-none"
                label="Password"
                name="password"
                placeholder="Enter Password"
                validateMsg="Password is required"
                type="password"
                required
              />
            </Col>
          </Flex>
          <Flex justify="flex-end" className="mt-1 mb-3">
            <Col>
              <Button
                onClick={() => navigate('/forget-password')}
                type="link"
                className="p-0 text-primary hover:!text-secondary-black font-open-sans"
              >
                Forgot password?
              </Button>
            </Col>
          </Flex>
          <Form.Item className="mt-2">
            <Flex flex={1}>
              <Button
                loading={loading}
                size="large"
                className="flex-1 text-sm font-bold text-white font-open-sans bg-primary lg:text-base"
                htmlType="submit"
              >
                Sign In
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </div>
  );
};

export default LoginPage;
