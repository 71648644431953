import { Divider, Flex, Spin, Row, Button } from 'antd';
import Card from 'common/Card';
import CountsCard from './components/CountsCard';
import users_tint from 'assets/icons/users_tint.svg';
import active_user_tint from 'assets/icons/active_user_tint.svg';
import masters_tint from 'assets/icons/masters_tint.svg';
import ActivityLog from './components/ActivityLog';
import { TabTitle } from 'utilities/helpers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getDashboardCounts } from '../redux/slice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export type Tbuttons = {
  id?: number;
  title: string;
  path: string;
};

export type TCards = {
  id?: number;
  description: string;
  title: string;
  count: number | string;
  countColor: string;
  icon: string;
};

const titleButtons = (obj: any): Tbuttons[] => {
  return [
    {
      id: 1,
      title: 'User Management',
      path: '/users',
    },
    {
      id: 2,
      title: 'Masters Management',
      path: '/masters',
    },
  ];
};

const getCards = (obj: any): TCards[] => {
  return [
    {
      id: 1,
      title: 'Total User',
      count: String(obj?.total_users).padStart(2, '0'),
      countColor: '#44B779',
      description:
        'This figure represents all users registered and active within the system, encompassing both new and existing users.',
      icon: users_tint,
    },
    {
      id: 2,
      title: 'Active Users',
      count: String(obj?.total_active_users).padStart(2, '0'),
      countColor: '#6BC7E4',
      description:
        'Engaged members currently utilizing the platform, reflecting ongoing interaction and participation.',
      icon: active_user_tint,
    },
    // {
    //   id: 3,
    //   title: 'Masters',
    //   count: '14',
    //   countColor: '#3885C2',
    //   description:
    //     'This section displays the total number of master records currently maintained within the system, including key entities and reference data.',
    //   icon: masters_tint,
    // },
  ];
};

export default function AdminDashboard() {
  TabTitle('Dashboard | NewAtom ELN');
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { dashboardCounts, dashboardCountsLoading } = useSelector(
    (state: RootState) => state.admin
  );

  useEffect(() => {
    dispatch(getDashboardCounts({}));
  }, []);

  return (
    <Flex vertical gap={30}>
      <Flex gap={30} vertical>
        <Row className="justify-between">
          <h2 className="text-[1.25rem] font-bold text-primary font-open-sans">
            Welcome back!
          </h2>
          <ul className="flex justify-end gap-4">
            {titleButtons({})?.map((but) => {
              return (
                <li key={but.id}>
                  <Button
                    onClick={() => {
                      navigate(but.path);
                    }}
                    className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-medium border border-primary-dark"
                  >
                    {but.title}
                  </Button>
                </li>
              );
            })}
          </ul>
        </Row>
        <Card>
          <Spin spinning={dashboardCountsLoading}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[1px] bg-tertiary-highlight-200">
              {getCards(dashboardCounts).map((card) => {
                return (
                  <CountsCard
                    key={card.id}
                    title={card.title}
                    count={card.count}
                    countColor={card.countColor}
                    description={card.description}
                    icon={card.icon}
                  />
                );
              })}
            </div>
          </Spin>
        </Card>
      </Flex>
    </Flex>
  );
}
