import { useCallback, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { useState } from 'react';
// import SearchBar from 'common/Content/searchBar';
// import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
// import { useNavigate } from 'react-router-dom';
import { MASTERS } from 'data/masters';
import MasterCard from './components/MasterCard';
import FolderLimitModal from 'features/Admin/folderLimit/FolderLimitModal';
import { getExperimentLimit } from 'features/Admin/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';

export default function Masters() {
  const [searchMaster, setSearchMaster] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const dispatch: AppDispatch = useDispatch();

  const { experimentLimit } = useSelector((state: RootState) => state.admin);

  console.log('>>experimentLimit::', experimentLimit);

  useEffect(() => {
    handleGetExperimentLimit();
  }, []);

  const handleGetExperimentLimit = (): void => {
    const payload = {};
    dispatch(getExperimentLimit(payload));
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchMaster(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  return (
    <Flex vertical className="h-full bg-white shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Masters
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {/*  <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search',
            }}
            filters={false}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
          <Button
            size="middle"
            icon={<PlusOutlined />}
            className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
            onClick={handleModalOpen}
          >
            Add New
          </Button> */}
        </div>
      </Flex>

      {/* list of masters */}
      <div className="grid grid-cols-1 gap-5 m-4 md:grid-cols-2 xl:grid-cols-3">
        {MASTERS.map((item) => {
          return (
            <MasterCard
              id={item.id}
              img={item.img}
              label={item.label}
              subLabel={item.subLabel}
              path={item.path}
              key={item.id}
              setIsModalOpen={setIsModalOpen}
            />
          );
        })}
      </div>
      <FolderLimitModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        experimentLimit={experimentLimit}
        handleGetExperimentLimit={handleGetExperimentLimit}
      />
    </Flex>
  );
}
