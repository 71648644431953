import React, { useEffect } from 'react';
import { Flex, Button, Spin, Space, Row, Col, Tooltip } from 'antd';
import Card from 'common/Card';
import Label from 'common/Label';
import CompoundList from './components/CompoundList';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getprojectDetail } from '../dashboard/redux/slice';
import { useNavigate, useParams } from 'react-router-dom';
import download_icon from 'assets/icons/download_icon_active.svg';
import FolderList from 'features/sectionHeadFlow/folders/components/FolderList';
import { PERMISSIONS } from 'utilities/roleRights';
import { getLoggedInUser } from 'utilities/helpers';
import { getFolderList } from 'features/sectionHeadFlow/redux/slice';
import { TabTitle } from 'utilities/helpers';
import {
  ArrowLeftOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';

interface payload {
  page: number;
  per_page: number;
  id: number;
}
interface payloadFolder {
  project_id: number;
}

export default function Projects() {
  TabTitle('Details-View | NewAtom ELN');
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const param = useParams();

  const { projectDetailData, getprojectDetailLoading, pagination } =
    useSelector((state: RootState) => state.project);

  const { folderList, folderListLoading } = useSelector(
    (state: RootState) => state.sectionHead
  );

  const transformedFolderList = folderList.length
    ? folderList.map((folder) => {
        return {
          folderName: `${folder.folder_route.route + folder.folder_number}`,
          id: folder.id,
          counts: folder.experiment_count,
        };
      })
    : [];

  useEffect(() => {
    const payload = {
      id: Number(param?.id),
    };
    dispatch(getprojectDetail(payload));
  }, [param.id]);

  useEffect(() => {
    const payload: payloadFolder = {
      project_id: Number(param?.id),
    };
    dispatch(getFolderList(payload));
  }, []);

  const compoundCodes = projectDetailData?.project_compounds?.map(
    (item: any) => {
      return {
        id: item.id,
        compoundCode: item.compound_code,
        // aliasCode: item.alias_code,
        quantityRequested: item.requested_quantity,
        purityRequested: item.requested_purity,
        key_specs: item.key_specs,
        uom_master_id: item.uom_master?.id,
        uom: item.uom_master?.uom_code,
      };
    }
  );

  type TFolder = {
    id: number;
    folderName: string;
  };

  const FOLDERS: TFolder[] = [
    { id: 1, folderName: 'GA005/A01' },
    { id: 2, folderName: 'GA005/A02' },
  ];

  const { role } = getLoggedInUser();

  const handleCreateCompoundClick = () => {
    if (!projectDetailData?.id) return;
    navigate(`/projects/create/compound/${projectDetailData?.id}`);
  };

  const handleCreateFolderClick = () => {
    if (!projectDetailData?.id) return;
    navigate(`/folders/create/folder/${projectDetailData?.id}`);
  };

  const handleEditClick = () => {
    if (!projectDetailData?.id) return;
    navigate('edit');
  };

  return (
    <Spin spinning={getprojectDetailLoading}>
      <Flex vertical gap={20}>
        <Card
          header={
            <>
              <span>Project #{projectDetailData?.project_code}</span>
              <Space>
                {PERMISSIONS.canEditProject(role) && (
                  <>
                    <Button
                      className="bg-transparent hidden h-7 md:block !p-0 hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                      icon={<EditOutlined />}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                    <Tooltip title={'Edit'} overlayClassName="custom-tooltip">
                      <Button
                        className="bg-transparent block h-7 md:hidden !p-0 hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                        icon={<EditOutlined />}
                        onClick={handleEditClick}
                      />
                    </Tooltip>
                  </>
                )}

                <Button
                  size="small"
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  className="font-open-sans h-7 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                  onClick={() => navigate('/dashboard')}
                >
                  Go back
                </Button>
              </Space>
            </>
          }
          headerClasses="px-6 text-sm flex items-center justify-between font-bold border border-tertiary-highlight-200 text-primary sm:text-base font-open-sans bg-tertiary-highlight-100"
        >
          <div className="flex flex-col gap-3 p-6">
            <Label
              description={projectDetailData?.proposal_code}
              label={'Proposal Code'}
            />
            <Label
              description={
                <span className="capitalize text-transform:">
                  {projectDetailData?.industry_type?.industry_type}
                </span>
              }
              label={'Industry type'}
            />
            <Label
              description={
                // <span className="text-transform: none">
                projectDetailData?.project_description
                // </span>
              }
              label={'Project Description'}
              containerClasses="flex flex-col items-start w-full gap-1 sm:flex-row sm:items-start sm:gap-6 justify-start"
            />
            <Label
              description={
                <span className="capitalize text-transform:">
                  {projectDetailData?.section_heads?.length > 0
                    ? projectDetailData?.section_heads
                        .map((user: any) => {
                          return user.section_head_user?.display_name;
                        })
                        .join(', ')
                    : ''}
                </span>
              }
              label={'Section Head'}
            />
            <Label
              description={
                projectDetailData?.documents?.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {projectDetailData?.documents?.map(
                      (item: any, index: number) => (
                        <div key={item.id}>
                          <Button
                            type="link"
                            // onClick={() => {
                            //   handleFetchDocument(item?.fileName);
                            // }}

                            className="border rounded-lg text-primary-dark border-tertiary-highlight-200 hover:opacity-75"
                          >
                            <a
                              href={item?.presignedUrl}
                              download={item?.fileName}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex items-center gap-2">
                                <span className="text-xs font-semibold font-open-sans">
                                  {item?.originalName}
                                </span>
                                <img src={download_icon} alt="download_icon" />
                              </div>
                            </a>
                          </Button>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  'NA'
                )
              }
              label={'Attachments'}
              containerClasses="flex flex-col items-start w-full gap-1 sm:flex-row sm:items-start sm:gap-6 justify-start"
            />
          </div>
        </Card>
        {PERMISSIONS.canReadCompound(role) && (
          <Card
            header={
              <>
                <span>Compounds</span>
                {PERMISSIONS.canCreateCompound(role ? role : '') && (
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    className="font-open-sans h-7 text-primary border border-primary hover:!text-white hover:!bg-primary rounded-md"
                    onClick={handleCreateCompoundClick}
                  >
                    Create Compound Code
                  </Button>
                )}
              </>
            }
            headerClasses="px-6 text-sm flex items-center justify-between font-bold border border-tertiary-highlight-200 text-primary sm:text-base font-open-sans bg-tertiary-highlight-100"
          >
            <div className="px-6 pb-6">
              <CompoundList
                role={role}
                compoundCodes={compoundCodes}
                id={projectDetailData.id}
              />
            </div>
          </Card>
        )}
        {PERMISSIONS.canReadFolders(role) && (
          <Card
            header={
              <>
                <span>Folders</span>
                {PERMISSIONS.canCreateFolder(role ? role : '') && (
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    className="font-open-sans h-7 text-primary border border-primary hover:!text-white hover:!bg-primary rounded-md"
                    onClick={handleCreateFolderClick}
                  >
                    Create Folder
                  </Button>
                )}
              </>
            }
            headerClasses="px-6 text-sm flex items-center justify-between font-bold border border-tertiary-highlight-200 text-primary sm:text-base font-open-sans bg-tertiary-highlight-100"
          >
            <Spin spinning={folderListLoading}>
              <div className="px-6 pb-6">
                <FolderList
                  role={role}
                  folders={transformedFolderList}
                  id={param?.id || projectDetailData?.id}
                />
              </div>
            </Spin>
          </Card>
        )}
      </Flex>
    </Spin>
  );
}
