import { useCallback, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { useState } from 'react';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ListView from './components/ListView';
import ClientModal from './components/ClientModal';
import { getClientsList } from 'features/Admin/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';

interface payload {
  page: number;
  perPage: number;
  search: string;
}

const PER_PAGE_ITEM = 15;

export default function Clients() {
  const navigate = useNavigate();
  const [searchClient, setSearchClient] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const dispatch: AppDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { pagination, clientsList, clientsListLoading, createClientLoading } =
    useSelector((state: RootState) => state.admin);

  useEffect(() => {
    handelGetClientsList();
  }, [searchClient, currentPage]);

  const handelGetClientsList = () => {
    const payload: payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchClient,
    };
    dispatch(getClientsList(payload));
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchClient(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Clients
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by name or email',
            }}
            filters={false}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
          <Button
            size="middle"
            icon={<PlusOutlined />}
            className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
            onClick={handleModalOpen}
          >
            Add Client
          </Button>
        </div>
      </Flex>
      <ListView
        searchClient={searchClient}
        clientsList={clientsList}
        handelGetClientsList={handelGetClientsList}
        clientsListLoading={clientsListLoading}
        pagination={pagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        PER_PAGE_ITEM={PER_PAGE_ITEM}
        addUserLoading={createClientLoading}
      />
      <ClientModal
        addUserLoading={createClientLoading}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        handelGetClientsList={handelGetClientsList}
      />
    </Flex>
  );
}
