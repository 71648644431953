import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  clientDetails,
  industryTypes,
  projectCode,
  projectCompoundCreate,
  projectCreate,
  removeProjectDoc,
  uomDetails,
} from './api';

interface ProjectState {
  createProjectLoading: boolean;
  createProjectCompoundLoading: boolean;
  industryTypesList: any[];
  industryTypesListLoading: boolean;
  clientDetailsList: any[];
  clientDetailsListLoading: boolean;
  uomList: any[];
  uomListLoading: boolean;
  projectCode: string;
  projectCodeLoading: boolean;
  removeProjectDocLoading: boolean;
}

const initialState: ProjectState = {
  createProjectLoading: false,
  createProjectCompoundLoading: false,
  industryTypesList: [],
  industryTypesListLoading: false,
  clientDetailsList: [],
  clientDetailsListLoading: false,
  uomList: [],
  uomListLoading: false,
  projectCode: '',
  projectCodeLoading: false,
  removeProjectDocLoading: false,
};

const actions = {
  CREATE_PROJECT: 'project/CREATE_PROJECT',
  REMOVE_PROJECT_DOCS: 'project/REMOVE_PROJECT_DOCS',
  CREATE_PROJECT_COMPOUND: 'project/CREATE_PROJECT_COMPOUND',
  INDUSTRY_TYPES_LIST: 'project/INDUSTRY_TYPES_LIST',
  CLIENT_DETAILS: 'project/CLIENT_DETAILS',
  UOM_DETAILS: 'project/UOM_DETAILS',
  PROJECT_CODE: 'project/PROJECT_CODE',
};

export const createProject = createAsyncThunk(
  actions.CREATE_PROJECT,
  async (payload: Record<string, any>) => {
    const response = await projectCreate(payload);
    return response;
  }
);

export const removeProjectDocs = createAsyncThunk(
  actions.REMOVE_PROJECT_DOCS,
  async (payload: Record<string, any>) => {
    const response = await removeProjectDoc(payload);
    return response;
  }
);

export const createProjectCompound = createAsyncThunk(
  actions.CREATE_PROJECT_COMPOUND,
  async (payload: Record<string, any>) => {
    const response = await projectCompoundCreate(payload);
    return response;
  }
);

export const getIndustryTypes = createAsyncThunk(
  actions.INDUSTRY_TYPES_LIST,
  async (payload: Record<string, any>) => {
    const response = await industryTypes(payload);
    return response;
  }
);

export const getClientDetailsList = createAsyncThunk(
  actions.CLIENT_DETAILS,
  async (payload: Record<string, any>) => {
    const response = await clientDetails(payload);
    return response;
  }
);

export const getUomList = createAsyncThunk(
  actions.UOM_DETAILS,
  async (payload: Record<string, any>) => {
    const response = await uomDetails(payload);
    return response;
  }
);

export const getProjectCode = createAsyncThunk(
  actions.PROJECT_CODE,
  async (payload: Record<string, any>) => {
    const response = await projectCode(payload);
    return response;
  }
);

export const projectSlice = createSlice({
  name: 'project2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create project
    builder
      .addCase(createProject.pending, (state) => {
        state.createProjectLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.createProjectLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createProject.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createProjectLoading = false;

        message.error(msg);
      });

    // remove project documents
    builder
      .addCase(removeProjectDocs.pending, (state) => {
        state.removeProjectDocLoading = true;
      })
      .addCase(removeProjectDocs.fulfilled, (state, action) => {
        state.removeProjectDocLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(removeProjectDocs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.removeProjectDocLoading = false;

        message.error(msg);
      });

    // create project compound
    builder
      .addCase(createProjectCompound.pending, (state) => {
        state.createProjectCompoundLoading = true;
      })
      .addCase(createProjectCompound.fulfilled, (state, action) => {
        state.createProjectCompoundLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createProjectCompound.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createProjectCompoundLoading = false;

        message.error(msg);
      });

    // get industry types list
    builder
      .addCase(getIndustryTypes.pending, (state) => {
        state.industryTypesListLoading = true;
      })
      .addCase(getIndustryTypes.fulfilled, (state, action) => {
        state.industryTypesListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.industryTypesList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getIndustryTypes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.industryTypesListLoading = false;

        message.error(msg);
      });

    // get client details list
    builder
      .addCase(getClientDetailsList.pending, (state) => {
        state.clientDetailsListLoading = true;
      })
      .addCase(getClientDetailsList.fulfilled, (state, action) => {
        state.clientDetailsListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.clientDetailsList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getClientDetailsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.clientDetailsListLoading = false;

        message.error(msg);
      });

    // get UOM list
    builder
      .addCase(getUomList.pending, (state) => {
        state.uomListLoading = true;
      })
      .addCase(getUomList.fulfilled, (state, action) => {
        state.uomListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.uomList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getUomList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.uomListLoading = false;

        message.error(msg);
      });

    // get project code
    builder
      .addCase(getProjectCode.pending, (state) => {
        state.projectCodeLoading = true;
      })
      .addCase(getProjectCode.fulfilled, (state, action) => {
        state.projectCodeLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.projectCode = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectCode.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.projectCodeLoading = false;

        message.error(msg);
      });
  },
});

export default projectSlice.reducer;
