import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { getLoggedInUser } from './utilities/helpers';
import Home from './features/home';
import {
  Login,
  ResetPassword,
  ForgotPassword,
  ChangePassword,
} from 'features/login';
import Dashboard from 'features/proposalLeadFlow/dashboard';
import Folders from 'features/sectionHeadFlow/folders';
import Reports from 'features/reports';
import Projects from 'features/proposalLeadFlow/projects';
import CreateProject from 'features/proposalLeadFlow/projects/pages/CreateProject';
import CreateCompound from 'features/proposalLeadFlow/projects/pages/CreateCompound';
import CreateFolder from 'features/sectionHeadFlow/folders/pages/CreateFolder';
// import Dashboard from 'features/sectionHeadFlow/components/dashboard';
import ProjectDetails from 'features/sectionHeadFlow/components/projectDetails';
import Experiments from 'features/sectionHeadFlow/experiments';
import CreateExperiment from 'features/sectionHeadFlow/experiments/pages/CreateExperiment';
import SmartSummaries from 'features/smartSummaries';
import {
  ADMIN,
  CHEMIST,
  PROPOSAL_LEAD,
  SECTION_HEAD,
  EXECUTIVE_ACCESS,
  TECHNICAL_LEAD,
} from 'utilities/roleRights';
import AdminDashboard from 'features/Admin/dashboard';
import Users from 'features/Admin/users';
import Masters from 'features/Admin/masters';
import Clients from 'features/Admin/clients';
import PreviewExperiment from 'features/sectionHeadFlow/experiments/pages/PreviewExperiment';
import UOM from 'features/Admin/uom';
import TypeOfTest from 'features/Admin/typeOfTest';
import FolderLimit from 'features/Admin/folderLimit';
import AuditTrail from 'features/reports/pages/AuditTrail';

type TRoutes = {
  index?: boolean;
  path?: string;
  allowedRoles: string[];
  element: JSX.Element;
};

interface LocationState {
  from?: {
    pathname?: string;
  };
}

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[];
}

const RoleBasedDashboard = () => {
  const { role } = getLoggedInUser();

  if (role === ADMIN) {
    return <AdminDashboard />;
    // return <Dashboard />;
  } else {
    return <Dashboard />;
  }
};

const ROUTES: TRoutes[] = [
  {
    index: true,
    allowedRoles: [
      PROPOSAL_LEAD,
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <RoleBasedDashboard />,
  },
  {
    path: 'dashboard',
    allowedRoles: [
      PROPOSAL_LEAD,
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <RoleBasedDashboard />,
  },
  {
    path: 'projects',
    allowedRoles: [PROPOSAL_LEAD, EXECUTIVE_ACCESS, ADMIN, TECHNICAL_LEAD],
    element: <CreateProject />,
  },
  {
    path: 'users',
    allowedRoles: [ADMIN],
    element: <Users />,
  },
  {
    path: 'masters',
    allowedRoles: [ADMIN],
    element: <Masters />,
  },
  {
    path: 'masters/clients',
    allowedRoles: [ADMIN],
    element: <Clients />,
  },
  {
    path: 'masters/uom',
    allowedRoles: [ADMIN],
    element: <UOM />,
  },
  {
    path: 'masters/type-of-test',
    allowedRoles: [ADMIN],
    element: <TypeOfTest />,
  },
  {
    path: 'masters/folder_limit',
    allowedRoles: [ADMIN],
    element: <FolderLimit />,
  },
  {
    path: 'projects/:id',
    allowedRoles: [
      PROPOSAL_LEAD,
      SECTION_HEAD,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <Projects />,
  },
  {
    path: 'projects/:id/edit',
    allowedRoles: [PROPOSAL_LEAD],
    element: <CreateProject />,
  },
  {
    path: 'projects/create/compound/:id',
    allowedRoles: [PROPOSAL_LEAD, EXECUTIVE_ACCESS, ADMIN, TECHNICAL_LEAD],
    element: <CreateCompound />,
  },
  {
    path: 'projects-details/:id',
    allowedRoles: [PROPOSAL_LEAD, EXECUTIVE_ACCESS, ADMIN, TECHNICAL_LEAD],
    element: <ProjectDetails />,
  },
  {
    path: 'reports',
    allowedRoles: [ADMIN],
    element: <Reports />,
  },
  {
    path: 'reports/audit_trail',
    allowedRoles: [ADMIN],
    element: <AuditTrail />,
  },
  {
    path: 'folders',
    allowedRoles: [
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <Folders />,
  },
  {
    path: 'folders/:id',
    allowedRoles: [
      PROPOSAL_LEAD,
      SECTION_HEAD,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
      CHEMIST,
    ],
    element: <Projects />,
  },
  {
    path: 'folders/create/folder/:id',
    allowedRoles: [SECTION_HEAD, CHEMIST, EXECUTIVE_ACCESS, TECHNICAL_LEAD],
    element: <CreateFolder />,
  },
  {
    path: '/folders/edit/folder/:id/:folder_id',
    allowedRoles: [SECTION_HEAD, CHEMIST, EXECUTIVE_ACCESS, TECHNICAL_LEAD],
    element: <CreateFolder />,
  },
  {
    path: 'experiments/:project_id/:folder_id',
    allowedRoles: [
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <Experiments />,
  },
  {
    path: 'experiments/:project_id/:folder_id/view/:experiment_id',
    allowedRoles: [
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <CreateExperiment />,
  },
  {
    path: 'experiments/:project_id/:folder_id/create',
    allowedRoles: [
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      ADMIN,
      TECHNICAL_LEAD,
    ],
    element: <CreateExperiment />,
  },
  {
    path: 'smart-summaries',
    allowedRoles: [EXECUTIVE_ACCESS],
    element: <SmartSummaries />,
  },
];

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  allowedRoles,
}) => {
  // Now inside a component that is rendered by a <Route>
  const location = useLocation() as { state: LocationState };

  //const { loading } = useSelector((state: State) => state.login);
  const { loggedIn, role } = getLoggedInUser();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/" state={{ replace: true }} />;
  }

  return <>{children}</>;
};

interface AuthCheckerProps {
  children: React.ReactNode;
}

const AuthChecker: React.FC<AuthCheckerProps> = ({ children }) => {
  // Now inside a component that is rendered by a <Route>
  const location = useLocation() as { state: LocationState };
  const { loggedIn } = getLoggedInUser();
  const goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : // : roleBasedNavigation(user?.user_details?.role || '');
      '';

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootRedirect />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="forget-password"
          element={
            <AuthChecker>
              <ForgotPassword />
            </AuthChecker>
          }
        />

        <Route
          path="reset-password"
          element={
            <AuthChecker>
              <ResetPassword />
            </AuthChecker>
          }
        />

        <Route path="change-password" element={<ChangePassword />} />
        <Route
          path="/"
          element={
            <PrivateRoute
              allowedRoles={[
                PROPOSAL_LEAD,
                SECTION_HEAD,
                CHEMIST,
                EXECUTIVE_ACCESS,
                ADMIN,
                TECHNICAL_LEAD,
              ]}
            >
              <Home />
            </PrivateRoute>
          }
        >
          {ROUTES.map((route, i) => {
            return (
              <Route
                key={i}
                index={Boolean(route.index)}
                path={route.path ? route.path : ''}
                element={
                  <PrivateRoute allowedRoles={route.allowedRoles}>
                    {route.element}
                  </PrivateRoute>
                }
              />
            );
          })}
        </Route>
        {/* <Route
          path="/experiment/preview/:project_id/:folder_id/:experiment_id"
          element={
            <PrivateRoute
              allowedRoles={[
                PROPOSAL_LEAD,
                SECTION_HEAD,
                CHEMIST,
                EXECUTIVE_ACCESS,
                ADMIN,
                TECHNICAL_LEAD,
              ]}
            >
              <PreviewExperiment />
            </PrivateRoute>
          }
        ></Route> */}
      </Routes>
    </Router>
  );
};

// Helper Component for Redirecting to the appropriate path
const RootRedirect: React.FC = () => {
  const user = getLoggedInUser();
  const location = useLocation() as { state: LocationState };

  const goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : // : user?.user_details?.role
      //   ? roleBasedNavigation(user?.user_details?.role || '')
      '/login';

  return <Navigate replace to={goTo} />;
};

export default App;
