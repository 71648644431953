import { useCallback, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { useState } from 'react';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getClientsList, getExperimentLimit } from 'features/Admin/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';

interface payload {
  page: number;
  perPage: number;
  search: string;
}

const PER_PAGE_ITEM = 10;

export default function FolderLimit() {
  const navigate = useNavigate();
  const [searchClient, setSearchClient] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const dispatch: AppDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    experimentLimit,
    experimentLimitLoading,
    updateExperimentLimitLoading,
  } = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    handelGetExperimentLimit();
  }, [searchClient, currentPage]);

  const handelGetExperimentLimit = () => {
    dispatch(getExperimentLimit({}));
  };

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-xl lg:text-2xl md text-primary-dark">
          Folder
        </h2>
      </Flex>
      <div>dsd</div>
    </Flex>
  );
}
