import React from 'react';
import { Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';

interface SearchBarProps {
  inputProps?: InputProps; // Use the correct InputProps type from Ant Design
  [key: string]: any; // For handling any additional props
}

const SearchBar: React.FC<SearchBarProps> = ({ inputProps, ...rest }) => {
  return (
    <Row {...rest}>
      {inputProps ? (
        <Col span={23}>
          <Input
            prefix={<SearchOutlined />}
            {...inputProps}
            className="hover:border hover:border-primary"
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default SearchBar;
