import {
  Col,
  Divider,
  Row,
  Form,
  Select,
  Button,
  FormInstance,
  Tag,
} from 'antd';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
// import KetcherDemo from './demo123/KetcherDemo';
import ExperimentsKetcher from '../chemStrucKetcher';
import { useEffect, useState } from 'react';
import ViewKetcher from '../chemStrucKetcher/ViewKetcher';
import { getLoggedInUser } from 'utilities/helpers';
import {
  createExperiment,
  deleteRefFile,
  getExperimentsDetails,
} from '../redux/slice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import moment from 'moment';
import { getFolderDetails } from 'features/sectionHeadFlow/redux/slice';
import { Upload } from 'common/fileUploader';
import './styles.css';
import download_icon from 'assets/icons/download_icon_active.svg';
import delete_icon from 'assets/icons/delete.svg';
import SectionHeader from 'common/SectionHeader';
import RemarksModal from 'common/RemarksModal';
import { useSelector } from 'react-redux';

type TSteps = {
  label: string | number;
  value: string | number;
};

type StepNew = {
  id: number;
  project_id: number;
  folder_id: number;
  reaction_scheme: string;
  description: string;
  smiles_string: string;
  sequence: number;
  is_active: boolean;
};

type TProps = {
  children?: string | JSX.Element | undefined;
  steps: TSteps[];
  isCreate: boolean;
  isView: boolean;
  stepData: any;
  folderDetails: any;
  setStepsNew: React.Dispatch<React.SetStateAction<StepNew[]>>;
  stepsNew: StepNew[];
  selectedStep: number;
  setSelectedStep: React.Dispatch<React.SetStateAction<number>>;
  tabClicked: boolean;
  setTabClicked: React.Dispatch<React.SetStateAction<boolean>>;
  handleSmilesChange: (newSmiles: string) => void;
  handleStepChange: (stepSequence: number) => void;
  experimentId: string | null;
  experimentDetailsData: any;
  form: FormInstance<any>;
  projectId: string | number;
  folderId: string | undefined;
  createExperimentLoading: boolean;
  setExperimentDetailsData: React.Dispatch<any>;
  experimentStatus?: string;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  experimentDetails: any;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ExperimentDetails({
  children,
  steps,
  isCreate,
  isView,
  isEditing,
  setIsEditing,
  stepData,
  folderDetails,
  setStepsNew,
  stepsNew,
  selectedStep,
  setSelectedStep,
  tabClicked,
  setTabClicked,
  handleSmilesChange,
  handleStepChange,
  form,
  experimentId,
  experimentDetailsData,
  folderId,
  projectId,
  createExperimentLoading,
  setExperimentDetailsData,
  experimentStatus,
  files,
  setFiles,
  experimentDetails,
}: TProps) {
  // const [isEditing, setIsEditing] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { role } = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { isExternalChemist } = useSelector(
    (state: RootState) => state.experiments
  );

  //remove locally uploaded files in literature reference
  const handleRemoveFile = (index: number | string) => {
    const allFiles = [...files];
    const filteredFiles = allFiles.filter((file, i) => file.name !== index);
    setFiles(filteredFiles);
  };

  //remove uploaded files from BE
  const handleDeleteFile = (id: number) => {
    const payload = {
      id,
      experiment_id: experimentId,
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(deleteRefFile(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const newBunch = [...uploadedFiles];
        const filteredFiles = newBunch.filter((file: any) => file.id !== id);
        setUploadedFiles(filteredFiles);
      }
    });
  };

  const finalSubmit = (values: any) => {
    const data = { ...experimentDetails, ...folderDetails };
    const payload = {
      project_id: projectId,
      experiment_id: experimentId,
      folder_id: folderId,
      references: values.references,
      objective_of_reaction: values.objective_of_reaction,
      is_product_formed: data?.is_product_formed,
      product_name: data.product_name,
      remarks: data.remarks,
      status: experimentStatus as string,
      //are not form entities
      step_number: data?.step_number, // `step-${selectedStep + 1}`,
      folder_route_scheme_id: data?.folder_route_scheme_id,
      // reaction_scheme: stepsNew?.filter(
      //   (item) => item.sequence === selectedStep
      // )[0]?.smiles_string,
      reaction_scheme: isView
        ? stepsNew[0]?.smiles_string
        : stepsNew?.filter((item) => item.sequence === selectedStep)[0]
            ?.smiles_string,

      is_edit: isView && isExternalChemist ? false : isView ? true : false,
      change_remarks: values.remark,
      section: 'experiment_details',
      experiment_category: values.experiment_category,
      re_submit : false,
      experiment_precautions: data?.experiment_precautions
    };
    let formdata = new FormData();

    formdata.append('project_id', payload.project_id as string);
    formdata.append('experiment_id', payload.experiment_id as string);
    formdata.append('re_submit', "false");
    formdata.append('folder_id', payload.folder_id as string);
    formdata.append('is_edit', payload.is_edit ? 'true' : 'false');
    formdata.append('references', payload.references);
    formdata.append('step_number', payload.step_number);
    formdata.append(
      'folder_route_scheme_id',
      String(payload.folder_route_scheme_id)
    );
    formdata.append('objective_of_reaction', payload.objective_of_reaction);
    formdata.append(
      'is_product_formed',
      payload.is_product_formed ? 'true' : 'false'
    );
    formdata.append('remarks', payload.remarks);
    formdata.append('reaction_scheme', payload.reaction_scheme);
    formdata.append('status', payload.status);
    formdata.append('change_remarks', payload.change_remarks);
    formdata.append('section', payload.section);
    formdata.append('experiment_category', payload.experiment_category);
    formdata.append('experiment_precautions', payload.experiment_precautions);

    files.forEach((file) => {
      formdata.append('attachments', file);
    });

    dispatch(createExperiment(formdata)).then((res: any) => {
      setIsRemarksModalOpen(false);
      if (res?.payload?.success) {
        setIsEditing(false);
        setFiles([]);
        setSelectedStep(0);
        dispatch(getExperimentsDetails({ id: experimentId })).then(
          (res: any) => {
            if (res?.payload.success) {
              setExperimentDetailsData(res?.payload?.data);
              dispatch(getFolderDetails({ id: folderId }));
            }
          }
        );
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isRemarksModalOpen) {
          finalSubmit({ remark, ...values });
        } else {
          setIsRemarksModalOpen(true);
        }
      })
      .catch(() => {});
  };

  //updating comments section
  const handleSave = () => {
    if (isEditing) {
      form
        .validateFields()
        .then((values: any) => {
          if (isRemarksModalOpen) return;
          setIsRemarksModalOpen(true);
        })
        .catch(() => {});
    } else {
      setIsEditing(true);
    }
  };

  //storing referenced files in separeate state to manipulate it
  useEffect(() => {
    if (experimentDetails?.experiment_references?.length) {
      setUploadedFiles(experimentDetails?.experiment_references);
    }
  }, [experimentDetails]);

  const selectedStepData =
    isView && isEditing
      ? true
      : stepsNew?.find((step) => step.sequence === selectedStep); //Number(experimentDetailsData?.step_number?.split('-')?.at(1));

  return (
    <Card
      header={
        <SectionHeader
          isEditing={isEditing}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Experiment details"
          saveLoading={createExperimentLoading}
          experimentId={experimentId}
        />
      }
    >
      <div className="flex flex-col gap-3 p-6">
        <Row gutter={10}>
          <Col
            span={24}
            sm={{ span: 8 }}
            md={{ span: 5 }}
            lg={{ span: 4 }}
            xxl={{ span: 5 }}
          >
            <FormInput
              name="project_code"
              label={'Project Code'}
              defaultStyle
              placeholder="GA004"
              disabled
            />
          </Col>
          <Col
            span={24}
            sm={{ span: 8 }}
            md={{ span: 5 }}
            lg={{ span: 4 }}
            xxl={{ span: 5 }}
          >
            <FormInput
              name="folder_id"
              label={'Folder ID'}
              defaultStyle
              placeholder="A01"
              disabled
            />
          </Col>
          <Col
            span={24}
            sm={{ span: 8 }}
            md={{ span: 5 }}
            lg={{ span: 4 }}
            xxl={{ span: 5 }}
          >
            <FormInput
              name="experiment_id"
              label={'Experiment ID'}
              defaultStyle
              placeholder="Generated automatically on experiment creation"
              disabled
            />
          </Col>
          <Col
            span={24}
            sm={{ span: 8 }}
            md={{ span: 9 }}
            lg={{ span: 7 }}
            xxl={{ span: 5 }}
          >
            <FormInput
              showTime
              disabled
              name="date_time"
              label={
                <span className="!text-2xs md:!text-xs xl:!text-sm">
                  Experiment Start Date & Time
                </span>
              }
              placeholder={String(
                moment(Date.now()).format('DD/MM/YYYY hh:mm:ss a')
              )}
              type="date"
              defaultStyle
              inputClasses="p-select font-open-sans shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
            />
          </Col>
          <Col
            span={24}
            sm={{ span: 8 }}
            md={{ span: 8 }}
            lg={{ span: 5 }}
            xxl={{ span: 4 }}
          >
            <FormInput
              name="experiment_category"
              label={
                <span className="!text-2xs md:!text-xs xl:!text-sm">
                  Experiment Category
                </span>
              }
              placeholder={'Select experiment category'}
              type="select"
              defaultStyle
              required
              validateMsg="Experiment category is required"
              disabled={isView && !isEditing}
              options={[
                { label: 'Feasibility', value: 'feasibility' },
                { label: 'Optimization', value: 'optimization' },
                { label: 'Validation', value: 'validation' },
              ]}
              selectInputClasses={`rounded-md font-open-sans shadow-none border selected-item-size disabled:!text-secondary-black focus-within:border-primary-dark !text-xs lg:!text-sm border-tertiary-highlight-200 ${isView && !isEditing && '!bg-[#d9d9d948]'}`}
            />
          </Col>
          <Col span={24}>
            <Row gutter={10}>
              <Col span={24} md={{ span: 20 }}>
                <FormInput
                  disabled={isView && !isEditing}
                  name="references"
                  label={'Literature References'}
                  placeholder="Literature References"
                  defaultStyle
                />
              </Col>
              <Col span={24} md={{ span: 4 }}>
                <FormInput
                  label={'Upload Files'}
                  name="placeholder"
                  type="placeholder"
                  defaultStyle
                  placeholderComponent={
                    <Upload
                      hideFilename
                      dragUpload={false}
                      type="horizontal"
                      files={files}
                      setFiles={setFiles}
                      limit={10}
                      disabled={isView && !isEditing}
                    />
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={10} className="flex flex-1 !m-0 gap-2">
          {uploadedFiles.length > 0 &&
            uploadedFiles?.map((item: any, index: number) => (
              <div key={item.id}>
                <Button
                  type="link"
                  className="border rounded-lg text-primary-dark border-tertiary-highlight-200 hover:opacity-75"
                  iconPosition="end"
                  icon={
                    isView &&
                    isEditing && (
                      <img
                        src={delete_icon}
                        alt="delete icon"
                        onClick={(e) => handleDeleteFile(item.id)}
                      />
                    )
                  }
                >
                  <a
                    href={item?.objectKey}
                    download={item?.fileName}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-semibold font-open-sans">
                        {item?.originalName}
                      </span>
                      <img src={download_icon} alt="download_icon" />
                    </div>
                  </a>
                </Button>
              </div>
            ))}
        </Row>
        <Row gutter={10} className="flex flex-1 !m-0 gap-2">
          {files.map((file) => {
            return (
              <Tag
                key={file.name}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleRemoveFile(file.name);
                }}
              >
                {file?.name}
              </Tag>
            );
          })}
        </Row>
        <Divider className="mt-0 mb-2" />
        <Row gutter={10}>
          <Col span={24} sm={{ span: 18 }}>
            <FormInput
              label={'Objective of the Reaction'}
              name="objective_of_reaction"
              placeholder="Title of the Reaction"
              defaultStyle
              required
              validateMsg="Objective of the Reaction is required"
              disabled={isView && !isEditing}
            />
          </Col>
          <Col span={24} sm={{ span: 6 }}>
            {isView /* && !isEditing */ && (
              <FormInput
                label={'Step Number'}
                name="step_no"
                placeholder="Step Number"
                defaultStyle
                required
                disabled
                validateMsg="Step number is required"
              />
            )}
            {isCreate /* || (isView && isEditing) */ && (
              <Form.Item
                label={
                  <span className="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans">
                    Step Number
                  </span>
                }
                name="stepDropdown border !border-radius-[4px]"
                rules={[{ required: true, message: 'Step number is required' }]}
              >
                <Select
                  className="stepNum_style"
                  disabled={isView && !isEditing}
                  size="large"
                  id="stepDropdown"
                  value={selectedStep}
                  onChange={(value) => handleStepChange(value)} // Directly pass the selected value
                  defaultValue={selectedStep}
                >
                  {stepsNew?.map((step) => (
                    <Select.Option key={step.id} value={step.sequence}>
                      Step {step.sequence + 1}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {(isCreate || (isView && isEditing)) && selectedStepData && (
              <ExperimentsKetcher
                stepData={selectedStepData}
                folderDetails={{}} // Provide folder details if necessary
                onSmilesChange={handleSmilesChange}
                tabClicked={tabClicked}
                setTabClicked={setTabClicked}
                experimentDetailsData={experimentDetailsData}
                isEditing={isEditing}
                isView={isView}
              />
            )}
            {isView && !isEditing && (
              <ViewKetcher experimentDetailsData={experimentDetailsData} />
            )}
          </Col>
        </Row>
        {!children ? '' : children}

        {/*change remarks modal */}
        <RemarksModal
          isOpen={isRemarksModalOpen}
          onApprove={(value) => handleSubmit(value)}
          loading={createExperimentLoading}
          onClose={() => setIsRemarksModalOpen(false)}
        />
      </div>
    </Card>
  );
}
