import { CloseOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Radio, Select, Space } from 'antd';
import { Rule } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import './FormInput.css';

type TRadioOptions = {
  label: string | number;
  value: string | number;
  disabled?: boolean;
  classNames?: string;
  subOptionsName?: string;
  subOptions?: TRadioOptions;
};

type TMargin = 'default' | 'small' | 'medium' | 'large' | 'extraLarge' | 'none';

type TType =
  | 'text'
  | 'password'
  | 'number'
  | 'radio'
  | 'select'
  | 'textarea'
  | 'date'
  | 'placeholder'
  | 'range';

type TFormInputProps = {
  marginBottom?: TMargin;
  name: string;
  label?: string | JSX.Element;
  rules?: Rule[];
  placeholder?: string;
  type?: TType;
  hideColon?: boolean;
  required?: boolean;
  inputClasses?: string;
  validateMsg?: string | JSX.Element;
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
  options?: TRadioOptions[] | any[];
  defaultStyle?: boolean;
  disabled?: boolean;
  rows?: number;
  multiple?: boolean;
  showTime?: boolean;
  onSearch?: (val: string) => void;
  optionRender?: (option: any, info: { index: number }) => JSX.Element;
  removeIcon?: string | JSX.Element | boolean;
  selectedRadioId?: string | number;
  selectInputClasses?: string;
  inputMaxLength?: number;
  placeholderComponent?: string | JSX.Element;
  tooltipTitle?: string;
  popupClassName?: string;
  wrapperClasses?: string;
  loading?: boolean;
  noStyle?: boolean;
  defaultValue?: string | number;
  max?: number;
  min?: number;
  suffixIcon?: string | JSX.Element | null;
  notFoundContent?: string | JSX.Element | null;
  defaultActiveFirstOption?: boolean;
  allowClear?: boolean;
  selectplaceholderClasses?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLSpanElement>;
  selectInputSize?: 'small' | 'middle' | 'large'
  classes?:string
};

export default function FormInput({
  name,
  label,
  marginBottom = 'large',
  rules,
  validateMsg,
  placeholder = '',
  type = 'text',
  hideColon = false,
  required = false,
  inputClasses,
  prefix = '',
  suffix = '',
  options,
  defaultStyle = false,
  disabled = false,
  rows = 3,
  multiple = false,
  showTime = false,
  onSearch,
  optionRender,
  removeIcon = <CloseOutlined />,
  selectedRadioId,
  selectInputClasses,
  inputMaxLength,
  placeholderComponent = '',
  tooltipTitle = '',
  popupClassName = '',
  wrapperClasses = '',
  loading = false,
  noStyle = false,
  defaultValue,
  min,
  max,
  suffixIcon,
  notFoundContent,
  defaultActiveFirstOption = true,
  allowClear = false,
  selectplaceholderClasses,
  onKeyDown,
  selectInputSize = 'large',
  classes
}: TFormInputProps) {
  const renderInput = (): JSX.Element | string => {
    const commonClassNames = inputClasses
      ? `${inputClasses} font-open-sans shadow-none disabled:!text-secondary-black`
      : 'p-input font-open-sans shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200';
    switch (type) {
      case 'password':
        return (
          <Input.Password
            title={tooltipTitle}
            disabled={disabled}
            placeholder={placeholder}
            className={commonClassNames}
            prefix={prefix}
            onKeyDown={onKeyDown}
          />
        );
      case 'radio':
        return (
          <Radio.Group disabled={disabled} className={commonClassNames}>
            {options?.map((option) => {
              return (
                <Radio
                  className={
                    selectedRadioId === option.value
                      ? `bg-secondary-filter ${option.classNames}`
                      : option.classNames
                  }
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  <Space>
                    {option.label}
                    {selectedRadioId === option.value &&
                      option.subOptions?.length && (
                        <Form.Item
                          style={{ margin: 0, padding: 0 }}
                          name={option.subOptionsName}
                        >
                          <Radio.Group
                            disabled={disabled}
                            className="px-1 bg-white border rounded-full border-tertiary-highlight-200 font-open-sans"
                            size="small"
                          >
                            <Space direction="horizontal">
                              {option.subOptions?.map((sub: any) => {
                                return (
                                  <Radio
                                    key={sub.value}
                                    value={sub.value}
                                    disabled={sub.disabled}
                                    className="font-open-sans"
                                    style={{ margin: 0, padding: 0 }}
                                  >
                                    {sub.label}
                                  </Radio>
                                );
                              })}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      )}
                  </Space>
                </Radio>
              );
            })}
          </Radio.Group>
        );
      case 'select':
        return (
          <Select
            popupClassName={popupClassName}
            title={tooltipTitle}
            onKeyDown={onKeyDown}
            popupMatchSelectWidth={false}
            className={
              selectInputClasses
                ? `${selectInputClasses} font-open-sans selected-item-size shadow-none disabled:!text-secondary-black`
                : 'rounded-md font-open-sans shadow-none border selected-item-size disabled:!text-secondary-black focus-within:border-primary-dark !text-xs lg:!text-sm hover:border-tertiary-highlight-200'
            }
            removeIcon={removeIcon}
            showSearch
            loading={loading}
            disabled={disabled}
            onSearch={onSearch}
            defaultActiveFirstOption={defaultActiveFirstOption}
            placeholder={
              <span
                className={selectplaceholderClasses || 'text-sm font-open-sans'}
              >
                {placeholder}
              </span>
            }
            filterOption={(input, option) =>
              (String(option?.label) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            defaultValue={defaultValue}
            suffixIcon={suffixIcon}
            notFoundContent={notFoundContent}
            options={options}
            optionRender={optionRender}
            mode={multiple ? 'multiple' : undefined}
            size={selectInputSize}
            variant="borderless"
          />
        );
      case 'textarea':
        return (
          <TextArea
            onKeyDown={onKeyDown}
            title={tooltipTitle}
            rows={rows}
            placeholder={placeholder}
            disabled={disabled}
            className={commonClassNames}
            style={{ resize: 'none' }}
          />
        );
      case 'date':
        return (
          <DatePicker
            title={tooltipTitle}
            showTime={showTime}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder}
            className={commonClassNames}
            format={showTime ? 'DD/MM/YYYY hh:mm:ss a' : 'DD/MM/YYYY'}
          />
        );
      case 'placeholder':
        return placeholderComponent;
      default:
        return (
          <Input
            onKeyDown={onKeyDown}
            allowClear={allowClear}
            title={tooltipTitle}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            className={commonClassNames}
            prefix={prefix}
            suffix={suffix}
            maxLength={inputMaxLength}
            defaultValue={defaultValue}
            max={max}
            min={min}
          />
        );
    }
  };

  return (
    <Form.Item
      label={
        defaultStyle ? (
          <label className="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans">
            {label}
          </label>
        ) : label ? (
          <label className={'font-open-sans text-xs lg:text-sm'}>{label}</label>
        ) : (
          ''
        )
      }
      colon={false}
      name={name}
      rules={
        rules?.length ? rules : [{ required: required, message: validateMsg }]
      }
      className={
        hideColon
          ? `hide-required-mark ${classes}`
          : `border-none ${marginBottom === 'small' ? 'mb-1' : marginBottom === 'medium' ? 'mb-2' : marginBottom === 'large' ? 'mb-3' : marginBottom === 'extraLarge' ? 'mb-4' : marginBottom === 'none' ? 'mb-0' : 'mb-0'}`
      }
      noStyle={noStyle}
    >
      {wrapperClasses ? (
        <Form.Item name={name} className={wrapperClasses}>
          {renderInput()}
        </Form.Item>
      ) : (
        renderInput()
      )}
    </Form.Item>
  );
}
