export default function Spacer({
  children,
  xs = false,
  sm = false,
  md = false,
  lg = false,
  xl = false,
}: {
  children: string | JSX.Element;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div
        className={
          xs
            ? 'hidden'
            : sm
              ? 'hidden'
              : md
                ? 'hidden'
                : lg
                  ? 'hidden'
                  : xl
                    ? 'hidden'
                    : 'invisible block'
        }
      >
        invisible
      </div>
      {children}
    </div>
  );
}
