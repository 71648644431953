import React, { useEffect, useRef, useState } from 'react';
import 'miew/dist/miew.min.css';
import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';
import { Ketcher } from 'ketcher-core';
import 'ketcher-react/dist/index.css';
import Miew from 'miew';
import styled from '@emotion/styled';

(window as any).Miew = Miew;

const CenteredContainer = styled.div`
  margin-top: 7px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  max-height: 100vh;
  max-width: 100vw;
`;

const GridWrapper = styled.div`
  height: 55vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  grid-template-columns: 1fr 270px 320px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'Ketcher Panel Output';

  & > div {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
`;

const structServiceProvider = new StandaloneStructServiceProvider();

interface KetcherDemoProps {
  onSmilesChange: (smiles: string) => void;
  stepData: any;
  folderDetails: any;
  tabClicked: boolean;
  setTabClicked: (value: boolean) => void;
  experimentDetailsData: any;
  isEditing: boolean;
  isView: boolean;
}

const ExperimentsKetcher: React.FC<KetcherDemoProps> = ({
  onSmilesChange,
  stepData,
  folderDetails,
  tabClicked,
  setTabClicked,
  experimentDetailsData,
  isEditing,
  isView,
}) => {
  const ketcherRef = useRef<Ketcher | null>(null);
  const [lastSmiles, setLastSmiles] = useState<string>('');
  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);

  const moleculeToLoad = folderDetails?.folder_route_schemes?.length
    ? folderDetails.folder_route_schemes[0].smiles_string
    : null;

  const handleOnInit = (ketcher: Ketcher) => {
    ketcherRef.current = ketcher;
    (window as any).ketcher = ketcher;
    setEditorInitialized(true); // Editor is ready
  };

  useEffect(() => {
    const fetchSmiles = async () => {
      if (ketcherRef.current) {
        const smiles = await ketcherRef.current.getSmiles();
        if (smiles !== lastSmiles) {
          setLastSmiles(smiles);
          onSmilesChange(smiles); // Notify parent of the SMILES change
        }
      }
    };

    const interval = setInterval(fetchSmiles, 1000); // Poll every second
    return () => clearInterval(interval); // Cleanup
  }, [lastSmiles, onSmilesChange]);

  useEffect(() => {
    setTabClicked(false);
    // Only set the molecule if the editor is initialized

    if (isView && isEditing) {
      if (experimentDetailsData && ketcherRef.current) {
        ketcherRef.current.setMolecule(experimentDetailsData?.reaction_scheme);
      }
    } else {
      if (editorInitialized && ketcherRef.current) {
        // Clear existing molecule first
        ketcherRef.current.setMolecule('');
        setTimeout(() => {
          if (stepData?.smiles_string) {
            ketcherRef?.current?.setMolecule(stepData.smiles_string); // Load the molecule
          } else if (moleculeToLoad) {
            ketcherRef?.current?.setMolecule(moleculeToLoad); // Load the molecule
          }
        }, 500); // 500ms delay to allow Ketcher to fully initialize
      }
    }
  }, [experimentDetailsData, editorInitialized, tabClicked]); //[stepData, editorInitialized, moleculeToLoad]);

  return (
    <CenteredContainer>
      <GridWrapper onClick={(e) => e.preventDefault()}>
        <Editor
          errorHandler={(message: string) => null}
          staticResourcesUrl={''}
          structServiceProvider={structServiceProvider}
          onInit={handleOnInit}
        />
      </GridWrapper>
    </CenteredContainer>
  );
};

export default ExperimentsKetcher;
