import clientsImg from 'assets/images/masters/clients.png';
import uomImg from 'assets/images/masters/uom.png';
import typeOfTestImg from 'assets/images/masters/type_of_test.png';
import crossHandsImg from 'assets/images/masters/cross_hands.png';

export type TCentralLogs = {
  id: number;
  label: string;
  subLabel: string;
  img: string;
  tableName: string;
};

export const CENTRAL_LOGS: TCentralLogs[] = [
  // {
  //   id: 1,
  //   label: 'Experiment',
  //   subLabel:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
  //   img: clientsImg,
  //   tableName: 'experiment',
  // },
  {
    id: 1,
    label: 'Users',
    subLabel:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    img: clientsImg,
    tableName: 'users',
  },
  {
    id: 2,
    label: 'Projects',
    subLabel:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    img: clientsImg,
    tableName: 'projects',
  },
  {
    id: 3,
    label: 'Folders',
    subLabel:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    img: clientsImg,
    tableName: 'folders',
  },
  {
    id: 4,
    label: 'Clients',
    subLabel:
      'The Client refers to the official individual or organization we are working with for this project or service',
    img: clientsImg,
    tableName: 'clients',
  },
  {
    id: 5,
    label: 'UOM',
    subLabel:
      'UoM specifies the standard unit used to quantify the products or services involved in the transaction or project.',
    img: uomImg,
    tableName: 'uom_masters',
  },
  {
    id: 6,
    label: 'Type of Test',
    subLabel:
      '"Type of Test" defines the specific category or method of testing applied to evaluate the performance, quality, or functionality.',
    img: typeOfTestImg,
    tableName: 'type_of_tests',
  },
  {
    id: 7,
    label: 'Experiment Limits',
    subLabel:
      '"Experiment Limit Per Folder" refers to the maximum number of experiments that can be stored or managed within a single folder.',
    img: crossHandsImg,
    tableName: 'experiment_limits',
  },
];
