import { Form, Space, Spin, Tooltip } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import deleteIcon from 'assets/icons/delete.svg';
import { useDispatch } from 'react-redux';
import { addProduct, getProductsList } from '../redux/slice';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { convertTextWithChemicalFormulas } from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';
import editIcon from 'assets/icons/edit.svg';
import Table from 'common/Table';
import FormInput from 'common/FormInput';
import { useSelector } from 'react-redux';

type TProps = {
  datasource: any;
  projectId: string | number;
  experimentId: string | null;
  isCreate: boolean;
  isView: boolean;
  isEditing: boolean;
  loading: boolean;
  isExternalChemist: boolean;
};

export default function CommentsList({
  datasource,
  projectId,
  experimentId,
  isCreate,
  isView,
  isEditing,
  loading,
  isExternalChemist,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const [idToRemove, setIdToRemove] = useState<number | null>(null);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [idToEdit, setIdToEdit] = useState<number | null>(null);
  const [form] = Form.useForm();
  const { uomList } = useSelector((state: RootState) => state.project2);

  //rendering uom list
  const renderedUOMList = uomList?.map((uom: any) => {
    return { label: uom.uom_code, value: uom.id };
  });

  const handleDeleteProduct = (id: number | null, remark: string) => {
    const payload = {
      id,
      is_active: false,
      change_remarks: remark,
      section: 'comments',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    dispatch(addProduct(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToRemove(null);
        dispatch(
          getProductsList({
            project_id: projectId,
            experiment_id: experimentId,
          })
        );
      } else {
        setIdToRemove(null);
      }
    });
  };

  //handle update product row
  const handleUpdateProductRow = (product: any, values: any) => {
    setIsRemarksModalOpen(false);
    const payload = {
      ...values,
      id: product?.id,
      uom_id: values.uom_master_id,
      experiment_id: experimentId,
      project_id: projectId,
      change_remarks: values.remark,
      product_formed: true,
      section: 'comments',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    dispatch(addProduct(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToEdit(null);
        dispatch(
          getProductsList({
            project_id: projectId,
            experiment_id: experimentId,
          })
        );
      } else {
        setIdToEdit(null);
      }
    });
  };

  const handleEditSubmit = (product: any, remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate) {
          handleUpdateProductRow(product, {
            remark,
            ...values,
          });
        } else {
          if (isRemarksModalOpen) {
            handleUpdateProductRow(product, {
              remark,
              ...values,
            });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  //handle click on edit btn in row
  const handleEditClick = (id: number) => {
    setIdToEdit(id);
    const selectedRow: any = datasource.find(
      (material: any) => material.id === id
    );
    form.setFieldsValue(selectedRow);
    form.setFieldsValue({ uom_master_id: selectedRow?.uom_master?.id });
    form.setFieldsValue({
      mol_formula: convertTextWithChemicalFormulas(selectedRow?.mol_formula),
    });
  };

  const columns = [
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Product Name
        </label>
      ),
      dataIndex: 'product_name',
      key: 'product_name',
      width: '10%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="product_name" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Product Formed
        </label>
      ),
      dataIndex: 'productFormed',
      key: 'product_formed',
      width: '8%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            Yes
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Quantity
        </label>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '8%',
      render: (text: string, record: any) => {
        const concatinatedStr = !text
          ? 'NA'
          : !record.uom_master
            ? text + ' ' + 'NA'
            : parseFloat(text).toFixed(2) + ' ' + record.uom_master?.uom_code;
        return idToEdit === record?.id ? (
          <FormInput noStyle name="quantity" type="number" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {concatinatedStr}
            </p>
          </Tooltip>
        );
      },
      //renderColumn,
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          UOM
        </label>
      ),
      dataIndex: 'uom',
      key: 'uom',
      width: '6%',
      hidden: !idToEdit,
      render: (text: string, record: any) =>
        idToEdit === record.id ? (
          <FormInput
            type="select"
            noStyle
            options={renderedUOMList}
            popupClassName="select-dropdown"
            name="uom_master_id"
            selectInputClasses="selected-item-transform bg-white border border-tertiary-highlight-200 rounded-md placeholder:text-primary select-placeholder"
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {
                renderedUOMList.find(
                  (uom: any) => uom.value === record?.uom_master?.id
                )?.label
              }
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Purity %
        </label>
      ),
      dataIndex: 'purity',
      key: 'purity',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="purity" type="number" />
        ) : (
          <Tooltip overlayClassName="custom-tooltip" title={text}>
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          MW
        </label>
      ),
      dataIndex: 'mol_weight',
      key: 'mol_weight',
      width: '6%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="mol_weight" type="number" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Mol. Formula
        </label>
      ),
      dataIndex: 'mol_formula',
      key: 'mol_formula',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="mol_formula" />
        ) : (
          <Tooltip
            title={convertTextWithChemicalFormulas(text)}
            overlayClassName="custom-tooltip"
          >
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {convertTextWithChemicalFormulas(text)}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Actual Yield %
        </label>
      ),
      dataIndex: 'actual_yield',
      key: 'actual_yield',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="actual_yield" type="number" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Th.Yield %
        </label>
      ),
      dataIndex: 'th_yield',
      key: 'th_yield',
      width: '7%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="th_yield" type="number" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: idToEdit ? '8%' : '4%',
      hidden: isView && !isEditing,
      render: (text: string, record: any) => {
        return (
          <Space>
            {idToEdit === record.id ? (
              idToEdit === record.id && loading ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                <>
                  <Tooltip title={'Save'} overlayClassName="custom-tooltip">
                    <div className="w-full mx-auto text-center">
                      <CheckOutlined
                        className="w-4 text-green-500 cursor-pointer hover:opacity-75"
                        onClick={() => handleEditSubmit(record, '')}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title={'Cancel'} overlayClassName="custom-tooltip">
                    <div className="w-full mx-auto text-center">
                      <CloseOutlined
                        className="w-4 text-gray-500 cursor-pointer hover:opacity-75"
                        onClick={() => {
                          setIdToEdit(null);
                        }}
                      />
                    </div>
                  </Tooltip>
                  {/*change remarks modal */}
                  <RemarksModal
                    isOpen={isRemarksModalOpen}
                    onApprove={(value) => handleEditSubmit(record, value)}
                    loading={loading}
                    onClose={() => setIsRemarksModalOpen(false)}
                  />
                </>
              )
            ) : (
              <Tooltip title={'Edit'} overlayClassName="custom-tooltip">
                <div className="w-full mx-auto text-center">
                  <img
                    src={editIcon}
                    className="w-4 cursor-pointer hover:opacity-75 hover:invert"
                    onClick={() => {
                      handleEditClick(record.id);
                    }}
                  />
                </div>
              </Tooltip>
            )}
            <Tooltip title={text} overlayClassName="custom-tooltip">
              <div className="w-full mx-auto text-center">
                {idToRemove && idToRemove === record.id ? (
                  <Spin indicator={<LoadingOutlined spin />} size="small" />
                ) : (
                  <>
                    <img
                      src={deleteIcon}
                      className="cursor-pointer hover:opacity-75"
                      onClick={() => {
                        if (isCreate) {
                          handleDeleteProduct(record.id, '');
                        }
                        setIdToRemove(record.id);
                      }}
                    />
                  </>
                )}
              </div>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      {/*change remarks modal for deleting a product */}
      <RemarksModal
        isOpen={Boolean(idToRemove) && isView}
        onApprove={(value) => handleDeleteProduct(idToRemove, value)}
        loading={loading}
        onClose={() => setIdToRemove(null)}
      />
      <Form form={form} id="comments_products_row_edit_form">
        <Table columns={columns} dataSource={datasource} bordered />
      </Form>
    </>
  );
}
