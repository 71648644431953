import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Divider,
  Flex,
  Row,
  Spin,
} from 'antd';
import Card from 'common/Card';
import { Upload, UploadProgress } from 'common/fileUploader';
import Label from 'common/Label';
import React, { useState } from 'react';
import { TOPICS } from 'data/smartSummarize';
import { getLocalToken } from 'utilities/apiClient';
import ReactMarkdown from 'react-markdown';

const SmartSummaries: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [summaryRes, setSummaryRes] = useState<string>('');
  const [summaryRes2, setSummaryRes2] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showSummary2, setShowSummary2] = useState<boolean>(false);

  const selectAllTopics = TOPICS.length === checkedList.length;
  const selectedTopics =
    checkedList.length > 0 && checkedList.length < TOPICS.length;

  // const { summaryData } = useSelector((state: RootState) => state.summarize);

  const onTopicSelect = (list: string[]) => {
    setCheckedList(list);
  };

  const onSelectAllTopics: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? TOPICS : []);
  };

  const handleRemoveFile = (index: number | string) => {
    const allFiles = [...files];
    const filteredFiles = allFiles.filter((file, i) => file.name !== index);
    setFiles(filteredFiles);
  };

  const handleClearSearch = () => {
    setCheckedList([]);
    setFiles([]);
  };

  const user = getLocalToken();

  const handleGenerateSummary = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    setShowSummary2(false);

    // Create a FormData object to send files
    let formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    // Append the prompt as plain text
    formData.append(
      'prompt',
      `I have provided a set of experiments including procedure, results, conclusion in separate files. Can you provide a summary of the aggregate observations across experiments?`
    );
    try {
      // Send POST request to the FastAPI endpoint
      const response = await fetch(
        'https://new-atom.azurewebsites.net/api/summarizer',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: user, // Authorization token
          },
        }
      );

      // setTimeout(() => {
      setLoading(false); // Stop the spinner after 5 additional seconds
      // }, 5000);

      // Check if the response is OK and if it's in JSON format
      if (!response.ok) {
        throw new Error('Failed to upload files');
      }

      // Attempt to parse the response as JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        // Set the summary response from the API
        setShowSummary(true);
        setSummaryRes(data);
      } else {
        // If not JSON, handle the text response
        const textData = await response.text();
        setShowSummary(true);
        setSummaryRes(textData);

        // /*------------Start 2nd time API calling---------------- */
        // // setLoading2(true);
        // let formData2 = new FormData();
        // files.forEach((file) => {
        //   formData2.append('files', file);
        // });

        // formData2.append(
        //   'prompt',
        //   `I have provided a set of experiments including procedure, results, conclusion in separate files. Please generate specific chemistry advice for chemists who are making the following requests: ${checkedList.join(', ')}. Each request should have a dedicated section with the request as its title.`
        // );

        // try {
        //   // Send POST request to the FastAPI endpoint
        //   const response = await fetch(
        //     'https://new-atom.azurewebsites.net/api/summarizer',
        //     {
        //       method: 'POST',
        //       body: formData2,
        //       headers: {
        //         Authorization: user, // Authorization token
        //       },
        //     }
        //   );

        //   // setTimeout(() => {
        //   // setLoading2(false); // Stop the spinner after 5 additional seconds
        //   // }, 5000);

        //   // Check if the response is OK and if it's in JSON format
        //   if (!response.ok) {
        //     throw new Error('Failed to upload files');
        //   }
        //   setLoading(false);
        //   // Attempt to parse the response as JSON
        //   const contentType = response.headers.get('content-type');
        //   if (contentType && contentType.includes('application/json')) {
        //     const data = await response.json();
        //     // Set the summary response from the API
        //     setShowSummary2(true);
        //     setSummaryRes2(data);
        //   } else {
        //     // If not JSON, handle the text response
        //     const textData = await response.text();
        //     setShowSummary2(true);
        //     setSummaryRes2(textData);
        //   }
        // } catch (error) {
        //   console.error('Error uploading files:', error);
        //   // setLoading2(false);
        // }
        // /* ------------End 2nd API ----------------------------------------- */
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setLoading(false);
    }
  };
  const handleSmartAdvice = async (event: any) => {
    event.preventDefault();

    // setLoading(true);

    // Create a FormData object to send files
    // let formData = new FormData();
    // files.forEach((file) => {
    //   formData.append('files', file);
    // });

    // Append the prompt as plain text
    // formData.append(
    //   'prompt',
    //   `I have provided a set of experiments including procedure, results, conclusion in separate files. Can you provide a summary of the aggregate observations across experiments?`
    // );
    // try {
    //   // Send POST request to the FastAPI endpoint
    //   const response = await fetch(
    //     'https://new-atom.azurewebsites.net/api/summarizer',
    //     {
    //       method: 'POST',
    //       body: formData,
    //       headers: {
    //         Authorization: user, // Authorization token
    //       },
    //     }
    //   );

    // setTimeout(() => {
    // setLoading(false); // Stop the spinner after 5 additional seconds
    // }, 5000);

    // Check if the response is OK and if it's in JSON format
    // if (!response.ok) {
    //   throw new Error('Failed to upload files');
    // }

    // // Attempt to parse the response as JSON
    // const contentType = response.headers.get('content-type');
    // if (contentType && contentType.includes('application/json')) {
    //   const data = await response.json();
    //   // Set the summary response from the API
    //   setShowSummary(true);
    //   setSummaryRes(data);
    // } else {
    //   // If not JSON, handle the text response
    //   const textData = await response.text();
    //   setShowSummary(true);
    //   setSummaryRes(textData);

    /*------------Start 2nd time API calling---------------- */
    setLoading2(true);
    setShowSummary(false);
    let formData2 = new FormData();
    files.forEach((file) => {
      formData2.append('files', file);
    });

    formData2.append(
      'prompt',
      `I have provided a set of experiments including procedure, results, conclusion in separate files. Please generate specific chemistry advice for chemists who are making the following requests: ${checkedList.join(', ')}. Each request should have a dedicated section with the request as its title.`
    );

    try {
      // Send POST request to the FastAPI endpoint
      const response = await fetch(
        'https://new-atom.azurewebsites.net/api/summarizer',
        {
          method: 'POST',
          body: formData2,
          headers: {
            Authorization: user, // Authorization token
          },
        }
      );

      // setTimeout(() => {
      setLoading2(false); // Stop the spinner after 5 additional seconds
      // }, 5000);

      // Check if the response is OK and if it's in JSON format
      if (!response.ok) {
        throw new Error('Failed to upload files');
      }
      setLoading(false);
      // Attempt to parse the response as JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        // Set the summary response from the API
        setShowSummary2(true);
        setSummaryRes2(data);
      } else {
        // If not JSON, handle the text response
        const textData = await response.text();
        setShowSummary2(true);
        setSummaryRes2(textData);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setLoading2(false);
    }
    /* ------------End 2nd API ----------------------------------------- */
    //   }
    // } catch (error) {
    //   console.error('Error uploading files:', error);
    //   setLoading(false);
    // }
  };
  return (
    <Flex gap={30} vertical>
      <h2 className="text-2xl font-bold text-primary font-open-sans">
        Summary
      </h2>
      <Spin spinning={loading}>
        <Spin spinning={loading2}>
          <Card>
            {/* file upload */}
            <Flex vertical gap={20}>
              <Row gutter={20}>
                <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="flex flex-col justify-center gap-4 ">
                    <Label
                      label={'Upload experiment files to summarise'}
                      labelClasses="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans"
                    />
                    <Upload
                      type="inline"
                      limit={10}
                      setFiles={setFiles}
                      files={files}
                      allowedFileTypes={['.docx', '.doc']}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={10} className="flex flex-1 gap-2 px-2">
                {files.map((file, index) => {
                  return (
                    <div
                      key={file.lastModified}
                      className={`flex ${files.length > 4 && 'flex-1'} min-w-fit`}
                    >
                      <UploadProgress
                        file={file}
                        index={file.name}
                        onDelete={handleRemoveFile}
                        size={Number((file.size / 1024).toFixed(2))}
                        percentage={100}
                      />
                    </div>
                  );
                })}
              </Row>
              <Divider className="!m-0" />

              {/* topics */}
              <Row gutter={20} className="flex flex-col gap-4">
                <Col span={24} className="flex justify-between">
                  <Label
                    label={'Tell the model where you need help'}
                    labelClasses="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans"
                  />
                  <Checkbox
                    indeterminate={selectedTopics}
                    onChange={onSelectAllTopics}
                    checked={selectAllTopics}
                    className="text-sm font-semibold font-open-sans text-tertiary-dark"
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox.Group
                    // options={TOPICS}
                    value={checkedList}
                    onChange={onTopicSelect}
                    className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2"
                  >
                    {TOPICS.map((topic) => {
                      return (
                        <Checkbox
                          key={topic}
                          className={`gap-2 p-3 text-xs font-semibold border rounded-lg hover:bg-tertiary-highlight-100 ${checkedList.includes(topic) ? 'border-primary' : 'border-tertiary-highlight-200'} font-open-sans`}
                          value={topic}
                        >
                          {topic}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Col>
              </Row>

              {/* buttons */}
              <div className="flex justify-end w-full gap-4 mt-4">
                <Button
                  className="font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                  onClick={handleClearSearch}
                >
                  Clear Search
                </Button>
                <Button
                  className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
                  onClick={handleGenerateSummary}
                >
                  Smart Summary
                </Button>
                <Button
                  className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
                  onClick={handleSmartAdvice}
                >
                  Smart Advice
                </Button>
              </div>

              {showSummary && (
                <div>
                  <h6 className="text-sm font-bold text-tertiary-dark font-open-sans">
                    View Smart Summary
                  </h6>

                  <div className="p-3 mt-2 mb-2 text-sm border rounded-lg text-tertiary-dark font-open-sans border-tertiary-highlight-200">
                    <ReactMarkdown>{summaryRes}</ReactMarkdown>
                  </div>
                </div>
              )}

              {showSummary2 && (
                <div>
                  <h6 className="text-sm font-bold text-tertiary-dark font-open-sans">
                    {/* Suggested Improvements */}
                    View Smart Advice
                  </h6>
                  <h6 className="text-sm font-bold text-tertiary-dark font-open-sans mt-2">
                    {/* Selected modal:{' '} */}
                    <span className="text-xs text-tertiary-dark font-open-sans">{`${checkedList.join(', ')}`}</span>
                  </h6>

                  <div className="p-3 mt-2 mb-2 text-sm border rounded-lg text-tertiary-dark font-open-sans border-tertiary-highlight-200">
                    <ReactMarkdown>{summaryRes2}</ReactMarkdown>
                  </div>
                </div>
              )}
            </Flex>
          </Card>
        </Spin>
      </Spin>
    </Flex>
  );
};

export default SmartSummaries;
