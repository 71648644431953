import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { centralLogs } from './api';

interface ProjectState {
  centralLogs: any[];
  centralLogsLoading: boolean;
  pagination: any;
}

const initialState: ProjectState = {
  centralLogs: [],
  centralLogsLoading: false,
  pagination: {},
};

const actions = {
  CENTRAL_LOGS: 'reports/CENTRAL_LOGS',
};

export const getCentralLogs = createAsyncThunk(
  actions.CENTRAL_LOGS,
  async (payload: Record<string, any>) => {
    const response = await centralLogs(payload);
    return response;
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get central logs
    builder
      .addCase(getCentralLogs.pending, (state) => {
        state.centralLogsLoading = true;
      })
      .addCase(getCentralLogs.fulfilled, (state, action) => {
        state.centralLogsLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.centralLogs = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getCentralLogs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.centralLogsLoading = false;

        message.error(msg);
      });
  },
});

export default reportsSlice.reducer;
