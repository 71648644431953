import { Button, Col, Flex, Form, message } from 'antd';
import logo from 'assets/icons/logo_new.svg';
import logo_full from 'assets/icons/logo_full_new.svg';
import { useNavigate } from 'react-router-dom';
import FormInput from 'common/FormInput';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ApiResponse, sendPasswordResetLink } from '../redux/slice';
import arrow_left from 'assets/icons/arrow_left.svg';
import { TabTitle } from 'utilities/helpers';

interface Payload {
  employee_id: string;
}

const ForgetPassword: React.FC = () => {
  TabTitle('Forget Password | NewAtom ELN');
  const dispatch: AppDispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.login);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSendResetLink = async (values: Payload) => {
    try {
      // Dispatch the action and wait for it to complete
      const response: ApiResponse = await dispatch(
        sendPasswordResetLink(values)
      ).unwrap();

      if (response.success) {
        // message.success(response.message);
        navigate('/login');
      } else {
        // message.error(response.message);
      }
    } catch {
      // Handle error here
      message.error('Failed to send password reset link');
    }
  };

  return (
    <div className="relative h-screen">
      <div className="w-full h-full bg-center bg-cover login-bg-filter md:w-4/5 lg:w-5/6"></div>
      <Flex
        className="absolute -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 right-1/2 md:right-0 left-1/2 md:left-auto md:translate-x-0 md:-translate-y-1/2 rounded-3xl md:rounded-s-[6rem] py-12 md:py-12 xl:py-16 px-8 md:px-12 lg:px-16 xl:px-20 w-[90vw] sm:w-[60vw] md:w-[40vw]"
        vertical
        gap={'2rem'}
      >
        <div className="flex items-center justify-center gap-2 px-2 md:justify-center">
          <img alt="logo" src={logo} className="w-[10%] sm:w-[12%]" />
          <img alt="logo full" src={logo_full} className="w-[25%] md:w-[40%]" />
        </div>
        <div className="absolute flex items-center justify-center font-open-sans top-4 right-4 xl:top-8 xl:right-12">
          <Button
            icon={<img src={arrow_left} alt="arrow left" />}
            onClick={() => navigate(-1)}
            type="text"
            className="text-xs font-semibold text-primary xl:text-base font-open-sans"
          >
            Go back
          </Button>
        </div>
        <Form layout="vertical" form={form} onFinish={handleSendResetLink}>
          <h2 className="mb-4 text-lg font-bold font-open-sans md:text-xl text-primary-filter">
            Forgot Password
          </h2>

          <Flex gap="1rem" vertical>
            <p className="font-open-sans">
              Enter your Employee Id and we'll send you a link to reset your
              password
            </p>
            <Col span={24}>
              <FormInput
                hideColon
                classes="!m-1 !border-none"
                label="Employee Id"
                name="employee_id"
                validateMsg="User Id is required"
                placeholder="Enter employee id"
                required
              />
            </Col>
          </Flex>
          <Form.Item className="mt-4">
            <Flex flex={1}>
              <Button
                htmlType="submit"
                size="large"
                className="flex-1 text-sm font-bold text-white font-open-sans bg-primary lg:text-base"
                loading={loading}
              >
                Request reset link
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </div>
  );
};

export default ForgetPassword;
