import { Button, Tooltip } from 'antd';
import { RootState } from 'app/store';
import Modal from 'common/Modal';
import Table from 'common/Table';
import { useResponsiveness } from 'hooks/useResponsiveness';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFieldformattedText, getFieldNameText } from 'utilities/helpers';

const columns = [
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Field
      </label>
    ),
    dataIndex: 'field',
    key: 'field',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed from
      </label>
    ),
    dataIndex: 'changed_from',
    key: 'changed_from',
    width: '20%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed To
      </label>
    ),
    dataIndex: 'changed_to',
    key: 'changed_to',
    width: '17%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed By
      </label>
    ),
    dataIndex: 'changed_by',
    key: 'changed_by',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed On
      </label>
    ),
    dataIndex: 'changed_on',
    key: 'changed_on',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Remarks
      </label>
    ),
    dataIndex: 'remarks',
    key: 'remarks',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
];

type TLogs = {
  field: string;
  changed_from: string;
  changed_to: string;
  changed_by: string;
  changed_on: string;
  remarks: string;
};

type TProps = {
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
};

export default function CentralLogs({
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM
}: TProps) {
  const { sm, xs, md } = useResponsiveness();

  const { centralLogs, centralLogsLoading } = useSelector(
    (state: RootState) => state.reports
  );

  //transform central logs
  const transformedCentralLogs: TLogs[] = centralLogs.length
    ? centralLogs.map((logs: any) => {
        return {
          field: getFieldNameText(logs.field),
          changed_from: getFieldformattedText(logs.changed_from),
          changed_to: getFieldformattedText(logs.changed_to),
          changed_by: logs.user?.first_name,
          remarks: logs.change_remarks,
          changed_on: moment(logs.changed_on).format('DD/MM/YYYY hh:mm a'),
        };
      })
    : [];

  return (
    <div className="px-2 py-0 mt-5 md:px-4">
      <Table
        hideScroll={sm || xs || md ? false : true}
        spanWidth={600}
        columns={columns}
        dataSource={transformedCentralLogs}
        bordered
        loading={centralLogsLoading}
        totalPages={pagination?.total_pages}
        pagination={true}
        pageSize={PER_PAGE_ITEM}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
