import bold from 'assets/icons/editor/bold.svg';
import italic from 'assets/icons/editor/italic.svg';
import quotes from 'assets/icons/editor/quotes.svg';
import unordered_list from 'assets/icons/editor/unordered_list.svg';
import ordered_list from 'assets/icons/editor/ordered_list.svg';
import gallery from 'assets/icons/editor/gallery.svg';
import attachment from 'assets/icons/editor/attachment.svg';
import sizeDec from 'assets/icons/editor/size_decrease.svg';
import sizeInc from 'assets/icons/editor/size_increase.svg';

const colors = ['red', 'green', 'blue', 'orange', 'violet'];
const formats = [
  [
    {
      className: 'ql-size',
      options: ['small', 'large', 'huge'],
    },
    { className: 'ql-bold', icon: bold },
    { className: 'ql-italic', icon: italic },
    {
      className: 'ql-header',
      value: '1',
      icon: sizeInc,
    },
    {
      className: 'ql-header',
      value: '2',
      icon: sizeDec,
    },
    {
      className: 'ql-blockquote',
      icon: quotes,
    },
    {
      className: 'ql-list',
      value: 'ordered',
      icon: ordered_list,
    },
    {
      className: 'ql-list',
      value: 'bullet',
      icon: unordered_list,
    },
    { className: 'ql-link', icon: attachment },
    { className: 'ql-image', icon: gallery },
  ],
];

export default formats;
