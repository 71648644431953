import { Grid } from 'antd';
const { useBreakpoint } = Grid;
interface Responsiveness {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

const useResponsiveness = (): Responsiveness => {
  const screen = useBreakpoint();

  return {
    xs: screen.xs ?? false,
    sm: (screen.sm ?? false) && !(screen.md ?? false),
    md: (screen.md ?? false) && !(screen.lg ?? false),
    lg: (screen.lg ?? false) && !(screen.xl ?? false),
    xl: (screen.xl ?? false) && !(screen.xxl ?? false),
    xxl: screen.xxl ?? false,
  };
};

export { useResponsiveness };
