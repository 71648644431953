import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { useDispatch } from 'react-redux';
import { createTypeOfList } from 'features/Admin/redux/slice';
import RemarksModal from 'common/RemarksModal';
import { useSelector } from 'react-redux';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  addUserLoading: boolean;
  isEdit?: boolean;
  handelgetTypeOfList: () => void;
  userDataById?: any;
};

export default function TypeOfTestModal({
  isEdit,
  isOpen,
  onClose,
  addUserLoading,
  handelgetTypeOfList,
  userDataById,
}: TProps) {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { createTypeOfListLoading } = useSelector(
    (state: RootState) => state.admin
  );

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        type_of_test: userDataById?.type_of_test,
      });
    }
  }, [userDataById]);

  const handleOnFinish = (values: any) => {
    if (isEdit) {
      setIsRemarksModalOpen(false);
      const payload = {
        ...values,
        id: userDataById.key,
        is_active: true,
        change_remarks: values.remark,
      };
      dispatch(createTypeOfList(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelgetTypeOfList();
        }
      });
    } else {
      const payload = {
        ...values,
      };
      dispatch(createTypeOfList(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelgetTypeOfList();
        }
      });
    }
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isEdit) {
          if (isRemarksModalOpen) {
            handleOnFinish({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        } else {
          handleOnFinish({ remark, ...values });
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      headerTitle={isEdit ? 'Edit Type of Test' : 'Add Type of Test'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => form.resetFields()}
      btnLoading={addUserLoading}
      agreeText={'Add Type of Test'}
      footerComponent={
        isEdit ? (
          <Flex justify="center" wrap gap={10} className="pb-6">
            <Button
              onClick={() => {
                onClose();
                form.resetFields();
              }}
              className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="font-semibold text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:border-primary"
              onClick={() => handleSubmit("")}
              loading={addUserLoading}
            >
              Update
            </Button>
          </Flex>
        ) : undefined
      }
    >
      <Form
        id="add_user_modal_form"
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        <div className="!p-4">
          <Row gutter={10}>
            <Col span={24}>
              <FormInput
                marginBottom="medium"
                name="type_of_test"
                label={<span className="text-tertiary-dark">Type of Test</span>}
                defaultStyle={false}
                placeholder="Enter type of test"
                required
                validateMsg="Type of test is required"
              />
            </Col>
          </Row>

          {/* change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={createTypeOfListLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      </Form>
    </Modal>
  );
}
