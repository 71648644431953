import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { summarize } from './api';

interface SummaryState {
  summaryData: any;
  summaryLoading: boolean;
}

const initialState: SummaryState = {
  summaryLoading: false,
  summaryData: null,
};

const actions = {
  SUMMARIZE: 'summary/SUMMARIZE',
};

export const createSummary = createAsyncThunk(
  actions.SUMMARIZE,
  async (payload: Record<string, any>) => {
    const response = await summarize(payload);
    return response;
  }
);

export const summarizeSlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get summary data
    builder
      .addCase(createSummary.pending, (state) => {
        state.summaryLoading = true;
      })
      .addCase(createSummary.fulfilled, (state, action) => {
        state.summaryLoading = false;
        console.log('action.payload', action);
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.summaryData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(createSummary.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.summaryLoading = false;

        message.error(msg);
      });
  },
});

export default summarizeSlice.reducer;
