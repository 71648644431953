import { Radio } from 'antd';
import { useState } from 'react';

type TTab = {
  id: number;
  label: string;
  content: string | JSX.Element;
  disabled?: boolean;
};

type TProps = {
  tabs: TTab[];
};

export default function Tabs({ tabs }: TProps) {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200">
        {tabs.map((tab) => {
          return (
            <Radio.Button
              key={tab.id}
              className={`font-bold shadow-none py-5 flex justify-center items-center transition-colors duration-100 ${activeTab === tab.id ? 'bg-secondary border-none' : 'bg-transparent'} ${tab.disabled ? 'text-tertiary-filter' : '!text-primary'}`}
              onClick={() => setActiveTab(tab.id)}
              disabled={tab.disabled}
            >
              {tab.label}
            </Radio.Button>
          );
        })}
      </div>
      <div className="mt-2">
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
}
