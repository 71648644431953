import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { QuillToolbar, formats, modules } from './customization';
import { useEffect, useRef, useState } from 'react';
import Label from 'common/Label';
import { QuillToolbar } from './toolbar';

type TProps = {
  label?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
};

const MAX_LENGTH = 2000;

export default function Editor({
  label,
  value,
  setValue,
  placeholder,
}: TProps) {
  const [contentLength, setContentLength] = useState<number>(0);
  const quillRef = useRef<ReactQuill | null>(null);

  //preventing user to enter more than allowed length
  const handleChange = (html: string) => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const length = quill.getText().trim().length;
      if (length > MAX_LENGTH) {
        quill.deleteText(MAX_LENGTH, length); 
        return;
      } else {
        setValue(html);
        setContentLength(length);
      }
    }
  };
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  const formats = [
    'size',
    'bold',
    'italic',
    'header',
    'blockquote',
    'list',
    'link',
    'image',
  ];

  return (
    <div className="flex flex-col gap-3 mt-1 sm:mt-0">
      {label && <Label label={label} />}
      <div className="w-full border border-tertiary-highlight-200 rounded-xl">
        <QuillToolbar />
        <ReactQuill
          ref={quillRef}
          value={value}
          modules={modules}
          formats={formats}
          onChange={handleChange}
          placeholder={placeholder}
          className="min-h-32"
        />
      </div>
      <div className="ml-2 text-xs text-left text-tertiary-filter">
        {`${MAX_LENGTH - contentLength}/${MAX_LENGTH} characters`}
      </div>
    </div>
  );
}
