import { Button, Col, Form, FormInstance, Row } from 'antd';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
import plusIcon from 'assets/icons/plus.svg';
import Label from 'common/Label';
import { useCallback, useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFolderRouteList,
  getProjectTeamList,
  getTechnicalLeadList,
} from 'features/sectionHeadFlow/redux/slice';
import { debounce } from 'lodash';
import AccessAssigner from 'common/AccessAssigner';
import Spacer from 'common/Spacer';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { PlusOutlined } from '@ant-design/icons';
// import KetcherDemo from '../chemicalStructureKetcher/index';
import KetcherTabs from '../chemicalStructureKetcher/KetcherTabs';
import deleteIcon from 'assets/icons/delete.svg';
import { SECTION_HEAD } from 'utilities/roleRights';
import { getLoggedInUser } from 'utilities/helpers';

type TSteps = {
  reaction_scheme: string;
  description: string;
  smiles_string: string;
};

export type TProps = {
  children?: string | JSX.Element;
  form: FormInstance<any>;
  steps: any[];
  setSteps: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedTechnicalLead: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTechnicalLead: any[];
  selectedProjectTeam: any[];
  setSelectedProjectTeam: React.Dispatch<React.SetStateAction<any[]>>;
  lastSmiles: string;
  setLastSmiles: React.Dispatch<React.SetStateAction<string>>;
  newTabCleared: boolean;
  setNewTabCleared: React.Dispatch<React.SetStateAction<boolean>>;
  ketcherSmiles: string;
  setKetcherSmiles: React.Dispatch<React.SetStateAction<string>>;
  tabClicked: boolean;
  setTabClicked: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

export default function FolderDetails({
  children,
  form,
  steps,
  setSteps,
  setSelectedTechnicalLead,
  selectedTechnicalLead,
  selectedProjectTeam,
  setSelectedProjectTeam,
  lastSmiles,
  setLastSmiles,
  newTabCleared,
  setNewTabCleared,
  ketcherSmiles,
  setKetcherSmiles,
  tabClicked,
  setTabClicked,
  activeTab,
  setActiveTab,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const [routeSearch, setRouteSearch] = useState('');
  const { role } = getLoggedInUser();
  //const [activeTab, setActiveTab] = useState<number>(0); // Active tab index
  // const [newTabCleared, setNewTabCleared] = useState<boolean>(false); // Flag to clear editor on new tab
  // const [ketcherSmiles, setKetcherSmiles] = useState<string>(''); // State to track SMILES string
  // const [tabClicked, setTabClicked] = useState<boolean>(false);

  const { folderRouteList, technicalLeadList, projectTeamList } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const { xs, sm, md } = useResponsiveness();

  useEffect(() => {
    const payload = {
      search: routeSearch,
    };
    dispatch(getFolderRouteList(payload));
  }, [routeSearch]);

  useEffect(() => {
    dispatch(getTechnicalLeadList());
  }, []);

  useEffect(() => {
    dispatch(getProjectTeamList());
  }, []);

  // const handleAddStep = () => {
  //   setSteps([
  //     ...steps,
  //     {
  //       reaction_scheme: '',
  //       description: '',
  //       smiles_string: '',
  //     },
  //   ]);
  //   form.setFieldsValue({ step_no: steps.length + 1 });
  // };
  const handleAddStep = () => {
    const currentStep = steps[activeTab];
    // Save current state
    const updatedSteps = steps.map((step, i) =>
      i === activeTab ? { ...step, smiles_string: ketcherSmiles } : step
    );

    // Add new step and set it as active
    setSteps([
      ...updatedSteps,
      { reaction_scheme: '', smiles_string: '', description: '' },
    ]);
    setActiveTab(steps.length); // Set the newly added tab as active
    setNewTabCleared(true); // Trigger clearing the editor
    setTabClicked(true);
  };

  const renderedFolderList = folderRouteList.map((item) => {
    return { label: item.route, value: item.id };
  });

  const renderedTechnicalLeadList = technicalLeadList.map((item) => {
    return {
      label: item.display_name,
      value: item.id,
      disabled: selectedProjectTeam.some((user) => user.id === item.id),
    };
  });

  const renderedProjectTeamList = projectTeamList.map((item) => {
    return {
      label: item.display_name,
      value: item.id,
      disabled: selectedTechnicalLead.some((user) => user.id === item.id),
    };
  });

  const handleRouteChange = useCallback(
    debounce((value) => {
      setRouteSearch(value);
    }, 500),
    []
  );
  const onRouteSearch = (value: string) => {
    handleRouteChange(value);
  };

  const handleUpdateUserAccess = (
    access: string[],
    role: string,
    id: string
  ) => {
    const view = access.includes('view_access') ? true : false;
    const edit = access.includes('edit_access') ? true : false;
    const approve = access.includes('approve_access') ? true : false;

    if (role === 'technical_lead') {
      const updatedUser = selectedTechnicalLead.map((user) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedTechnicalLead(updatedUser);
    }

    if (role === 'remove_technical_lead') {
      const users = [...selectedTechnicalLead];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedTechnicalLead(filteredUsers);

      const selectedValues = form.getFieldValue('technical_lead');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ technical_lead: selectedUsers });
    }

    if (role === 'project_team') {
      const updatedUser = selectedProjectTeam.map((user) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedProjectTeam(updatedUser);
    }

    if (role === 'remove_project_team') {
      const users = [...selectedProjectTeam];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedProjectTeam(filteredUsers);

      const selectedValues = form.getFieldValue('project_team');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ project_team: selectedUsers });
    }
  };

  return (
    <Card>
      <>
        <Row gutter={20} className="">
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="folder_route_id"
              label="Route"
              type="select"
              onSearch={onRouteSearch}
              defaultStyle={true}
              placeholder="Select a route"
              options={renderedFolderList}
              required
              validateMsg="Route is required"
            />
          </Col>
          <Col span={24} sm={{ span: 4 }}>
            <Spacer xs={xs}>
              <Button
                size="large"
                type="primary"
                // icon={<img src={plusIcon} />}
                className={`w-full text-xs font-semibold bg-transparent font-open-sans ${steps.length < 1 ? 'border text-tertiary border-tertiary-highlight-200 bg-gray-400' : 'border text-secondary-highlight-100 border-secondary-highlight-100 hover:!bg-primary hover:!text-white'}`}
                onClick={handleAddStep}
                disabled={steps.length < 1}
                icon={<PlusOutlined />}
              >
                Add Step
              </Button>
            </Spacer>
          </Col>
        </Row>
        <KetcherTabs
          steps={steps}
          setSteps={setSteps}
          handleAddStep={handleAddStep}
          lastSmiles={lastSmiles}
          setLastSmiles={setLastSmiles}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          newTabCleared={newTabCleared}
          setNewTabCleared={setNewTabCleared}
          ketcherSmiles={ketcherSmiles}
          setKetcherSmiles={setKetcherSmiles}
          tabClicked={tabClicked}
          setTabClicked={setTabClicked}
        />
        {/*  <Row>
          <Col span={24}>
            <FormInput
              defaultStyle
              name="reaction_scheme"
              label={
                steps.length > 1 ? (
                  <span className="flex items-center ">
                    <span>Reaction Scheme</span>
                    <Form.Item name="step_no" className="p-0 m-0">
                      <select>
                        {steps.map((step, i) => {
                          return (
                            <option key={i} value={i}>
                              (Step-{i + 1})
                            </option>
                          );
                        })}
                      </select>
                    </Form.Item>
                  </span>
                ) : (
                  'Reaction Scheme'
                )
              }
              type="placeholder"
              // placeholderComponent={
              //   <KetcherDemo
              //     lastSmiles={lastSmiles}
              //     setLastSmiles={setLastSmiles}
              //   />
              // }
              rows={12}
              placeholder="Add reaction scheme"
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormInput
              defaultStyle
              name="description"
              label="Description"
              type="textarea"
              placeholder="Enter description"
              rows={5}
            />
          </Col>
        </Row> */}
        {role === SECTION_HEAD && (
          <Row gutter={20}>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                name="technical_lead"
                label="Assign Technical Lead"
                type="select"
                multiple
                removeIcon={
                  <img
                    src={deleteIcon}
                    className="cursor-pointer hover:opacity-75"
                  />
                }
                defaultStyle={true}
                placeholder="Select technical lead"
                required
                validateMsg="Assign technical lead is required"
                options={renderedTechnicalLeadList}
              />
            </Col>
            {/* <Col span={24} md={{ span: 16 }}>
            <div className="flex flex-wrap flex-1 gap-2">
              {selectedTechnicalLead.map((lead) => {
                return (
                  <div key={lead.id}>
                    {xs || sm ? (
                      <div className="mt-2">
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="technical_lead"
                        />
                      </div>
                    ) : (
                      <Spacer sm={sm} xs={xs}>
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="technical_lead"
                        />
                      </Spacer>
                    )}
                  </div>
                );
              })}
            </div>
          </Col> */}
          </Row>
        )}
        <Row gutter={20} className="mt-2">
          <Col span={24} md={{ span: 8 }}>
            <FormInput
              name="project_team"
              label="Assign Project Team"
              type="select"
              multiple
              defaultStyle={true}
              placeholder="Select project team"
              options={renderedProjectTeamList}
              required
              removeIcon={
                <img
                  src={deleteIcon}
                  className="cursor-pointer hover:opacity-75"
                />
              }
              validateMsg="Assign project team is required"
            />
          </Col>
          {/* <Col span={24} md={{ span: 16 }}>
            <div className="flex flex-wrap flex-1 gap-2">
              {selectedProjectTeam.map((lead) => {
                return (
                  <div key={lead.id}>
                    {xs || sm ? (
                      <div className="mt-2">
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="project_team"
                        />
                      </div>
                    ) : (
                      <Spacer sm={sm} xs={xs}>
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="project_team"
                        />
                      </Spacer>
                    )}
                  </div>
                );
              })}
            </div>
          </Col> */}
        </Row>
        {!children ? '' : children}
      </>
    </Card>
  );
}
