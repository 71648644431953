import { Dropdown } from 'antd';

type TProps = {
  children: string | JSX.Element;
  content: string | JSX.Element | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export default function LinkDropdown({
  children,
  content,
  isOpen,
  setIsOpen,
}: TProps) {
  return (
    <Dropdown
      placement="bottom"
      menu={{
        items: [
          {
            key: 1,
            label: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {content}
              </div>
            ),
            className: '!p-0 !m-0',
          },
        ],
      }}
      arrow
      open={isOpen}
      onOpenChange={(visible) => setIsOpen(visible)}
      mouseLeaveDelay={0.8}
    >
      {children}
    </Dropdown>
  );
}
