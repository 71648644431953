import { SendOutlined } from '@ant-design/icons';
import { Form, message, Spin } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import { getprojectList_dropdown } from 'features/proposalLeadFlow/dashboard/redux/slice';
import {
  getFolderList,
  resetFolderDropdownSearch,
} from 'features/sectionHeadFlow/redux/slice';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type TProps = {
  isOpen: boolean;
  setIsOpen: any;
};

export default function FolderSearch({ isOpen, setIsOpen }: TProps) {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    projectListData,
    projectListDropdownData,
    getprojectListDropdownDataLoading,
  } = useSelector((state: RootState) => state.project);

  const { folderList, folderListLoading } = useSelector(
    (state: RootState) => state.sectionHead
  );

  //handle project search
  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      const payload = {
        page: 1,
        perPage: 20,
        search: searchTerm,
      };
      dispatch(getprojectList_dropdown(payload));
    }
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  //rendering project search dropdown
  const transformedProjectList = projectListDropdownData?.length
    ? projectListDropdownData.map((project: any) => {
        return { label: project.project_code, value: project.id };
      })
    : projectListData.map((project: any) => {
        return { label: project.project_code, value: project.id };
      });

  //rendering folder list
  const transformedFolderList = folderList?.length
    ? folderList.map((folder: any) => {
        return {
          label: `${folder.folder_route.route + folder.folder_number}`,
          value: folder.id,
        };
      })
    : [];

  //handle form value change
  const handleFormValueChange = (changedVal: any, values: any) => {
    if (changedVal.project_search) {
      const payload = {
        project_id: changedVal.project_search,
      };
      dispatch(getFolderList(payload));
    }

    if (changedVal.folder_search) {
      const projectId = form.getFieldValue('project_search');
      const folderId = form.getFieldValue('folder_search');
      if (folderId && projectId) {
        navigate(`/experiments/${projectId}/${folderId}`);
        form.resetFields();
        dispatch(resetFolderDropdownSearch());
        setIsOpen(false);
      } else {
        message.error('Please select a folder');
      }
    }
  };
  //reseting fields
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Form
      className="flex flex-col gap-2 bg-white w-28 lg:w-full"
      form={form}
      onValuesChange={handleFormValueChange}
    >
      <div className="flex flex-col gap-1">
        <FormInput
          name="project_search"
          type="select"
          placeholder="Search projects"
          selectplaceholderClasses="text-xs font-open-sans"
          options={transformedProjectList}
          onSearch={debouncedSearch}
          optionRender={(option: any) => {
            return (
              <span className="!text-xs font-open-sans">{option.label}</span>
            );
          }}
          selectInputSize="middle"
          marginBottom="none"
          defaultActiveFirstOption={false}
          notFoundContent={null}
          suffixIcon={
            getprojectListDropdownDataLoading ? <Spin size="small" /> : null
          }
        />
      </div>
      {form.getFieldValue('project_search') && (
        <div className="flex flex-col gap-1">
          <FormInput
            name="folder_search"
            type="select"
            selectplaceholderClasses="text-xs font-open-sans"
            options={transformedFolderList}
            onSearch={handleSearch}
            marginBottom="none"
            selectInputSize="middle"
            optionRender={(option: any) => {
              return (
                <span className="!text-xs font-open-sans">{option.label}</span>
              );
            }}
            placeholder="Search folders"
            defaultActiveFirstOption={false}
            notFoundContent={null}
            suffixIcon={folderListLoading ? <Spin size="small" /> : null}
          />
        </div>
      )}
    </Form>
  );
}
