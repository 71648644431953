import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/login/redux/slice';
//import homeReducer from '../features/home/redux/slice';
import projectReducer from '../features/proposalLeadFlow/dashboard/redux/slice';
import projectReducer2 from '../features/proposalLeadFlow/projects/redux/slice';
import sectionHeadReducer from '../features/sectionHeadFlow/redux/slice';
import experimentsReducer from 'features/sectionHeadFlow/experiments/redux/slice';
import adminReducer from 'features/Admin/redux/slice';
import summaryReducer from 'features/smartSummaries/redux/slice';
import reportsReducer from 'features/reports/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    //home: homeReducer,
    project: projectReducer,
    project2: projectReducer2,
    sectionHead: sectionHeadReducer,
    experiments: experimentsReducer,
    admin: adminReducer,
    summarize: summaryReducer,
    reports: reportsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Define a type for the thunk actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// import { configureStore } from '@reduxjs/toolkit';
// import authReducer from '../features/login/redux/slice';

// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//   },
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
