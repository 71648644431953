import { Col, FormInstance, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import Card from 'common/Card';
import { Upload, UploadProgress } from 'common/fileUploader';
import FormInput from 'common/FormInput';
import Label from 'common/Label';
import {
  getSectionHeadsList,
  getTechnicalLeadList,
} from 'features/sectionHeadFlow/redux/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccessAssigner from 'common/AccessAssigner';
import { useResponsiveness } from 'hooks/useResponsiveness';
import Spacer from 'common/Spacer';
import deleteIcon from 'assets/icons/delete.svg';
import { removeProjectDocs } from '../redux/slice';

type TProps = {
  children: string | JSX.Element | undefined;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  sectionHeadList: any[];
  setSelectedSectionHead: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSectionHead: any[];
  technicalLeadList: any[];
  setSelectedTechnicalLead: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTechnicalLead: any[];
  form: FormInstance<any>;
};

export default function AttachmentDetails({
  children,
  setFiles,
  files,
  sectionHeadList,
  selectedTechnicalLead,
  setSelectedTechnicalLead,
  technicalLeadList,
  setSelectedSectionHead,
  form,
  selectedSectionHead,
}: TProps) {
  const { xs, sm } = useResponsiveness();

  const handleRemoveImage = (index: number | string, isEdit = false) => {
    const images = [...files];
    const filteredImages = images.filter((img, i) => i !== index);
    setFiles(filteredImages);
  };
  const dispatch: AppDispatch = useDispatch();

  const handleRemoveUploadedImage = (
    id: string | number,
    index: number | string
  ) => {
    const images = [...files];
    const filteredImages = images.filter((img, i) => i !== index);
    dispatch(removeProjectDocs({ id })).then((res: any) => {
      if (res?.payload?.success) {
        setFiles(filteredImages);
      }
    });
  };

  const handleUpdateUserAccess = (
    access: string[],
    role: string,
    id: string
  ) => {
    const view = access.includes('view_access') ? true : false;
    const edit = access.includes('edit_access') ? true : true;
    const approve = access.includes('approve_access') ? true : true;

    if (role === 'section_head') {
      const updatedUser = selectedSectionHead.map((user: any) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedSectionHead(updatedUser);
    }

    if (role === 'remove_section_head') {
      const users = [...selectedSectionHead];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedSectionHead(filteredUsers);

      const selectedValues = form.getFieldValue('section_head');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ section_head: selectedUsers });
    }

    if (role === 'technical_lead') {
      const updatedUser = selectedTechnicalLead.map((user: any) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedTechnicalLead(updatedUser);
    }

    if (role === 'remove_technical_lead') {
      const users = [...selectedTechnicalLead];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedTechnicalLead(filteredUsers);

      const selectedValues = form.getFieldValue('technical_lead');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ technical_lead: selectedUsers });
    }
  };

  useEffect(() => {
    dispatch(getSectionHeadsList());
    dispatch(getTechnicalLeadList());
  }, []);

  const renderedSectionHeadList = sectionHeadList.map((item) => {
    return { label: item.display_name, value: item.id };
  });

  const renderedTechnicalLeadList = technicalLeadList.map((item) => {
    return { label: item.display_name, value: item.id };
  });

  return (
    <Card>
      <>
        <Row gutter={20}>
          <Col span={24} lg={{ span: 6 }} md={{ span: 6 }}>
            <div className="flex flex-col justify-center gap-4 ">
              <Label label={'Attachments'} />
              <Upload
                type="inline"
                limit={10}
                setFiles={setFiles}
                files={files}
              />
            </div>
          </Col>
          <Col span={24} md={{ span: 18 }}>
            <FormInput
              name="progress"
              type="placeholder"
              label={' '}
              placeholderComponent={
                <Row gutter={10} className="flex flex-1 gap-2">
                  {files.map((file: any, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex ${files.length > 4 && 'flex-1'} min-w-fit`}
                      >
                        {file.originalName ? (
                          <UploadProgress
                            file={file}
                            index={file.id}
                            onDelete={(id) =>
                              handleRemoveUploadedImage(id, index)
                            }
                            size={'Uploaded'}
                            percentage={100}
                          />
                        ) : (
                          <UploadProgress
                            file={file}
                            index={index}
                            onDelete={handleRemoveImage}
                            size={Number((file.size / 1024).toFixed(2))}
                            percentage={100}
                          />
                        )}
                      </div>
                    );
                  })}
                </Row>
              }
            />
          </Col>
        </Row>
        <Row gutter={20} className="mt-4">
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="section_head"
              label="Assign Section Head"
              type="select"
              multiple
              required
              validateMsg="Section Head is required"
              removeIcon={
                <img
                  src={deleteIcon}
                  className="cursor-pointer hover:opacity-75"
                />
              }
              defaultStyle={true}
              placeholder="Select section head"
              options={renderedSectionHeadList}
            />
          </Col>
          {/* <Col span={24} md={{ span: 16 }}>
            <div className="flex flex-wrap flex-1 gap-2">
              {selectedSectionHead.map((lead) => {
                return (
                  <div key={lead.id}>
                    {xs || sm ? (
                      <div className="mt-2">
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="section_head"
                        />
                      </div>
                    ) : (
                      <Spacer sm={sm} xs={xs}>
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="section_head"
                        />
                      </Spacer>
                    )}
                  </div>
                );
              })}
            </div>
          </Col> */}
        </Row>
        <Row gutter={20} className="mt-4">
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="technical_lead"
              label="Assign Technical Lead"
              type="select"
              multiple
              removeIcon={
                <img
                  src={deleteIcon}
                  className="cursor-pointer hover:opacity-75"
                />
              }
              defaultStyle={true}
              placeholder="Select techincal lead"
              options={renderedTechnicalLeadList}
            />
          </Col>
          {/* <Col span={24} md={{ span: 16 }}>
            <div className="flex flex-wrap flex-1 gap-2">
              {selectedTechnicalLead.map((lead) => {
                return (
                  <div key={lead.id}>
                    {xs || sm ? (
                      <div className="mt-2">
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="technical_lead"
                        />
                      </div>
                    ) : (
                      <Spacer sm={sm} xs={xs}>
                        <AccessAssigner
                          user={lead}
                          updateUserAccess={handleUpdateUserAccess}
                          userRole="technical_lead"
                        />
                      </Spacer>
                    )}
                  </div>
                );
              })}
            </div>
          </Col> */}
        </Row>
        {!children ? '' : children}
      </>
    </Card>
  );
}
