import { Button, Flex, Form, message, Spin } from 'antd';
import IndustrialDetails from '../components/IndustrialDetails';
import CompoundDetails from '../components/CompoundDetails';
import { useEffect, useState } from 'react';
import AttachmentDetails from '../components/AttachmentDetails';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, getIndustryTypes } from '../redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  generateUniqueProjectCodeKey,
  getSelectedIndustryType,
  TabTitle,
} from 'utilities/helpers';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import RemarksModal from 'common/RemarksModal';

export default function CreateProject() {
  TabTitle('Create Project | NewAtom ELN');
  const { pathname } = useLocation();
  const isEdit = pathname.split('/').at(-1) === 'edit';
  const param = useParams();
  const [form] = Form.useForm();
  const [editorValue, editorSetValue] = useState<string>('');
  const [files, setFiles] = useState<File[] | any[]>([]);
  // const [aliasCode, setAliasCode] = useState<null | string>(null);
  const [activeRadioId, setActiveRadioId] = useState('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [industryTypeError, setIndustryTypeError] = useState<string | null>(
    null
  );
  const { sectionHeadList, technicalLeadList } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const { projectDetailData, getprojectDetailLoading } = useSelector(
    (state: RootState) => state.project
  );

  const { createProjectLoading } = useSelector(
    (state: RootState) => state.project2
  );
  const [selectedSectionHead, setSelectedSectionHead] = useState<any[]>([]);
  const [selectedTechnicalLead, setSelectedTechnicalLead] = useState<any[]>([]);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  const [selectedChildOption, setSelectedChildOption] = useState<string>('');
  const [genProjectCode, setGenProjectCode] = useState<string>('');
  const [formattedInput, setFormattedInput] = useState<string>(''); // Formatted input state
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { industryTypesList } = useSelector(
    (state: RootState) => state.project2
  );

  useEffect(() => {
    const payloadIndustryType = {
      search: '',
    };
    dispatch(getIndustryTypes(payloadIndustryType));
  }, []);

  const transformUserList = (list: any[], role: string = '') => {
    return list.map((item, index) => {
      let existingUser = null;
      if (role === 'sh') {
        existingUser = projectDetailData?.section_heads?.find(
          (user: any) => user.section_head_id === item.id
        );
      } else {
        existingUser = projectDetailData?.technical_leads?.find(
          (user: any) => user.project_chemist.id === item.id
        );
      }
      return existingUser
        ? {
            section_head_id: item.id,
            sequence: index,
            id: existingUser.id,
            is_tl: role === 'tl' ? true : false,
            view_access: item.view_access ? false : false,
            edit_access: item.edit_access ? true : true,
            approve_access: item.approve_access ? true : true,
          }
        : {
            section_head_id: item.id,
            sequence: index,
            is_tl: role === 'tl' ? true : false,
            view_access: item.view_access ? false : false,
            edit_access: item.edit_access ? true : true,
            approve_access: item.approve_access ? true : true,
          };
    });
  };

  useEffect(() => {
    if (genProjectCode) {
      setIndustryTypeError(null);
    }
  }, [genProjectCode]);

  const renderedIndustryTypes = industryTypesList?.map((industry: any) => {
    if (industry.industry_type === 'Pharma') {
      return {
        label: industry.industry_type,
        value: industry.id,
        classNames:
          'border font-open-sans border-tertiary-highlight-200 p-2 rounded-lg',
        subOptionsName: 'child_option',
        subOptions: [
          {
            label: 'GMP',
            value: `${industry.id}-1`,
          },
          {
            label: 'Development',
            value: `${industry.id}-2`,
          },
        ],
      };
    } else {
      return {
        label: industry.industry_type,
        value: industry.id,
        classNames:
          'border font-open-sans border-tertiary-highlight-200 p-2 rounded-lg',
      };
    }
  });

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (changedVal.compound_code) {
      setFormattedInput(changedVal.compound_code);
      // form.setFieldsValue({
      //   compound_code: convertTextWithChemicalFormulas(
      //     changedVal.compound_code
      //   ),
      // });
    }

    if (changedVal.section_head) {
      const selectedUsers = sectionHeadList.filter((user: any) =>
        changedVal.section_head.includes(user.id)
      );
      setSelectedSectionHead(selectedUsers);
    }

    if (changedVal.technical_lead) {
      const selectedUsers = technicalLeadList.filter((user: any) =>
        changedVal.technical_lead.includes(user.id)
      );
      setSelectedTechnicalLead(selectedUsers);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        compound_code: convertTextWithChemicalFormulas(formattedInput),
      });
    }
  };

  const onClear = (e: any) => {
    editorSetValue('');
    // setAliasCode(null);
    setGenProjectCode('');
    setSelectedOption('');
    setSelectedChildOption('');
    setSelectedSectionHead([]);
    setSelectedTechnicalLead([]);
    setFiles([]);
    setIndustryTypeError(null);
  };

  const finalSubmit = (values: any, remark = '') => {
    const transformedList_SH = transformUserList(selectedSectionHead, 'sh');
    const transformedList_TL = transformUserList(selectedTechnicalLead, 'tl');

    const deletedSH = projectDetailData?.section_heads
      ?.filter(
        (user: any) =>
          ![...transformedList_SH].some(
            (ch: any) => ch.section_head_id === user?.section_head_id
          )
      )
      .map((user: any) => ({ is_active: false, id: user?.id }));

    const deletedTL = projectDetailData?.technical_leads
      ?.filter(
        (user: any) =>
          ![...transformedList_TL].some(
            (ch: any) => ch.section_head_id === user?.project_chemist?.id
          )
      )
      .map((user: any) => ({ is_active: false, id: user?.id }));

    if (!genProjectCode) {
      setIndustryTypeError('Please select industry type');
      return;
    }
    let formdata = new FormData();

    if (isEdit) {
      formdata.append('id', param?.id as string);
      formdata.append('change_remarks', remark);
      formdata.append('is_edit', 'true');
    }

    const filteredFiles = files.filter((file) => !file.originalName);
    filteredFiles.forEach((file) => {
      formdata.append('documents', file);
    });

    // formdata.append('project_code', genProjectCode);
    formdata.append('type', genProjectCode);
    formdata.append('industry_type_id', selectedOption);
    formdata.append('proposal_code', values.proposal_code);
    formdata.append('client_id', values.client_id);
    formdata.append('project_description', values.project_description);
    formdata.append(
      'compound_code',
      convertChemicalFormulasToSimpleText(values.compound_code)
    );
    formdata.append('requested_quantity', values.requested_quantity);
    formdata.append('uom_master_id', values.uom_master_id);
    formdata.append('requested_purity', values.requested_purity);
    formdata.append('key_specs', editorValue);
    // formdata.append('alias_code', aliasCode !== null ? aliasCode : '');
    if (isEdit) {
      formdata.append(
        'section_heads',
        JSON.stringify([...transformedList_SH, ...deletedSH])
      );
      formdata.append(
        'technical_lead',
        JSON.stringify([...transformedList_TL, ...deletedTL])
      );
    } else {
      formdata.append('section_heads', JSON.stringify([...transformedList_SH]));
      formdata.append(
        'technical_lead',
        JSON.stringify([...transformedList_TL])
      );
    }

    dispatch(createProject(formdata)).then((res: any) => {
      if (res.payload?.success) {
        if (isEdit) {
          navigate('/projects/' + param.id, { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }
      } else {
        message.error('Failed to create new project...');
      }
    });
  };

  const onSubmit = (values: any, remark = '') => {
    if (!isEdit) {
      finalSubmit(values, remark);
    } else {
      if (isRemarksModalOpen) {
        setIsRemarksModalOpen(false);
        finalSubmit(values, remark);
      } else {
        setIsRemarksModalOpen(true);
      }
    }
  };

  //getting project details in edit
  useEffect(() => {
    if (isEdit) {
      const payload = {
        id: Number(param?.id),
      };
      dispatch(getprojectDetail(payload));
    }
  }, [param.id, isEdit]);

  //setting the project details in edit
  useEffect(() => {
    if (isEdit) {
      const data = projectDetailData;
      form.setFieldsValue({
        proposal_code: data?.proposal_code,
        project_code: data?.project_code,
        client_id: data?.client?.id,
        project_description: data?.project_description,
      });

      // setting industry type
      const industryType = getSelectedIndustryType(data?.project_code);
      if (industryType?.m) {
        setSelectedChildOption(industryType?.c as string);
        setSelectedOption(industryType?.m);
      }

      // setting files
      if (data?.documents?.length) {
        setFiles(data?.documents);
      }

      //set selected section heads
      if (data?.section_heads?.length) {
        const chemist = data?.section_heads?.map(
          (sh: any) => sh.section_head_id
        );
        form.setFieldsValue({
          section_head: chemist,
        });
        const selectedUsers = sectionHeadList.filter((user: any) =>
          chemist.includes(user.id)
        );
        setSelectedSectionHead(selectedUsers);
      }

      //set selected TL
      if (data?.technical_leads?.length) {
        const chemist = data?.technical_leads?.map(
          (tl: any) => tl.project_chemist.id
        );
        form.setFieldsValue({
          technical_lead: chemist,
        });
        const selectedUsers = technicalLeadList.filter((user: any) =>
          chemist.includes(user.id)
        );
        setSelectedTechnicalLead(selectedUsers);
      }
    }
  }, [isEdit, projectDetailData]);

  return (
    <Flex vertical gap="1.25rem">
      <h1 className="text-2xl font-bold text-primary font-open-sans">
        {isEdit ? 'Edit project' : 'Create new project'}
      </h1>
      <Form
        onFinish={onSubmit}
        layout="vertical"
        form={form}
        onValuesChange={handleFormValuesChange}
        onKeyDown={handleKeyDown}
        className="flex flex-col gap-5"
      >
        <Spin spinning={createProjectLoading || getprojectDetailLoading}>
          <IndustrialDetails
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            selectedChildOption={selectedChildOption}
            setSelectedChildOption={setSelectedChildOption}
            setGenProjectCode={setGenProjectCode}
            industryTypeError={industryTypeError}
            parentForm={form}
            data={projectDetailData}
            isEdit={isEdit}
          />
        </Spin>

        {/* compound details form */}
        {!isEdit && (
          <Spin spinning={createProjectLoading}>
            <CompoundDetails
              // setAliasCode={setAliasCode}
              // aliasCode={aliasCode}
              editorSetValue={editorSetValue}
              editorValue={editorValue}
              form={form}
            />
          </Spin>
        )}

        {/* attachments details form */}
        <Spin spinning={createProjectLoading || getprojectDetailLoading}>
          <AttachmentDetails
            technicalLeadList={technicalLeadList}
            setSelectedTechnicalLead={setSelectedTechnicalLead}
            selectedTechnicalLead={selectedTechnicalLead}
            setSelectedSectionHead={setSelectedSectionHead}
            selectedSectionHead={selectedSectionHead}
            sectionHeadList={sectionHeadList}
            setFiles={setFiles}
            files={files}
            form={form}
          >
            <Flex justify="center" gap={'1rem'} className="mt-4 ">
              <Button
                type="text"
                className="text-primary h-9 font-open-sans"
                onClick={() => navigate(-1)}
                disabled={createProjectLoading}
              >
                Cancel
              </Button>
              {!isEdit && (
                <Button
                  htmlType="reset"
                  onClick={onClear}
                  className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                  disabled={createProjectLoading || getprojectDetailLoading}
                >
                  Clear
                </Button>
              )}
              <Button
                htmlType="submit"
                type="primary"
                className="text-white h-9 bg-primary-dark font-open-sans hover:!bg-white hover:!text-primary font-semibold border border-primary-dark"
                loading={createProjectLoading || getprojectDetailLoading}
              >
                {isEdit ? 'Update' : 'Create'}
              </Button>
            </Flex>
          </AttachmentDetails>
          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => {
              const values = form.getFieldsValue();
              onSubmit(values, value);
            }}
            loading={createProjectLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </Spin>
      </Form>
    </Flex>
  );
}
