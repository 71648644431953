import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}
interface ResponseWithPagination {
  success: boolean;
  message: string;
  data: any;
  pagination: any;
}

//dashboard...................................................................
export const dashboardCount = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.dashboardCount, payload, true);
};

//users...........................................................................
export const usersList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.userListAdmin, payload, true);
};

export const userCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createUser, payload, true);
};

//clients...............................................................................
export const clientList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.clientListAdmin, payload, true);
};

export const clientCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createClient, payload, true);
};

//roles...................................................................................
export const roleList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.roleList, payload, true);
};

//uom...............................................................................
export const uomList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.uomList, payload, true);
};

export const uomCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createUom, payload, true);
};

//TypeOfList...............................................................................
export const typeOfList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.typeOfList, payload, true);
};

export const typeOfListCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.typeOfListCreate, payload, true);
};

//experiment limit...............................................................................
export const experimentLimit = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.experimentLimit, payload, true);
};

export const changeExperimentLimit = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.post(apiClient.Urls.createExperimentLimit, payload, true);
};
