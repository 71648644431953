import CreateButton from 'common/CreateButton';
import CompoundCard from './CompoundCard';
import { Col, Empty, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from 'utilities/roleRights';
import { useState } from 'react';
import CreateCompound from '../pages/CreateCompound';

export type TCompoundCode = {
  id: number;
  compoundCode: string;
  aliasCode: string;
  quantityRequested: string;
  purityRequested: string;
  key_specs: string;
  uom_master_id: number;
  uom: string;
};
type TProps = {
  compoundCodes: TCompoundCode[];
  id: string | number;
  role?: string;
};

export default function CompoundList({ compoundCodes, id, role }: TProps) {
  const navigate = useNavigate();
  const [idToEdit, setIdToEdit] = useState<number | null>(null);

  const handleClick = () => {
    if (!id) return;
    navigate(`/projects/create/compound/${id}`);
  };

  if (!compoundCodes?.length) {
    if (PERMISSIONS.canCreateCompound(role ? role : '')) {
      return (
        <div className="flex items-center justify-center mt-4 cursor-pointer">
          <CreateButton
            subLabel={
              <p className="mt-2 text-sm font-semibold text-center font-open-sans">
                Please create a Compound Code for the project
              </p>
            }
            label={
              <p className="mt-2 text-base font-bold font-open-sans text-primary-dark">
                No Compound Code
              </p>
            }
            iconClassess="w-12"
            borderLess
            onClick={handleClick}
          />
        </div>
      );
    } else {
      return <Empty className="mt-4" />;
    }
  }

  const renderedCompoundList = idToEdit ? (
    <div className="w-full mt-4">
      <CreateCompound
        initData={compoundCodes?.find((code) => code.id === idToEdit)}
        idToEdit={idToEdit}
        setIdToEdit={setIdToEdit}
      />
    </div>
  ) : (
    compoundCodes?.map((code) => {
      return (
        <Col
          key={code.aliasCode}
          span={12}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
          className="mt-3"
        >
          <CompoundCard
            id={code.id}
            setIdToEdit={setIdToEdit}
            compoundCode={code.compoundCode}
            purityRequested={code.purityRequested}
            quantityRequested={code.quantityRequested}
            uom={code.uom}
            // aliasCode={code.aliasCode}
            compoundCodLength={compoundCodes?.length}
            keySpecs={code.key_specs}
          />
        </Col>
      );
    })
  );

  return (
    <Row gutter={10} className="flex items-center">
      {
        renderedCompoundList
        // PERMISSIONS.canCreateCompound(role ? role : '') && (
        //   <Col
        //     key={0}
        //     span={12}
        //     md={{ span: 8 }}
        //     lg={{ span: 6 }}
        //     className="mt-3"
        //   >
        //     <CreateButton label="Create Compound code" onClick={handleClick} />
        //   </Col>
        // ),
      }
    </Row>
  );
}
