import { Flex, Tooltip } from 'antd';

type TCommonProps = {
  label: string | JSX.Element;
};

type TPropsDescriptive = TCommonProps & {
  description: string | JSX.Element;
  labelClasses?: string;
  descriptionClasses?: string;
  containerClasses?: string;
  showTooltip?: boolean;
};

type TPropsNonDescriptive = TCommonProps & {
  description?: null | undefined | '';
  required?: boolean;
};

type TProps = TPropsDescriptive | TPropsNonDescriptive;

const isDescriptive = (props: TProps): props is TPropsDescriptive => {
  return 'description' in props;
};

export default function Label(props: TProps) {
  if (isDescriptive(props)) {
    const {
      label,
      description,
      labelClasses,
      descriptionClasses,
      containerClasses,
      showTooltip,
    } = props;
    return (
      <span
        className={
          containerClasses
            ? `${containerClasses} flex flex-col items-start w-full gap-1 sm:flex-row`
            : 'flex flex-col items-start w-full gap-1 sm:flex-row sm:items-center sm:gap-6'
        }
      >
        <label
          className={
            labelClasses
              ? `${labelClasses} font-open-sans`
              : 'flex-shrink-0 w-full sm:w-[12%] text-xs font-semibold font-open-sans'
          }
        >
          {label}
        </label>
        {showTooltip ? (
          <Tooltip title={description} overlayClassName="custom-tooltip">
            <p
              className={
                descriptionClasses
                  ? `${descriptionClasses} font-open-sans`
                  : 'flex-grow text-xs font-open-sans'
              }
            >
              {description}
            </p>
          </Tooltip>
        ) : (
          <p
            className={
              descriptionClasses
                ? `${descriptionClasses} font-open-sans`
                : 'flex-grow text-xs font-open-sans'
            }
          >
            {description}
          </p>
        )}
      </span>
    );
  } else {
    const { required, label } = props;
    return required ? (
      <Flex align="center" gap={4}>
        <span className="text-sm pt-1 text-[#ff4d4f]">*</span>
        <label className="text-xs font-semibold required_field lg:text-sm text-tertiary-dark font-open-sans">
          {label}
        </label>
      </Flex>
    ) : (
      <label className="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans">
        {label}
      </label>
    );
  }
}
