// import { TMaster } from 'data/masters';
import { useNavigate } from 'react-router-dom';

export type TMaster = {
  id: number;
  label: string;
  path: string;
  img: string;
  subLabel: string
};

export default function ReportCard({ id, path, label, img, subLabel }: TMaster) {
  const navigate = useNavigate();
  return (
    <div
      className="border cursor-pointer hover:border-primary hover:bg-tertiary-highlight-100 border-[##EAECF0] rounded-md font-open-sans p-6 text-md font-semibold flex gap-5"
      onClick={() => navigate(path)}
    >
      <img src={img} alt={label} className="w-20 h-14" />
      <div className="flex flex-col">
        <label className="font-bold text-md font-open-sans text-[#6BC7E4]">
          {label}
        </label>
        <span className="text-xs text-tertiary-filter font-open-sans">
          {subLabel}
        </span>
      </div>
    </div>
  );
}
