import React from 'react';

import ListView from './components/ListView';
import { TabTitle } from 'utilities/helpers';

const Dashboard: React.FC = () => {
  TabTitle('Dashboard | NewAtom ELN');
  return (
    <div>
      <ListView />
    </div>
  );
};

export default Dashboard;
