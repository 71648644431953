import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}
interface ResponseWithPagination {
  success: boolean;
  message: string;
  data: any;
  pagination: any;
}

export const summarize = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.post(apiClient.Urls.summarize, payload, true, 'file');
};