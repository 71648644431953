import { CloseOutlined } from '@ant-design/icons';
import { Modal as AntModal, Button, Flex } from 'antd';

type TProps = {
  isOpen: boolean;
  headerTitle?: string;
  onClose?: () => void;
  onCancel?: () => void;
  footerComponent?: string | JSX.Element;
  onAgree?: () => void;
  header?: string | JSX.Element;
  agreeText?: string | JSX.Element;
  cancelText?: string | JSX.Element;
  children?: string | JSX.Element;
  centered?: boolean;
  btnLoading?: boolean;
  agreeBtnClasses?: string;
  spanWidth?: string;
  headerTitleClasses?: string;
};

export default function Modal({
  isOpen,
  onClose,
  footerComponent,
  onAgree,
  header,
  agreeText = 'Add values',
  cancelText = 'Clear',
  children,
  onCancel,
  headerTitle,
  centered = false,
  btnLoading,
  agreeBtnClasses,
  spanWidth,
  headerTitleClasses,
}: TProps) {
  return (
    <AntModal
      centered={centered}
      classNames={{
        content: 'p-0',
        body: 'max-h-[75vh] overflow-auto',
      }}
      title={
        header ? (
          header
        ) : (
          <div className="flex items-center justify-between px-3 py-2 border-b rounded-t-lg border-secondary-dark bg-tertiary-highlight-100">
            <h3
              className={
                headerTitleClasses ||
                'text-lg font-bold text-primary font-open-sans'
              }
            >
              {headerTitle}
            </h3>
            <Button
              className="bg-transparent border-none"
              icon={<CloseOutlined />}
              shape="circle"
              onClick={onClose}
            />
          </div>
        )
      }
      footer={
        footerComponent ? (
          footerComponent
        ) : (
          <Flex justify="center" wrap gap={10} className="pb-6">
            <Button
              onClick={onCancel}
              className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
            >
              {cancelText}
            </Button>
            <Button
              type="primary"
              className={
                agreeBtnClasses ||
                'font-semibold text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:border-primary'
              }
              onClick={onAgree}
              loading={btnLoading}
            >
              {agreeText}
            </Button>
          </Flex>
        )
      }
      confirmLoading={btnLoading}
      open={isOpen}
      closable={false}
      className={spanWidth}
    >
      {children}
    </AntModal>
  );
}
