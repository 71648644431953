import CreateButton from 'common/CreateButton';
import { Col, Empty, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import FolderCard from './FolderCard';
import { PERMISSIONS } from 'utilities/roleRights';

type TFolder = {
  counts: number;
  id: number;
  folderName: string;
};
type TProps = {
  folders: TFolder[];
  id: string | number;
  role?: string;
};

export default function FolderList({ folders, id, role }: TProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/folders/create/folder/${id}`);
  };
  
  const handleFolderClick = (folderId: string | number) => {
    if (PERMISSIONS.canReadFolders(role as string)) {
      navigate(`/folders/edit/folder/${id}/${folderId}`);
    } else {
      navigate(`/experiments/${id}/${folderId}`);
    }
  };

  if (!folders?.length) {
    if (PERMISSIONS.canCreateFolder(role ? role : '')) {
      return (
        <div className="flex items-center justify-center mt-4 cursor-pointer">
          <CreateButton
            subLabel={
              <p className="mt-2 text-sm font-semibold text-center font-open-sans">
                Please create Folder for the project
              </p>
            }
            label={
              <p className="mt-2 text-base font-bold font-open-sans text-primary-dark">
                No Folder Found
              </p>
            }
            iconClassess="w-12"
            borderLess
            onClick={handleClick}
          />
        </div>
      );
    } else {
      return <Empty className="mt-4" />;
    }
  }

  const renderedFolderList = folders?.map((folder) => {
    return (
      <Col
        key={folder.id}
        // span={12}
        // sm={{ span: 12 }}
        // md={{ span: 6 }}
        // lg={{ span: 3 }}
        onClick={() => handleFolderClick(folder.id)}
        className="mt-3"
      >
        <FolderCard counts={folder.counts} folderName={folder.folderName} />
      </Col>
    );
  });

  return (
    <Row className="flex flex-wrap items-center" gutter={20}>
      {[
        ...renderedFolderList,
        // PERMISSIONS.canCreateFolder(role ? role : '') && (
        //   <Col
        //     key={0}
        //     span={24}
        //     sm={{ span: 12 }}
        //     md={{ span: 6 }}
        //     lg={{ span: 4 }}
        //     className='mt-3'
        //   >
        //     <CreateButton label="Create Folder" onClick={handleClick} />
        //   </Col>
        // ),
      ]}
    </Row>
  );
}
