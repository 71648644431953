import { Flex } from 'antd';
import ReportCard from './components/ReportCard';
import { REPORTS } from 'data/reports';

export default function Reports() {
  return (
    <Flex vertical className="h-full bg-white shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Reports
        </h2>
      </Flex>

      <div className="grid grid-cols-1 gap-5 m-4 md:grid-cols-2 xl:grid-cols-3">
        {REPORTS.map((item) => {
          return (
            <ReportCard
              id={item.id}
              img={item.img}
              label={item.label}
              path={item.path}
              key={item.id}
              subLabel={item.subLabel}
            />
          );
        })}
      </div>
    </Flex>
  );
}
