import bg from 'assets/icons/folder_bg.svg';
import folderOpen from 'assets/icons/folder_open.svg';
import folderClose from 'assets/icons/folder_closed.svg';
import { useState } from 'react';

type TProps = {
  counts: number;
  folderName: string;
};

export default function Folder({ counts, folderName }: TProps) {
  const [isHovering, setIsHovering] = useState(false);
  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="relative inline-block cursor-pointer rounded-xl shadow-bottom-only hover:shadow-bottom-only-darker"
    >
      <img src={bg} className="block" />
      <img
        src={folderClose}
        className={`absolute z-50 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-opacity duration-300 ${
          isHovering ? 'opacity-0' : 'opacity-100'
        }`}
      />
      <img
        src={folderOpen}
        className={`absolute z-50 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-opacity duration-300 ${
          isHovering ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <span
        className={`absolute z-50 w-full -translate-x-1/2 -translate-y-1/2 text-2xs top-1/2 left-1/2 transition-opacity duration-300 ${
          isHovering ? 'opacity-100' : 'opacity-0'
        } `}
      >
        <span className="block font-medium text-center text-2xs font-open-sans">
          {counts}
        </span>
        <span className="block font-medium text-center text-[6px] font-open-sans">
          Experiments
        </span>
      </span>
      <span
        className={`absolute z-50 -translate-x-1/2 -translate-y-1/2 text-xs transition-colors duration-300 ${isHovering ? 'text-black' : 'text-white'} font-semibold font-open-sans -bottom-2 left-1/2`}
      >
        {folderName}
      </span>
    </div>
  );
}
