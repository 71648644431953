import { useState } from 'react';
import { Link } from 'react-router-dom';

type TProps = {
  path: string;
  activeIcon: string;
  icon: string;
  label: string;
  classes: string;
};

export default function IconLink({
  path,
  activeIcon,
  icon,
  label,
  classes,
}: TProps) {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Link
      to={path}
      className=" hover:!text-primary font-open-sans hover:!bg-transparent"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex items-center gap-1 text-[1.12rem]">
        {isHovering ? (
          <img src={activeIcon} className={classes} />
        ) : (
          <img src={icon} className={classes} />
        )}
        {label}
      </div>
    </Link>
  );
}
