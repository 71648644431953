export default function Card({
  children,
  header,
  headerClasses,
}: {
  children: JSX.Element | string;
  header?: string | JSX.Element;
  headerClasses?: string;
}) {
  return header ? (
    <div className="bg-white font-open-sans rounded-xl shadow-equal-sides">
      <div
        className={
          headerClasses
            ? `${headerClasses} px-3 py-2 text-sm border border-tertiary-highlight-200 rounded-t-xl sm:text-base font-open-sans bg-tertiary-highlight-100`
            : 'px-6 py-3 text-sm font-bold border border-tertiary-highlight-200 rounded-t-xl text-primary sm:text-base font-open-sans bg-tertiary-highlight-100'
        }
      >
        {header}
      </div>
      {children}
    </div>
  ) : (
    <div className="p-3 bg-white font-open-sans rounded-xl shadow-equal-sides">
      {children}
    </div>
  );
}
