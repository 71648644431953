import { Quill } from 'react-quill';

import { Flex, Select } from 'antd';
import formats from './options';

const renderOptions = (formatData: any) => {
  const { className, options } = formatData;
  return (
    <select className={className}>
      <option selected={true}></option>
      {options.map((value: any) => {
        return <option value={value} key={value}></option>;
      })}
    </select>
  );
};
const renderSingle = (formatData: any) => {
  const { className, value, icon } = formatData;
  return (
    <button className={`${className} mx-1`} value={value}>
      {icon && <img src={icon} alt={className}/>}
    </button>
  );
};

export const QuillToolbar = () => (
  <div id="toolbar" className=' !border-none flex flex-col items-start flex-1 gap-4 p-2 m-2 sm:items-center sm:flex-row rounded-xl bg-secondary-filter'>
    {formats.map((classes, i) => {
      return (
        <span className="ql-formats" key={i}>
          {classes.map((formatData: any) => {
            return formatData.options
              ? renderOptions(formatData)
              : renderSingle(formatData);
          })}
        </span>
      );
    })}
  </div>
);
