import { SendOutlined } from '@ant-design/icons';
import { Form, message, Spin } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import { getprojectList_dropdown } from 'features/proposalLeadFlow/dashboard/redux/slice';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type TProps = {
  isOpen: boolean;
  setIsOpen: any;
};

export default function ProjectSearch({ isOpen, setIsOpen }: TProps) {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const {
    projectListData,
    projectListDropdownData,
    getprojectListDropdownDataLoading,
  } = useSelector((state: RootState) => state.project);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setSearch(searchTerm);
      const payload = {
        page: 1,
        perPage: 20,
        search: searchTerm,
      };
      dispatch(getprojectList_dropdown(payload));
    }
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  //rendering project search dropdown
  const renderingProjectList = projectListDropdownData?.length
    ? projectListDropdownData.map((project: any) => {
        return { label: project.project_code, value: project.id };
      })
    : projectListData.map((project: any) => {
        return { label: project.project_code, value: project.id };
      });

  const handleSearchSubmit = (changedVal: any, values: any) => {
    if (changedVal.project_search) {
      const foundSearch = renderingProjectList.find(
        (project) =>
          String(project.label).toLowerCase() === search.toLowerCase().trim()
      )?.value;
      const projectId = changedVal.project_search || foundSearch;
      if (projectId) {
        navigate(`/folders/${projectId}`);
        form.resetFields();
        setIsOpen(false);
      } else {
        message.error('Please select a valid project code');
      }
    }
  };

  //reseting fields
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Form
      className="w-28 lg:w-40"
      form={form}
      onValuesChange={handleSearchSubmit}
    >
      <FormInput
        name="project_search"
        type="select"
        selectplaceholderClasses="text-xs font-open-sans"
        options={renderingProjectList}
        onSearch={debouncedSearch}
        selectInputClasses='w-full'
        marginBottom="none"
        defaultActiveFirstOption={false}
        optionRender={(option: any) => {
          return (
            <span className="!text-xs font-open-sans">{option.label}</span>
          );
        }}
        selectInputSize="middle"
        placeholder="Search project"
        suffixIcon={
          getprojectListDropdownDataLoading ? <Spin size="small" /> : null
        }
        notFoundContent={null}
      />
    </Form>
  );
}
