import fileIcon from 'assets/icons/file.svg';
import deleteIcon from 'assets/icons/delete.svg';

type TProps = {
  file: File | any;
  size: number | string;
  percentage: number;
  onDelete: (index: number | string) => void;
  index: number | string;
};

export default function UploadProgress({
  file,
  size,
  percentage,
  onDelete,
  index,
}: TProps) {
  return (
    <div className="relative flex items-center justify-center w-full gap-4 px-2 py-2 border rounded-lg font-open-sans border-primary-dark">
      <img src={fileIcon} alt="file icon" />
      <div className="flex flex-col flex-1 gap-3">
        <div className="flex flex-col items-start">
          <label
            title={file.name || file.originalName}
            className="text-sm font-semibold text-ellipsis font-open-sans text-tertiary-dark"
          >
            {file.name || file.originalName}
          </label>
          <label className="text-xs font-open-sans text-tertiary-filter">
            {typeof size === 'number'
              ? `${size}KB`
              : typeof size === 'string'
                ? size
                : null}
          </label>
        </div>
        {/* <div
          className="flex items-center justify-center flex-1 gap-2"
          style={{ width: percentage + '%' }}
        >
          <div className="flex-1 w-full h-3 rounded-full bg-primary"></div>
          <label className="text-xs font-open-sans text-tertiary-dark sm:text-sm">
            {percentage}%
          </label>
        </div> */}
      </div>
      <img
        src={deleteIcon}
        onClick={() => onDelete(index)}
        className="cursor-pointer hover:opacity-75"
        alt="delete icon"
      />
    </div>
  );
}
