import { Button, Flex, Form } from 'antd';
import Modal from './Modal';
import { CloseOutlined } from '@ant-design/icons';
import FormInput from './FormInput';

type TProps = {
  fieldName: string;
  onApprove: (value: any) => void;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  fieldPlaceholder: string;
  headerTitle: string;
  description?: string | JSX.Element;
  agreeBtnClasses?: string;
  agreeText?: string;
};

export default function ModalCtx({
  fieldName,
  onApprove,
  isOpen,
  loading,
  onClose,
  fieldPlaceholder,
  headerTitle,
  description,
  agreeBtnClasses,
  agreeText,
}: TProps) {
  const [form] = Form.useForm();
  return (
    <Form
      id="context_modal_form"
      onFinish={(values: any) => onApprove(values)}
      form={form}
      layout="vertical"
    >
      <Modal
        isOpen={isOpen}
        btnLoading={loading}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        onAgree={() => {
          const values = form.getFieldsValue();
          onApprove(values);
        }}
        onCancel={() => {
          onClose();
          form.resetFields();
        }}
        centered
        header={
          <div className="flex items-center justify-between px-3 py-2">
            <h3 className="text-base font-bold text-tertiary-dark">
              {headerTitle}
            </h3>
            <Button
              className="bg-transparent border-none"
              icon={<CloseOutlined />}
              shape="circle"
              onClick={() => {
                onClose();
                form.resetFields();
              }}
            />
          </div>
        }
        cancelText={'No'}
        agreeText={agreeText}
        agreeBtnClasses={agreeBtnClasses || 'text-white font-open-sans px-10'}
      >
        <Flex vertical gap={'1rem'}>
          <span className="px-3 text-sm text-tertiary-filter">
            {description}
          </span>
          <div className="pr-3">
            <FormInput
              type="textarea"
              name={fieldName}
              label={<span className="hidden">label</span>}
              placeholder={fieldPlaceholder}
              defaultStyle
            />
          </div>
        </Flex>
      </Modal>
    </Form>
  );
}
